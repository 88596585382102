import { Input, Notification, PhoneIcon } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { ChangeEventHandler, KeyboardEventHandler, useCallback, useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonContainer, CancelButton, ContentSection, SaveButton, SideImageContainerContent } from '../../components'
import Localization from '../../locales'
import { editPhoneNumberStore, StoreContext } from '../../store'
import { IMainViewState } from '../../store/dataModels/interfaces'
import { useFormFields } from '../../views/registration/utils/useFormFields'
import { validatePhoneNumber } from '../../views/registration/utils/validators'
import { AlertNotification, VERIFY_PHONE_ERROR } from '../../views/registration/verifyPhone/AlertNotification'
import { StaticInputLayout } from '../StaticInputLayout'

const EditConsumerPhoneNumberComponent: React.FC = () => {
  const { t } = useTranslation()
  const [error, setError] = useState<VERIFY_PHONE_ERROR | null>(null)
  const {
    userStore,
    mainViewStore,
    editPhoneNumberStore: { isSavingPossible },
  } = useContext(StoreContext)

  const [
    {
      phoneNumber: { value, labelTranslationKey, invalidMessageTranslationKey, isInvalid, touched, ref },
    },
    handleFieldChange,
  ] = useFormFields<'phoneNumber'>({
    phoneNumber: {
      value: '',
      validator: validatePhoneNumber,
      isInvalid: false,
      touched: false,
      ref: useRef(),
      labelTranslationKey: 'mainView.editPhoneNumber.newPhoneNumber',
      invalidMessageTranslationKey: 'mainView.editPhoneNumber.invalidPhoneNumber',
    },
  })

  const save = useCallback(async () => {
    if (isSavingPossible) {
      try {
        editPhoneNumberStore.sanitizePhoneNumber()
        await editPhoneNumberStore.sendVerifyCode()

        // clear input field
        const customEvent = new CustomEvent('updateField', { detail: { fieldName: 'phoneNumber', value: '' } })
        handleFieldChange(customEvent)

        mainViewStore.setState(IMainViewState.VerifyPhone)
      } catch (message) {
        setError(VERIFY_PHONE_ERROR.SEND_NEW_CODE_ERROR)
      }
    }
  }, [handleFieldChange, isSavingPossible, mainViewStore])

  const updateNumber: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      editPhoneNumberStore.setNewPhoneNumber(event.currentTarget.value)
      handleFieldChange(event)
    },
    [handleFieldChange]
  )

  const acceptEnterKey: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      // enter
      if (event.key === 'Enter' && isSavingPossible) {
        save()
      }
    },
    [isSavingPossible, save]
  )

  editPhoneNumberStore.setLang(Localization.CurrentLocale)

  return (
    <SideImageContainerContent>
      <ContentSection>
        <StaticInputLayout
          label={t('mainView.editPhoneNumber.currentPhoneNumber')}
          content={userStore.identities.consumerIdentity.consumerPhoneNumber.value}
          Icon={PhoneIcon}
        />
        {error && <AlertNotification error={error} />}
        <Input
          ref={ref}
          autoFocus
          value={value}
          lightBackground
          name="phoneNumber"
          id="phonenumber-input"
          onChange={updateNumber}
          onBlur={handleFieldChange}
          onKeyDown={acceptEnterKey}
          isInvalid={touched && isInvalid}
          label={t(labelTranslationKey as string)}
          message={touched && isInvalid ? t(invalidMessageTranslationKey as string) : ''}
        />
        <Notification dismissible={false} title={t(`mainView.editPhoneNumber.verifyInfoTitle`)}>
          {t(`mainView.editPhoneNumber.verifyInfoText`)}
        </Notification>
      </ContentSection>
      <ButtonContainer>
        <CancelButton id="edit_phone_cancel_button" onClick={mainViewStore.reset} />
        <SaveButton id="edit_phone_save_button" disabled={!isSavingPossible} onClick={save} />
      </ButtonContainer>
    </SideImageContainerContent>
  )
}

export const EditConsumerPhoneNumber = observer(EditConsumerPhoneNumberComponent)
