import { ProfileIcon } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { StaticInputLayout } from '../StaticInputLayout'

interface IProps {
  orgName: string
  businessId: string
  invitorEmail: string
  invitorName: string
  invitedByPosti: boolean
}

export const InviteShortInfoComponent: React.FC<IProps> = ({
  orgName,
  businessId,
  invitorEmail,
  invitorName,
  invitedByPosti,
}) => {
  const { t } = useTranslation()
  const invitor = invitorName ? invitorName : invitorEmail

  return (
    <StaticInputLayout
      Icon={ProfileIcon}
      label={t('invite.accept.joinMessage', { orgName, businessId })}
      content={
        !invitedByPosti ? t('invite.accept.invitedBy', { mainUserName: invitor }) : t('invite.accept.invitedByPosti')
      }
    />
  )
}

export const InviteShortInfo = observer(InviteShortInfoComponent)
