import { IConfirmEmailResult } from '../store/dataModels/interfaces'
import { serviceAdapter } from './serviceAdapter'

/**
 * This service was created for abstracting the API layer.
 * Idea is to start collecting all API endpoints in one place. They should also work in an unified way.
 * It's yet to be decided what the final outcome will be. Perhaps, we could use some design pattern with hooks.
 */
class ApiService {
  async verifyEmail(code: string): Promise<IConfirmEmailResult> {
    if (!serviceAdapter.getXsrfToken()) {
      try {
        await serviceAdapter.sendGetRequest('/api/preverifyemail')
      } catch (e) {
        console.error(e)
      }
    }
    return serviceAdapter
      .sendPostRequest('/api/verifyemail', {
        code: code,
      })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw response
        }
      })
  }
}

export default new ApiService()
