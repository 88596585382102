import { action, makeObservable, observable, runInAction } from 'mobx'

import Localization from '../locales'
import { serviceAdapter } from '../service/serviceAdapter'
import { encodeToBase64 } from '../utils/password'
import { validateCodeComplexity, validatePasswordComplexity, validateResetEmail } from '../utils/validation'
import { validateEmail } from '../views/registration/utils/validators'

export enum CORPORATE_RECOVERY_STEP {
  EMAIL = 'email',
  PASSWORD = 'password',
  SUCCESS = 'success',
}

export enum SUCCESS_STEP {
  NONE,
  EMAIL_SUCCESS,
  PASSWORD_SUCCESS,
}

export const RESPONSE_MESSAGE = {
  BAD_REQUEST: 'accountRecovery.corporate.message.badRequest',
  INTERNAL_SERVER_ERROR: 'accountRecovery.corporate.message.internalError',
  SUCCESS: 'accountRecovery.corporate.message.success',
  CODE_EXPIRED: 'accountRecovery.corporate.message.codeExpired',
  CODE_NOT_FOUND: 'accountRecovery.corporate.message.codeNotFound',
  EMAIL_MISMATCH: 'accountRecovery.corporate.message.emailMismatch',
  CODE_USED: 'accountRecovery.corporate.message.codeUsed',
  TOO_MANY_REQUEST: 'accountRecovery.corporate.message.codeExpired',
} as const

export interface RecoveryResponseBody {
  status: number
  message: keyof typeof RESPONSE_MESSAGE
}

export class CorporateAccountRecoveryStore {
  step: CORPORATE_RECOVERY_STEP
  email: string
  isSaving: boolean
  error: boolean
  success: SUCCESS_STEP
  isValid: boolean
  message: typeof RESPONSE_MESSAGE[keyof typeof RESPONSE_MESSAGE] | null

  constructor() {
    makeObservable(this, {
      email: observable,
      step: observable,
      isSaving: observable,
      error: observable,
      success: observable,
      isValid: observable,
      message: observable,
      setEmail: action,
      requestCode: action,
      resetPassword: action,
      reset: action,
      setStep: action,
      resetError: action,
    })

    this.reset()
  }

  reset() {
    this.email = ''
    this.step = CORPORATE_RECOVERY_STEP.EMAIL
    this.isSaving = false
    this.success = SUCCESS_STEP.NONE
    this.isValid = validateResetEmail(this.email)

    this.resetError()
  }

  resetError() {
    this.error = false
    this.message = null
  }

  setEmail(value: string) {
    this.email = value
    this.isValid = validateResetEmail(value)
  }

  setStep(step: CORPORATE_RECOVERY_STEP) {
    this.resetError()
    this.step = step
  }

  async requestCode() {
    if (!(this.email || validateEmail(this.email))) {
      return
    }

    runInAction(() => {
      this.isSaving = true
    })

    try {
      if (!serviceAdapter.getXsrfToken()) {
        await serviceAdapter.sendGetRequestWithoutLogin('/api/preverifyemail')
      }

      const response = await serviceAdapter.sendPostRequestWithoutLogin('/api/accountrecovery/init', {
        email: this.email,
        language: Localization.CurrentLocale,
      })

      if (response.status === 200) {
        runInAction(() => {
          this.resetError()
          this.isSaving = false
          this.success = SUCCESS_STEP.EMAIL_SUCCESS
          this.step = CORPORATE_RECOVERY_STEP.PASSWORD
        })

        return
      }

      if (response.status >= 400) {
        this.message = RESPONSE_MESSAGE.BAD_REQUEST

        if (response.status >= 500) {
          this.message = RESPONSE_MESSAGE.INTERNAL_SERVER_ERROR
        }
      }

      runInAction(() => {
        this.error = true
      })
    } catch (err) {
      runInAction(() => {
        this.error = true
        this.message = RESPONSE_MESSAGE.INTERNAL_SERVER_ERROR
      })
    } finally {
      runInAction(() => {
        this.isSaving = false
      })
    }

    return
  }

  async resetPassword({ code, password }: { code: string; password: string }) {
    if (!(validateCodeComplexity(code) || validatePasswordComplexity(password))) {
      return
    }

    runInAction(() => {
      this.isSaving = true
    })

    try {
      const response = await serviceAdapter.sendPostRequestWithoutLogin('/api/accountrecovery/code', {
        code,
        password: encodeToBase64(password),
        passwordEncoded: true,
        email: this.email,
        language: Localization.CurrentLocale,
      })

      if (response.status === 200) {
        runInAction(() => {
          this.isSaving = false
          this.success = SUCCESS_STEP.PASSWORD_SUCCESS
          this.step = CORPORATE_RECOVERY_STEP.SUCCESS
        })

        return
      }

      if (response.status >= 400 && response.status < 500) {
        const { message }: RecoveryResponseBody =
          response.headers.get('Content-Type') !== 'application/json'
            ? { message: 'INTERNAL_SERVER_ERROR' }
            : await response.json()

        this.message = RESPONSE_MESSAGE[message] || RESPONSE_MESSAGE.INTERNAL_SERVER_ERROR
      }

      if (response.status >= 500) {
        this.message = RESPONSE_MESSAGE.INTERNAL_SERVER_ERROR
      }

      runInAction(() => {
        this.error = true
      })
    } catch (err) {
      runInAction(() => {
        this.isSaving = false
        this.error = true
        this.message = RESPONSE_MESSAGE.INTERNAL_SERVER_ERROR
      })
    } finally {
      runInAction(() => {
        this.isSaving = false
      })
    }
  }
}

export const corporateAccountRecoveryStore = new CorporateAccountRecoveryStore()
