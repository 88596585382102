import { ArrowRightIcon, Button, Headline, HeadlineSize } from '@postidigital/posti-components/build/brand'
import { sendPageInfo, sendUserAction } from '@postidigital/posti-google-analytics'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Localization from '../../locales'
import AcceptStore, { IAcceptState } from '../../store/views/acceptStore'
import { AllSetSvg } from '../../views/registration/registration.styles'
import { CSATSelector } from '../general/CSATSelector'
import { ButtonContainer, Centered, ContentSection } from '../layout'
import { ResponsiveBody } from '../typography/ResponsiveBody'

interface IProps {
  created: boolean
  firstName?: string
  href: string
  buttonText: string
  continuationName: string
}

export const DoneInfoComponent: React.FC<IProps> = ({ created, firstName, href, buttonText, continuationName }) => {
  const { t } = useTranslation()
  const store = useContext(AcceptStore)

  const handleRedirectClick = useCallback(() => {
    sendUserAction({
      actionType: 'element_click',
      name: continuationName,
    })
    location.href = href
  }, [continuationName, href])

  useEffect(() => {
    sendUserAction({
      actionType: created ? 'onboarding_create_account_completed' : 'onboarding_accept_organization_completed',
      login: continuationName,
    })

    sendPageInfo({
      language: Localization.CurrentLocale,
      solution: 'oneaccount-onboarding',
      domain: window.location.hostname,
      pagePath: store.state === IAcceptState.Registered ? '/acceptinvite-registered' : '/acceptinvite-accepted',
      pageName: 'oneaccount-onboarding',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <AllSetSvg />
      <Centered>
        <ContentSection>
          <Headline as="h2" size={HeadlineSize.Four}>
            {created && t('invite.done.registeredTitle')}
            {!created && t('invite.done.acceptedTitle')}
          </Headline>
          <ResponsiveBody>
            {created ? t('invite.done.registeredText', { name: firstName }) : t('invite.done.acceptedText')}
          </ResponsiveBody>
          {store.state !== IAcceptState.PreviouslyAccepted && (
            <Centered style={{ paddingTop: '2rem' }}>
              <CSATSelector />
            </Centered>
          )}
        </ContentSection>
      </Centered>
      <ButtonContainer position="center">
        <Button
          id="accept_done_button"
          icon={ArrowRightIcon}
          iconPosition="right"
          hasBackground
          onClick={handleRedirectClick}
          role="link"
        >
          {buttonText}
        </Button>
      </ButtonContainer>
    </>
  )
}

export const DoneInfo = observer(DoneInfoComponent)
