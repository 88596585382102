import { action, computed, makeObservable, observable, runInAction, toJS } from 'mobx'

import { serviceAdapter } from '../service/serviceAdapter'
import { IIdentities } from './dataModels/interfaces'

export class UserStore {
  identities: IIdentities = {
    consumerIdentity: {
      communicationLanguage: '',
      marketingPermissions: [],
      contracts: [],
      consumerPhoneNumber: {
        verified: false,
        value: '',
      },
      isTerminated: false,
      terminationDate: '',
      preferredName: '',
      termsAndConditionsAccepted: false,
      postalAddress: {
        addressLines: [
          {
            value: '',
            type: '',
          },
        ],
        countryCode: '',
      },
    },
    businessIdentities: {
      organizationPhoneNumber: '',
      organizations: [],
    },
    email: '',
    firstName: '',
    lastName: '',
  }

  savedMarketingPermissions: string[] = []

  isLoading: boolean = true

  hideNotiTimeout: ReturnType<typeof setTimeout> | undefined = undefined

  error: string = ''

  changesMade: boolean = false

  savingChanges: boolean = false

  expanderOpen: boolean = false

  constructor() {
    makeObservable(this, {
      identities: observable,
      savedMarketingPermissions: observable,
      isLoading: observable,
      error: observable,
      changesMade: observable,
      savingChanges: observable,
      expanderOpen: observable,
      setExpanderOpen: action,
      hasOrganizations: computed,
      streetAddress: computed,
      marketingPermissions: computed,
      setLastName: action,
      setFirstName: action,
      updateLangToBackend: action,
      saveMarketingChanges: action,
      toggleMarketingPermission: action,
      setLanguage: action,
      setPhoneNumber: action,
      hideErrorAfterTimeout: action,
      setPreferredName: action,
      restoreOriginalMarketingPermissions: action,
      fetchIdentities: action,
      removeOrg: action,
      setOrganizationPhoneToUserAccountsMyOrganizations: action,
    })
  }

  setExpanderOpen(open: boolean) {
    this.expanderOpen = open
  }

  get hasOrganizations(): boolean {
    return Boolean(this.identities.businessIdentities.organizations?.[0]?.name)
  }

  get streetAddress(): string {
    if (!this.identities || !this.identities.consumerIdentity?.postalAddress) {
      return ''
    }

    const { addressLines } = this.identities.consumerIdentity?.postalAddress

    const streetAddressObj = addressLines.find((obj) => obj.type === 'streetaddress')
    const postalCodeObj = addressLines.find((obj) => obj.type === 'postalcode')
    const cityObj = addressLines.find((obj) => obj.type === 'city')

    return `${streetAddressObj?.value} ${postalCodeObj?.value} ${cityObj?.value}`
  }

  get marketingPermissions(): Array<any> {
    if (!this.identities || !this.identities.consumerIdentity) {
      return []
    }

    return this.identities.consumerIdentity.marketingPermissions
  }

  setLastName = (lastName: string): void => {
    this.identities.lastName = lastName
  }
  setFirstName = (firstName: string): void => {
    this.identities.firstName = firstName
  }

  async updateLangToBackend(lang: string, prevLanguage: string) {
    try {
      let response = await serviceAdapter.sendPostRequest('/api/myaccount/changelanguage', {
        lang,
      })

      if (response.status >= 400) {
        this.error = 'failedToUpdateLanguage'
        this.hideErrorAfterTimeout()
        throw new Error('Bad response from server')
      }
    } catch (err) {
      this.identities.consumerIdentity.communicationLanguage = prevLanguage
      console.error(err)
    }
  }

  async saveMarketingChanges() {
    this.savingChanges = true
    try {
      const marketingSettings = toJS(this.identities.consumerIdentity.marketingPermissions)

      let response = await serviceAdapter.sendPostRequest('/api/myaccount/changemarketing', {
        marketing: marketingSettings,
      })

      if (response.status >= 400) {
        this.error = 'marketingChanges'
        this.hideErrorAfterTimeout()
        this.savingChanges = false
        throw new Error('Bad response from server')
      }

      runInAction(() => {
        this.savingChanges = false
        this.changesMade = false
        this.error = 'marketingSuccess'
      })
    } catch (err) {
      console.error(err)
    }
  }

  toggleMarketingPermission(permission: string): void {
    this.changesMade = true
    const currentPermissions = toJS(this.identities.consumerIdentity.marketingPermissions)
    if (currentPermissions.includes(permission)) {
      const filteredPermissions = currentPermissions.filter((curPerm: string) => curPerm !== permission)
      this.identities.consumerIdentity.marketingPermissions = filteredPermissions
      return
    }
    this.identities.consumerIdentity.marketingPermissions.push(permission)
  }

  setLanguage(lang: string) {
    const prevLang = this.identities.consumerIdentity.communicationLanguage
    this.identities.consumerIdentity.communicationLanguage = lang
    this.updateLangToBackend(lang, prevLang)
  }

  setPhoneNumber(number: string) {
    this.identities.consumerIdentity.consumerPhoneNumber.value = number
  }

  async hideErrorAfterTimeout() {
    if (this.hideNotiTimeout) {
      clearTimeout(this.hideNotiTimeout)
    }

    this.hideNotiTimeout = setTimeout(() => {
      this.error = ''
    }, 5000)
  }

  setPreferredName = (name: string): void => {
    this.identities.consumerIdentity.preferredName = name
  }

  restoreOriginalMarketingPermissions() {
    this.identities.consumerIdentity.marketingPermissions = toJS(this.savedMarketingPermissions)
  }

  fetchIdentities = async (withoutLogin = false) => {
    this.isLoading = true
    try {
      let response = !withoutLogin
        ? await serviceAdapter.sendGetRequest('/api/myaccount/identities')
        : await serviceAdapter.sendGetRequestWithoutLogin('/api/myaccount/identities')

      const data = await response.json()

      runInAction(() => {
        this.identities = data
        this.isLoading = false
        this.savedMarketingPermissions = data?.consumerIdentity.marketingPermissions
      })
    } catch (e) {
      throw e
    } finally {
      this.isLoading = false
    }
  }

  removeOrg = async (businessId: string) => {
    this.isLoading = true

    // find out what the enpoint needs
    // just the org ID?
    try {
      let response = await serviceAdapter.sendDeleteRequest(`/api/myaccount/organizations/${businessId}`)

      if (response.status >= 400) {
        this.isLoading = false
        throw new Error('Bad response from server')
      }

      runInAction(() => {
        this.isLoading = false

        const filteredOrgs = this.identities.businessIdentities.organizations.filter((org) => {
          return org.businessId !== businessId
        })
        this.identities.businessIdentities.organizations = filteredOrgs
      })
    } catch (err) {
      console.error(err)
    }
  }

  setOrganizationPhoneToUserAccountsMyOrganizations = (phone: string): void => {
    this.identities.businessIdentities.organizationPhoneNumber = phone
  }
}

export const userStore = new UserStore()
