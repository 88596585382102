import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx'

import { serviceAdapter } from '../service/serviceAdapter'
import { validateResetEmail } from '../utils/validation'
import { infoStore } from './infoStore'

export const EMAIL_PENDING_VERIFICATION_KEY = 'EMAIL_PENDING_VERIFICATION_KEY'

/**
 * Default value 30 minutes
 * Can be controlled from window.customVerificationTTl for testing
 */
const EMAIL_VERIFICATION_TTL = 30 * 60 * 1000

export interface IEmailVerificationPending {
  email: string
  expires: number
  expirationHandler?: ReturnType<typeof setTimeout>
}

/**
 * Initialize only after info is loaded.
 */
reaction(
  () => infoStore.userInfo?.email,
  () => {
    editEmailStore.processPendingVerification()
    editEmailStore.startPoll()
  }
)

export class EditEmailStore {
  newEmail: string

  savingChanges: boolean

  error: boolean

  emailAlreadyInUse: boolean

  changeSuccess: boolean

  hideErrorTimeout: ReturnType<typeof setTimeout> | undefined

  currentEmail: string

  isNewEmailValid: boolean

  emailPendingVerification: IEmailVerificationPending | null = null

  pollingInterval: ReturnType<typeof setInterval> | null = null

  constructor() {
    makeObservable(this, {
      emailPendingVerification: observable,
      newEmail: observable,
      savingChanges: observable,
      error: observable,
      emailAlreadyInUse: observable,
      changeSuccess: observable,
      isNewEmailValid: observable,
      setNewEmail: action,
      saveNewEmail: action,
      hideErrorAfterTimeout: action,
      reset: action,
      addPendingVerification: action,
      expirePendingVerification: action,
      processPendingVerification: action,
      startPoll: action,
      isEmailVerified: computed,
      hasPendingVerifications: computed,
    })

    this.reset()
    this.startPoll()
  }

  // Get the pending verification from localStorage
  private getPendingVerification(): IEmailVerificationPending | null {
    const pendingVerificationStr = localStorage.getItem(EMAIL_PENDING_VERIFICATION_KEY)
    if (!pendingVerificationStr) return null
    return JSON.parse(pendingVerificationStr)
  }

  // Save the pending verification to localStorage
  private setPendingVerification(pendingVerification: IEmailVerificationPending | null) {
    localStorage.setItem(EMAIL_PENDING_VERIFICATION_KEY, JSON.stringify(pendingVerification))
  }

  // Check if the current email is verified
  get isEmailVerified() {
    return !!this.emailPendingVerification && infoStore.userInfo.email === this.emailPendingVerification.email
  }

  // Check if there are pending verifications
  get hasPendingVerifications() {
    return !!this.emailPendingVerification
  }

  setInitialState = () => {
    this.newEmail = ''
    this.savingChanges = false
    this.error = false
    this.emailAlreadyInUse = false
    this.changeSuccess = false
    this.currentEmail = infoStore.userInfo?.email || ''
    this.isNewEmailValid = validateResetEmail(this.newEmail, this.currentEmail)
  }

  // Reset the state of the store
  reset = () => {
    this.setInitialState()
    this.processPendingVerification()
  }

  setNewEmail(value: string) {
    this.newEmail = value
    this.isNewEmailValid = validateResetEmail(value, this.currentEmail)
  }

  async saveNewEmail() {
    this.savingChanges = true
    try {
      let response = await serviceAdapter.sendPostRequest('/api/myaccount/changeemail', {
        email: this.newEmail,
      })

      if (response.status >= 400) {
        this.error = true
        this.savingChanges = false
        this.hideErrorAfterTimeout()
        if (response.status == 409) {
          this.emailAlreadyInUse = true
          throw new Error('Email already in use')
        } else {
          throw new Error('Bad response from server')
        }
      }

      runInAction(() => {
        this.savingChanges = false
        this.changeSuccess = true
      })

      // Add the pending verification and start polling
      this.addPendingVerification(this.newEmail)
      this.startPoll()
    } catch (err) {
      console.error(err)
    }
  }

  async hideErrorAfterTimeout() {
    if (this.hideErrorTimeout) {
      clearTimeout(this.hideErrorTimeout)
    }

    this.hideErrorTimeout = setTimeout(() => {
      this.error = false
      this.emailAlreadyInUse = false
    }, 5000)
  }

  // Expire a pending verification
  expirePendingVerification = () => {
    this.setPendingVerification(null)
    this.emailPendingVerification = null

    this.setInitialState()
  }

  // Add a new pending verification
  addPendingVerification = (email: string): IEmailVerificationPending => {
    const expirationTime = Date.now() + EMAIL_VERIFICATION_TTL
    const pendingVerification: IEmailVerificationPending = { email, expires: expirationTime }

    // Save to localStorage
    this.setPendingVerification(pendingVerification)

    // Clear existing expiration handler if exists
    if (this.emailPendingVerification?.expirationHandler) {
      clearTimeout(this.emailPendingVerification.expirationHandler)
    }

    // Set new expiration handler
    const expirationHandler = setTimeout(() => {
      this.expirePendingVerification()
    }, window.customVerificationTTl || EMAIL_VERIFICATION_TTL)

    this.emailPendingVerification = {
      ...pendingVerification,
      expirationHandler,
    }

    return this.emailPendingVerification
  }

  // Process pending verifications
  processPendingVerification = (): void => {
    const pendingVerification = this.getPendingVerification()

    // If no pending verification, expired or verified
    if (
      !pendingVerification ||
      pendingVerification.expires < Date.now() ||
      infoStore.userInfo.email === pendingVerification.email
    ) {
      this.expirePendingVerification()
      return
    }

    // Otherwise, update the current pending verification
    this.addPendingVerification(pendingVerification.email)
  }

  // Stop polling for changes
  stopPoll = (): void => {
    if (this.pollingInterval) {
      this.expirePendingVerification()

      clearInterval(this.pollingInterval)
      this.pollingInterval = null
    }
  }

  // Poll for information
  pollInfo = async (): Promise<void> => {
    if (this.isEmailVerified || !this.hasPendingVerifications) {
      this.stopPoll()
      return
    }

    try {
      await infoStore.fetchUserInfo(false, true)

      if (this.isEmailVerified) {
        this.stopPoll()
      }
    } catch (err) {
      this.stopPoll()
    }
  }

  // Start polling for changes
  startPoll = (): void => {
    if (!this.pollingInterval && this.hasPendingVerifications) {
      this.pollingInterval = setInterval(this.pollInfo, 10000)
    }
  }
}

export const editEmailStore = new EditEmailStore()
