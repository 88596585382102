import styled from 'styled-components'

import { RoundedPageSection } from './PageSection'

export const MaxWidthLimiter = styled.div<{ max?: number }>`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: ${({ max }) => (max ? max : '640')}px;

  @media (max-width: ${({ max }) => (max ? max - 1 : '639')}px) {
    // Rounded page sections should not be rounded when
    // they reach the sides of the browser window
    & ${RoundedPageSection} {
      border-radius: 0px;
    }
  }
`
