import { BrandTheme } from '@postidigital/posti-theme'

export const breakpoint = {
  xs: `(min-width: ${BrandTheme.breakpoint.xs})`,
  sm: `(min-width: ${BrandTheme.breakpoint.sm})`,
  md: `(min-width: ${BrandTheme.breakpoint.md})`,
  lg: `(min-width: ${BrandTheme.breakpoint.lg})`,
  mobile: `(min-width: ${BrandTheme.breakpoint.xs}) and (max-width: 767px)`,
}

export function checkIfMobile(): boolean {
  return /Mobi|Android/i.test(navigator.userAgent)
}

// New breakpoint definition copied from omaposti-web-frontend. Let's start using this instead.
const MOBILE_HEIGHT_BREAKPOINT = '450px'
export const breakpoint2 = {
  smallMobile: `screen and (min-width: 0) and (max-width: 22em)`,
  // Use a custom 47.9375em === 767px value so that the there is no overlap and iPad viewport looks correct
  mobile: `screen and (min-width: 0) and (max-width: 47.9375em)`,
  mobileInLandscape: `screen and (min-width: 0) and (max-height: ${MOBILE_HEIGHT_BREAKPOINT})`,
  smallMobileInLandscape: `screen and (min-width: 0) and (max-height: 22em)`,
  mobileAndUp: `screen and (min-width: ${BrandTheme.breakpoint.xs})`,
  tablet: `screen and (min-width: ${BrandTheme.breakpoint.sm}) and (max-width: ${BrandTheme.breakpoint.md}) and (min-height: ${MOBILE_HEIGHT_BREAKPOINT})`,
  tabletAndUp: `screen and (min-width: ${BrandTheme.breakpoint.md}) and (min-height: ${MOBILE_HEIGHT_BREAKPOINT})`,
  belowLaptop: `screen and (max-width: ${BrandTheme.breakpoint.lg})`,
  laptop: `screen and (min-width: ${BrandTheme.breakpoint.lg}) and (max-width: ${BrandTheme.breakpoint.xl})`,
  laptopAndUp: `screen and (min-width: ${BrandTheme.breakpoint.lg})`,
  belowDesktop: `screen and (max-width: ${BrandTheme.breakpoint.xl})`,
  desktop: `screen and (min-width: ${BrandTheme.breakpoint.xl})`,
  largeDesktop: `screen and (min-width: ${BrandTheme.breakpoint.xxl})`,
}
