/**
 * Encodes a given string to a Base64 string using UTF-8 encoding.
 * This handles multibyte characters properly, ensuring compatibility with decoding on various platforms.
 *
 * @param input - The string to be encoded.
 * @returns The Base64 encoded string.
 *
 * @example
 * // Example usage:
 * const password = "äää111AAAAa!";
 * const encodedPassword = encodeToBase64(password);
 * console.log(encodedPassword); // Outputs the Base64 encoded string, e.g., "w6TDpMOkMTExQUFBQWEh"
 */
export const encodeToBase64 = (input: string): string => {
  // Convert the input string to a Uint8Array using UTF-8 encoding
  const uint8Array = new TextEncoder().encode(input)

  // Convert the Uint8Array to a binary string using map and join
  const binaryString = Array.from(uint8Array)
    .map((byte) => String.fromCharCode(byte))
    .join('')

  // Encode the binary string to Base64
  return btoa(binaryString)
}
