import { action, makeObservable, observable, runInAction } from 'mobx'
import { createContext } from 'react'

import RegistrationService from '../../service/registration_service/RegistrationService'
import { encodeToBase64 } from '../../utils/password'
import { IOrgInvite } from '../dataModels/interfaces'

export enum IAcceptState {
  Loading,
  Invalid,
  Accepting,
  Registering,
  Registered,
  Accepted,
  PreviouslyAccepted,
  ErrorOccurred,
}

export class AcceptStore {
  state: IAcceptState = IAcceptState.Loading

  code: string = ''

  invite: IOrgInvite = {
    email: '',
    organizationName: '',
    businessId: '',
    invitorEmail: '',
    invitorName: '',
    invitedByPosti: false,
    userExists: false,
    accepted: false,
    hasPrimaryService: false,
    hasSecondaryService: false,
    primaryReturnUrl: '',
  }

  firstName: string = ''

  constructor() {
    makeObservable(this, {
      state: observable,
      code: observable,
      invite: observable,
      firstName: observable,
      setFirstName: action,
      setState: action.bound,
      setCode: action,
      loadInvite: action,
      register: action,
      accept: action,
    })
  }

  public setFirstName(firstName: string) {
    this.firstName = firstName
  }

  public setState(state: IAcceptState) {
    this.state = state
  }

  public setCode(code: string) {
    this.code = code
  }

  public loadInvite = async () => {
    try {
      const response = await RegistrationService.getInvite(this.code)
      runInAction(() => {
        this.invite = response
        this.setState(this.invite.accepted ? IAcceptState.PreviouslyAccepted : IAcceptState.Accepting)
      })
      return !this.invite.accepted
    } catch (tryAgain) {
      runInAction(() => {
        this.setState(tryAgain ? IAcceptState.ErrorOccurred : IAcceptState.Invalid)
      })
    }
    return false
  }

  public register = async (firstName: string, lastName: string, password: string, phoneNumber: string) => {
    const registrationResponse = await RegistrationService.registerUser({
      inviteCode: this.code,
      firstName: firstName,
      lastName,
      mobilePhoneNumber: phoneNumber,
      password: encodeToBase64(password),
      passwordEncoded: true,
      acceptInvite: true,
    })
    if (!registrationResponse) {
      runInAction(() => {
        this.setState(IAcceptState.ErrorOccurred)
      })
      return
    }

    const acceptResponse = await RegistrationService.acceptInvite(this.code)
    if (!acceptResponse) {
      runInAction(() => {
        this.setState(IAcceptState.ErrorOccurred)
      })
      return
    }

    runInAction(() => {
      this.setState(IAcceptState.Registered)
    })
  }

  public accept = async () => {
    const success = await RegistrationService.acceptInvite(this.code)
    runInAction(() => {
      this.setState(success ? IAcceptState.Accepted : IAcceptState.ErrorOccurred)
    })
  }
}

export default createContext(new AcceptStore())
