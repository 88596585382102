import { EnvelopeIcon, Notification } from '@postidigital/posti-components/build/brand'
import { sendUserAction } from '@postidigital/posti-google-analytics'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'

import { ContentContainer, ContentSection, PostiPageFooter } from '../../../components'
import { DelayLinkButton } from '../../../components/buttons/DelayLinkButton'
import { PostiPageLoading } from '../../../components/layout/postiPageLoading/PostiPageLoading'
import { StaticInputLayout } from '../../../components/StaticInputLayout'
import { ResponsiveBody } from '../../../components/typography/ResponsiveBody'
import { ResponsiveHeadline } from '../../../components/typography/ResponsiveHeadline'
import { ResponsiveLinkButton } from '../../../components/typography/ResponsiveLinkButton'
import { registrationStore } from '../../../store'
import { GeneralErrorNotificationWithRetryButton } from '../components/GeneralErrorNotificationWithRetryButton'
import { RegistrationModal } from '../components/RegistrationModal'
import { SubmitSvg } from '../registration.styles'
import { sendConsumerRegistrationErrorUserAction } from '../RegistrationMainView'
import { AlertNotification } from './AlertNotification'
import { EditEmailComponent } from './EditEmail'

export enum VERIFY_EMAIL_ERROR {
  INIT_FAIL = 'email_verification_init_failed',
  SEND_NEW_LINK_ERROR = 'send_new_link_error',
  INTERNAL_ERROR = 'internal_error',
  EMAIL_IN_USE_ERROR = 'email_in_use_error',
}

const VerifyEmailComponent: React.FC = observer(() => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const [initialising, setInitialising] = useState(true)
  const [error, setError] = useState<VERIFY_EMAIL_ERROR | null>(null)
  const [newLinkSent, setNewLinkSent] = useState(false)
  const [requestingNewLink, setRequestingNewLink] = useState(false)

  useEffect(() => {
    if (registrationStore.changeRegistrationEmailRequestFailed) {
      clearAllNotifications()
      setError(VERIFY_EMAIL_ERROR.INTERNAL_ERROR)
    }
  }, [])

  useEffect(() => {
    // Initialise email verification in server-side
    setInitialising(true)
    registrationStore
      .sendVerifyEmailLink(registrationStore.info!.email as string)
      .catch((res) => {
        if (res?.status === 409) {
          setModalOpen(true)
          return setError(VERIFY_EMAIL_ERROR.EMAIL_IN_USE_ERROR)
        }

        setError(VERIFY_EMAIL_ERROR.INIT_FAIL)
      })
      .finally(() => {
        setInitialising(false)
      })
  }, [])

  // Send error codes to analytics
  useEffect(() => {
    if (error) {
      sendConsumerRegistrationErrorUserAction(error)
    }
  }, [error])

  const clearAllNotifications = () => {
    setError(null)
    setNewLinkSent(false)
  }

  const onSendNewLink = useCallback(async () => {
    clearAllNotifications()
    setRequestingNewLink(true)
    try {
      await registrationStore.sendVerifyEmailLink(registrationStore.info!.email as string)
      setNewLinkSent(true)
    } catch (message) {
      setError(VERIFY_EMAIL_ERROR.SEND_NEW_LINK_ERROR)
    } finally {
      setRequestingNewLink(false)
    }
  }, [])

  const onCloseModal = useCallback(() => {
    setModalOpen(false)
  }, [])

  const onClearParentErrors = useCallback(() => {
    setError(null)
  }, [])

  const renderContent = () => {
    return (
      <>
        <SubmitSvg />
        {(newLinkSent || registrationStore.infoReFetched) && (
          <Notification id="notification_container" contentType="success" dismissible={false}>
            {t('registration.verifyEmail.newLinkSent')}
          </Notification>
        )}
        {error && <AlertNotification error={error} />}
        <ContentSection gap="lg" top={!!(newLinkSent || registrationStore.infoReFetched || error)}>
          <ResponsiveBody>{t('registration.verifyEmail.weSentYou')}</ResponsiveBody>
          <StaticInputLayout
            label={t('registration.verifyEmail.email')}
            content={registrationStore.info!.email}
            Icon={EnvelopeIcon}
          />
          <ResponsiveBody>{t('registration.verifyEmail.instruction')}</ResponsiveBody>
          <Notification id="notification_you_can_close_info" contentType="informational" dismissible={false}>
            {t('registration.verifyEmail.windowCanBeClosedInfo')}
          </Notification>
          <div>
            <ResponsiveBody style={{ fontWeight: 600 }}>{t('registration.verifyEmail.didNotReceive')}</ResponsiveBody>
            <ResponsiveBody>
              {t('registration.verifyEmail.checkSpamOr')}
              <span>&nbsp;</span>
            </ResponsiveBody>
            <ResponsiveLinkButton
              id="button_edit_email"
              onClick={() => {
                clearAllNotifications()
                setModalOpen(true)
                sendUserAction({
                  actionType: 'changeEmail',
                  stage: 'open',
                })
              }}
            >
              {t('registration.verifyEmail.reEnterEmail')}.
            </ResponsiveLinkButton>
            <br />
            <DelayLinkButton
              id="button_send_new_link"
              delay={5000}
              onClick={onSendNewLink}
              isLoading={requestingNewLink}
            >
              {t('registration.verifyEmail.sendNew')}
            </DelayLinkButton>
          </div>
        </ContentSection>
      </>
    )
  }

  // When email verification init fails, we only show the header and alert notification
  if (error === VERIFY_EMAIL_ERROR.INIT_FAIL) {
    return (
      <ContentContainer>
        <ResponsiveHeadline as="h1">{t('registration.verifyEmail.title')}</ResponsiveHeadline>
        <ContentSection>
          <GeneralErrorNotificationWithRetryButton
            additionalMsg={t('registration.verifyEmail.errorMessages.tooManyTimesTryAgain')}
          />
        </ContentSection>
      </ContentContainer>
    )
  }

  if (initialising) {
    return <PostiPageLoading id="registrationLoading" />
  }

  return (
    <>
      <ContentContainer>
        <ResponsiveHeadline as="h1">{t('registration.verifyEmail.title')}</ResponsiveHeadline>
        {renderContent()}
        <PostiPageFooter showCookiePreferences />
      </ContentContainer>
      <RegistrationModal isOpen={modalOpen} title={t('registration.verifyEmail.reEnterEmail')}>
        <EditEmailComponent
          onCancel={onCloseModal}
          onCloseModal={onCloseModal}
          onClearParentErrors={onClearParentErrors}
          externalErrorMessage={
            error === VERIFY_EMAIL_ERROR.EMAIL_IN_USE_ERROR ? t('registration.create.errorMessages.emailTaken') : ''
          }
          preventCancel={error === VERIFY_EMAIL_ERROR.EMAIL_IN_USE_ERROR}
        />
      </RegistrationModal>
    </>
  )
})

export const VerifyEmail = withRouter(VerifyEmailComponent)
