import { ArrowRightIcon, Button, EnvelopeIcon, Notification } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  FormEventHandler,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonContainer, ContentSection } from '../../components'
import { AbsoluteMessageInput } from '../../components/general/input'
import { ResponsiveBody } from '../../components/typography/ResponsiveBody'
import { ResponsiveLink } from '../../components/typography/ResponsiveLink'
import Localization, { Locale } from '../../locales'
import { corporateAccountRecoveryStore, StoreContext } from '../../store'

const EmailStep: FC<{ mail?: string }> = ({ mail }) => {
  const { t } = useTranslation()
  const [touched, setTouched] = useState<boolean>(false)
  const {
    corporateAccountRecoveryStore: { email, isValid, error, message, isSaving },
  } = useContext(StoreContext)
  const lang: Locale = Localization.CurrentLocale as Locale

  const handleInputBlur: FocusEventHandler<HTMLInputElement> = useCallback(() => {
    setTouched(true)
  }, [])

  const handleEmail: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    corporateAccountRecoveryStore.resetError()
    corporateAccountRecoveryStore.setEmail(event.target.value)
  }, [])

  const handleSubmitCodeRequest: FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()

      if (isSaving) {
        return null
      }

      return corporateAccountRecoveryStore.requestCode()
    },
    [isSaving]
  )

  const renderText = useMemo(() => {
    if (mail && mail === '0') {
      return <ResponsiveBody>{t('accountRecovery.corporate.emailStep.mailReset.content0')}</ResponsiveBody>
    }

    return (
      <>
        <ResponsiveBody>{t('accountRecovery.corporate.emailStep.content0')}</ResponsiveBody>
        <ResponsiveBody as="p">
          {t('accountRecovery.corporate.emailStep.content1')}
          <ResponsiveLink style={{ display: 'inline' }} href={`/account-recovery-info?lang=${lang}`} target="_blank">
            {t('accountRecovery.corporate.emailStep.contentLink')}
          </ResponsiveLink>
        </ResponsiveBody>
      </>
    )
  }, [lang, mail, t])

  return (
    <form id="corporate-recovery-init" onSubmit={handleSubmitCodeRequest}>
      <ContentSection>
        {renderText}
        {error && message && (
          <Notification dismissible={false} contentType="alert">
            {t(message)}
          </Notification>
        )}
      </ContentSection>
      <ContentSection>
        <AbsoluteMessageInput
          autoFocus
          lightBackground
          id="email-input"
          value={email}
          onChange={handleEmail}
          onBlur={handleInputBlur}
          isInvalid={touched && !isValid}
          label={t('accountRecovery.corporate.emailStep.inputLabel')}
          leftTemplate={<EnvelopeIcon width="24px" />}
          message={touched && !isValid ? t('mainView.editEmail.validationError') : undefined}
        />
      </ContentSection>
      <ButtonContainer>
        <Button
          size="sm"
          type="submit"
          id="corporate_recovery_email"
          contentMode="primary"
          hasBackground
          icon={ArrowRightIcon}
          iconPosition="right"
          disabled={!isValid}
        >
          {t('accountRecovery.corporate.emailStep.ctaLabel')}
        </Button>
      </ButtonContainer>
    </form>
  )
}

export default observer(EmailStep)
