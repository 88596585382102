import { Modal, ModalStateWrapper } from '@postidigital/posti-components'
import { Button } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { FC, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ButtonContainer, ContentSection } from '../../components'
import { ResponsiveBody } from '../../components/typography/ResponsiveBody'
import { ICountdownResponse } from '../../hooks/useCountdown'

const AccountRecoveryLogoutModal: FC<{
  countDownData: ICountdownResponse['data']
  reset: ICountdownResponse['reset']
  logout: () => void
}> = ({ countDownData: { minutes, seconds }, reset, logout }) => {
  const { t } = useTranslation()

  const getTimeLabel = useCallback(
    (time: number, format: 'm' | 's') => {
      if (time === 1) {
        if (format === 'm') {
          return t(`accountRecovery.time.minute`)
        }

        return t(`accountRecovery.time.second`)
      }

      if (format === 'm') {
        return t(`accountRecovery.time.minutes`)
      }

      return t(`accountRecovery.time.seconds`)
    },
    [t]
  )

  const timeString = `${minutes > 0 ? `${minutes} ${getTimeLabel(minutes, 'm')}` : ''}${
    seconds > 0 ? ` ${seconds} ${getTimeLabel(seconds, 's')}` : ''
  }`

  if (minutes > 2) {
    return null
  }

  return (
    <ModalStateWrapper open={true}>
      <Modal mobileMode="alert">
        <Modal.Content>
          <Modal.Title size="Five">{t('accountRecovery.reset.sessionModal.title')}</Modal.Title>
          <Modal.MainContent>
            <ContentSection>
              <ResponsiveBody style={{ whiteSpace: 'pre-line' }}>
                <Trans
                  i18nKey="accountRecovery.reset.sessionModal.content"
                  values={{ time: timeString }}
                  components={[<b />, <br />]}
                />
              </ResponsiveBody>
            </ContentSection>
            <ButtonContainer>
              <Button id="account_recovery_exit" contentMode="secondary" onClick={logout} size="sm">
                {t('accountRecovery.reset.sessionModal.exit')}
              </Button>
              <Button id="account_recovery_continue" type="button" contentMode="primary" onClick={reset} size="sm">
                {t('accountRecovery.reset.sessionModal.continue')}
              </Button>
            </ButtonContainer>
          </Modal.MainContent>
        </Modal.Content>
      </Modal>
    </ModalStateWrapper>
  )
}

export default observer(AccountRecoveryLogoutModal)
