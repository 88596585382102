import { Input, Notification, ProfileIcon } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { ChangeEventHandler, FormEventHandler, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { StoreContext, userStore } from '../../store'
import { ButtonContainer, CancelButton, ContentSection, SaveButton, StyledWrapper } from '..'
import { StaticInputLayout } from '../StaticInputLayout'

const EditPreferredNameComponent: React.FC = () => {
  const { t } = useTranslation()
  const { mainViewStore, editPreferredNameStore } = useContext(StoreContext)
  const { error, newPreferredName, changeSuccess, savingChanges } = editPreferredNameStore

  const canSave = useCallback((): boolean => {
    if (!newPreferredName) {
      return false
    }

    if (newPreferredName == userStore.identities?.consumerIdentity?.preferredName) {
      return false
    }

    return !(savingChanges || changeSuccess)
  }, [changeSuccess, newPreferredName, savingChanges])

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      editPreferredNameStore.setNewPreferredName(event.target.value)
    },
    [editPreferredNameStore]
  )

  const handleSave: FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()

      if (!canSave()) {
        return null
      }

      return editPreferredNameStore.savePreferredName()
    },
    [canSave, editPreferredNameStore]
  )

  return (
    <StyledWrapper>
      <form id="edit_prefered_name_form" onSubmit={handleSave}>
        <ContentSection>
          {!!userStore.identities?.consumerIdentity?.preferredName.trim() && (
            <StaticInputLayout
              label={t('mainView.editPreferredName.oldPreferredName')}
              content={userStore.identities?.consumerIdentity?.preferredName}
              Icon={ProfileIcon}
            />
          )}

          <Input
            autoFocus
            lightBackground
            id="preferredName-input"
            label={t(`mainView.editPreferredName.newPreferredName`)}
            value={newPreferredName}
            onChange={handleChange}
            isInvalid={!Boolean(newPreferredName)}
          />

          {error && (
            <Notification contentType="alert" dismissible={false}>
              {t(`mainView.editPreferredName.error`)}
            </Notification>
          )}

          {changeSuccess && (
            <Notification contentType="success" dismissible={false}>
              {t(`mainView.editPreferredName.success`)}
            </Notification>
          )}
        </ContentSection>
        <ButtonContainer>
          <CancelButton
            type="button"
            id="cancel_preferredname_button"
            onClick={mainViewStore.reset}
            disabled={editPreferredNameStore.savingChanges}
            translationId={editPreferredNameStore.changeSuccess ? 'general.close' : 'general.cancel'}
          />
          <SaveButton type="submit" id="change_preferredname_button" disabled={!canSave()} />
        </ButtonContainer>
      </form>
    </StyledWrapper>
  )
}

export const EditPreferredName = observer(EditPreferredNameComponent)
