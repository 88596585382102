import Localization from '../../locales'

/**
 * A list of services with configuration
 */
export const SERVICE_LIST_CONFIG = {
  ompaposti: {
    service: 'ompaposti',
    labelTranslationId: 'serviceLinkLabel.ompaposti',
    link: window['postienv']?.serviceLinks?.ompaposti?.[Localization.CurrentLocale],
  },
  moving: {
    service: 'moving',
    labelTranslationId: 'serviceLinkLabel.moving',
    link: window['postienv']?.serviceLinks?.moving?.[Localization.CurrentLocale],
  },
  shop: {
    service: 'shop',
    labelTranslationId: 'serviceLinkLabel.shop',
    link: window['postienv']?.serviceLinks?.shop?.[Localization.CurrentLocale],
  },
  address: {
    service: 'address',
    labelTranslationId: 'serviceLinkLabel.address',
    link: window['postienv']?.serviceLinks?.address?.[Localization.CurrentLocale],
  },
} as const
