import { PostiLogo } from '@postidigital/posti-components'
import { Body, LinkButton, LogoutIcon } from '@postidigital/posti-components/build/brand'
import { BrandTheme } from '@postidigital/posti-theme'
import { observer } from 'mobx-react-lite'
import React, { MutableRefObject, useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css, DefaultTheme, StyledComponent } from 'styled-components'

import { LangSelectButton } from '../../components'
import { serviceAdapter } from '../../service/serviceAdapter'
import { StoreContext } from '../../store'
import { breakpoint } from '../../utils'
import { ExitRegistrationModal } from '../../views/registration/components/ExitRegistrationModal'
import CookieLink from '../general/CookieLink'
import { MobileNav } from './MobileNav'

interface IProps {
  test?: string
  hideLogout?: boolean
  hideLang?: boolean
  hideNavigation?: boolean
  hasShadow?: boolean
  fullWidth?: boolean
  overrideLogOutButtonText?: string
  promptBeforeLogout?: boolean
  exitRegistrationModalBodyText?: string
  showCookieIcon?: boolean
}

const TopNavComponent: React.FC<IProps> = ({
  hideLogout,
  hideLang,
  hideNavigation = false,
  hasShadow = true,
  fullWidth = false,
  overrideLogOutButtonText,
  promptBeforeLogout = false,
  exitRegistrationModalBodyText = '',
  showCookieIcon = false,
}) => {
  const { t } = useTranslation()
  const { layoutStore } = useContext(StoreContext)
  const formRef: MutableRefObject<any> = useRef(null)
  const [exitModalOpen, setExitModalOpen] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false)

  const showMobileNav = layoutStore.isMobile && !hideNavigation
  const showTopbarLanguageSelector = !hideLang && !showMobileNav

  const logOutButtonText = overrideLogOutButtonText ? overrideLogOutButtonText : t('general.logout')

  const onLogout = (e: any) => {
    e.preventDefault()
    if (promptBeforeLogout) {
      setExitModalOpen(true)
    } else {
      serviceAdapter.handleLogOut()
    }
  }

  return (
    <StyledTopNav isFixed={mobileMenuOpen} hasShadow={hasShadow}>
      <StyledNavContainer fullWidth={fullWidth}>
        {showMobileNav && !layoutStore.isAppWebView && <MobileNav />}
        <div>
          <a href="https://www.posti.fi">
            <PostiLogo textColor={BrandTheme.color.postiOrange50} height="2em" />
          </a>
        </div>
        {!layoutStore.isAppWebView && (
          <StyledControlsContainer>
            {showTopbarLanguageSelector && <LangSelectButton isLast={hideLogout} />}
            {!hideLogout && (
              <form ref={formRef} id="logout_form" method="post" onSubmit={onLogout}>
                <LinkButton
                  type="submit"
                  id="logout_button"
                  contentMode="secondary"
                  style={{ padding: 0, margin: 0 }}
                  size="xs"
                  icon={LogoutIcon}
                  iconPosition="left"
                >
                  {logOutButtonText}
                </LinkButton>
              </form>
            )}

            {showCookieIcon && <CookieLink idString="side_nav_cookie_link" iconOnly />}
          </StyledControlsContainer>
        )}

        {showMobileNav && layoutStore.isAppWebView && <MobileNav setMobileMenuOpen={setMobileMenuOpen} />}
      </StyledNavContainer>
      <ExitRegistrationModal
        isOpen={exitModalOpen}
        title={t('registration.exitModal.title')}
        onCancel={() => setExitModalOpen(false)}
        onConfirm={serviceAdapter.handleLogOut}
      >
        <Body size={'Three'}>{exitRegistrationModalBodyText}</Body>
      </ExitRegistrationModal>
    </StyledTopNav>
  )
}

export const TopNav = observer(TopNavComponent)

export const topNavHeight = 72

const StyledNavContainer = styled.div<{ fullWidth: boolean }>(
  ({
    theme: {
      brand: {
        breakpoint: { xs, sm, xxl },
        spacing: { space2, space6, space4 },
      },
    },
    fullWidth,
  }) => css`
    max-width: ${fullWidth ? '100%' : xxl};
    width: 100%;
    min-width: ${xs};
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${space2}rem;
    flex-direction: row;
    padding: 0 ${space4}rem;

    @media (min-width: ${sm}) {
      padding: 0 ${space6}rem;
    }
  `
)

export const StyledTopNav: StyledComponent<'div', DefaultTheme, { hasShadow?: boolean; isFixed?: boolean }> = styled(
  'div'
)<{ hasShadow?: boolean; isFixed?: boolean }>(
  ({
    theme: {
      brand: {
        color: { gray70, white },
        breakpoint: { xs },
        spacing,
      },
    },
    hasShadow = true,
    isFixed = false,
  }) => {
    const boxShadow = hasShadow ? 'box-shadow: 0px 8px 22px rgba(27, 31, 35, 0.07);' : ''

    return css`
      background: ${white};
      color: ${gray70};
      width: 100%;
      position: ${isFixed ? 'fixed' : 'relative'};
      z-index: 100;
      min-width: ${xs};
      height: ${spacing.space26}rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      @media screen and (${breakpoint.md}) {
        ${boxShadow};
        height: ${spacing.space18}rem;
      }
    `
  }
)

const StyledControlsContainer = styled.div(
  ({
    theme: {
      brand: { spacing },
    },
  }) => css`
    align-items: center;
    display: flex;
    gap: ${spacing.space6}rem;

    @media screen and (${breakpoint.md}) {
      gap: ${spacing.space10}rem;
    }
  `
)
