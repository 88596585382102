import { Button } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams, withRouter } from 'react-router'

import { ButtonContainer, ContentContainer, ContentSection, PostiPage, PostiPageFooter, TopNav } from '../../components'
import { PostiPageLoading } from '../../components/layout/postiPageLoading/PostiPageLoading'
import { ResponsiveBody } from '../../components/typography/ResponsiveBody'
import { ResponsiveHeadline } from '../../components/typography/ResponsiveHeadline'
import Localization, { Locale } from '../../locales'
import ApiService from '../../service/ApiService'
import RegistrationService from '../../service/registration_service/RegistrationService'
import { RegistrationStep } from '../../store'
import { sendConsumerRegistrationPageInfo } from '../registration'
import {
  ConfusedErrorSvg,
  ListNotification,
  ListNotificationBody,
  SubmitSvg,
} from '../registration/registration.styles'

interface AccountVerifiedParams {
  code: string
}

const AccountVerifiedViewComponent: React.FC = observer(({}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [isRegistration, setIsRegistration] = useState(false)
  const { code } = useParams<AccountVerifiedParams>()
  const lang: Locale = Localization.CurrentLocale as Locale
  const history = useHistory()

  useEffect(() => {
    ApiService.verifyEmail(code)
      .then((response) => {
        setIsRegistration(response.registration)
        if (!response.verified) {
          setError(true)
        }
      })
      .catch(() => setError(true))
      .finally(() => {
        setLoading(false)
        RegistrationService.backgroundLogout()
      })
  }, [code])

  useEffect(() => {
    if (!loading && isRegistration) {
      const analyticsStepName = error ? 'verifiedError' : 'verified'
      sendConsumerRegistrationPageInfo(analyticsStepName as RegistrationStep)
    }
  }, [isRegistration, error, loading])

  const onGotoPosti = useCallback(() => (location.href = `https://posti.fi/${lang}`), [lang])

  const onGotoOmaPosti = useCallback(() => (location.href = window['postienv'].omapostiUniversalLinks[lang]), [lang])

  const onGotoMyAccount = useCallback(() => history.push('/'), [history])

  const onGotoRegistration = useCallback(() => history.push('/registration'), [history])

  const successContent = () => {
    return (
      <ContentContainer>
        <SubmitSvg />
        <ResponsiveHeadline as="h1">
          {isRegistration
            ? t('accountVerified.success.registrationTitle')
            : t('accountVerified.success.emailChangedTitle')}
        </ResponsiveHeadline>
        <ContentSection>
          {isRegistration ? (
            <>
              <ResponsiveBody>{t('accountVerified.success.welcome')}</ResponsiveBody>
              <ButtonContainer className={'swap-button-order-in-mobile'}>
                <Button hasBackground id="button_goto_omaposti" contentMode="primary" onClick={onGotoOmaPosti}>
                  {t('registration.buttons.gotoOmaPosti')}
                </Button>
                <Button id="button_back_to_posti" contentMode="secondary" onClick={onGotoPosti}>
                  {t('registration.buttons.backToPosti')}
                </Button>
              </ButtonContainer>
            </>
          ) : (
            <ButtonContainer>
              <Button id="button_goto_myaccount" contentMode="primary" onClick={onGotoMyAccount}>
                {t('accountVerified.success.continueToMyAccount')}
              </Button>
              <div style={{ flex: 1 }}></div>
            </ButtonContainer>
          )}
        </ContentSection>
        <PostiPageFooter showCookiePreferences />
      </ContentContainer>
    )
  }

  const errorContent = () => {
    return (
      <ContentContainer>
        <ResponsiveHeadline as="h1">{t('accountVerified.failure.title')}</ResponsiveHeadline>
        <ConfusedErrorSvg />
        <ListNotification contentType="alert" dismissible={false}>
          <>
            <ListNotificationBody>{t('accountVerified.failure.verificationFailedDueTo')}</ListNotificationBody>
            <ul>
              <li>
                <ListNotificationBody>{t('accountVerified.failure.reason1')}</ListNotificationBody>
              </li>
              <li>
                <ListNotificationBody>{t('accountVerified.failure.reason2')}</ListNotificationBody>
              </li>
              <li>
                <ListNotificationBody>{t('accountVerified.failure.reason3')}</ListNotificationBody>
              </li>
            </ul>
            {isRegistration ? (
              <ListNotificationBody>
                {t('accountVerified.failure.pleaseTryAgainAndReturnToRegistration')}
              </ListNotificationBody>
            ) : (
              <ListNotificationBody>
                {t('accountVerified.failure.pleaseTryAgainAndReturnToMyAccount')}
              </ListNotificationBody>
            )}
          </>
        </ListNotification>
        {isRegistration ? (
          <Button hasBackground id="button_return_to_registration" onClick={onGotoRegistration}>
            {t('accountVerified.failure.returnToRegistration')}
          </Button>
        ) : (
          <Button hasBackground id="button_return_to_myaccount" onClick={onGotoMyAccount}>
            {t('accountVerified.failure.returnToMyAccount')}
          </Button>
        )}
        <PostiPageFooter showCookiePreferences />
      </ContentContainer>
    )
  }

  const renderView = () => {
    if (loading) {
      return <PostiPageLoading id="accountVerifiedLoading" />
    } else if (error) {
      return errorContent()
    } else {
      return successContent()
    }
  }

  return (
    <>
      <TopNav
        hasShadow={false}
        hideLogout={loading || isRegistration}
        overrideLogOutButtonText={t('registration.buttons.exitRegistration')}
      />
      <PostiPage>{renderView()}</PostiPage>
    </>
  )
})

export const AccountVerifiedView = withRouter(AccountVerifiedViewComponent)
