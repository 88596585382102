import { PhoneIcon } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { MouseEventHandler, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ContentSection,
  EditButton,
  OrganisationExpander,
  OrgDialogContent,
  SideImageContainerContent,
  StyledButtonWrapper,
} from '../components'
import { EditOrganizationPhoneNumber } from '../components/orgs/EditOrganizationPhoneNumber'
import { StaticInputLayout } from '../components/StaticInputLayout'
import { ResponsiveBody } from '../components/typography/ResponsiveBody'
import { ResponsiveHeadline } from '../components/typography/ResponsiveHeadline'
import { organizationsUiStateStore, StoreContext } from '../store'
import { IDetailsUiState } from '../store/dataModels/interfaces'

const MyOrgsComponent: React.FC = () => {
  const [t] = useTranslation()
  const { orgStore, userStore } = useContext(StoreContext)
  const { isLoading } = userStore
  const { showModal, setShowModal } = orgStore

  const handleLeaveOrgModalClose: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    setShowModal(false)
  }, [setShowModal])

  if (isLoading) {
    return null
  }

  const getDetailsContent = (state: IDetailsUiState) => {
    switch (+state) {
      case IDetailsUiState.EditPhone:
        return <EditOrganizationPhoneNumber />

      default:
        return (
          <SideImageContainerContent>
            <ResponsiveHeadline as="h1">{t(`myOrgsView.title`)}</ResponsiveHeadline>
            <ContentSection>
              <StyledButtonWrapper>
                <StaticInputLayout
                  label={t(`myOrgsView.organizationPhoneNumber`)}
                  content={userStore.identities.businessIdentities.organizationPhoneNumber || '-'}
                  Icon={PhoneIcon}
                />
                <EditButton
                  id="change_organization_phone_button"
                  onClick={() => organizationsUiStateStore.setState(IDetailsUiState.EditPhone)}
                />
              </StyledButtonWrapper>
              <ResponsiveBody>{t(`myOrgsView.info`)}</ResponsiveBody>
              <ResponsiveBody>{t(`myOrgsView.askMoreInfo`)}</ResponsiveBody>
            </ContentSection>
            <ContentSection>
              {userStore.identities.businessIdentities.organizations.map((org) => {
                return <OrganisationExpander key={org.businessId} organization={org} />
              })}
            </ContentSection>
            <OrgDialogContent visible={showModal} onClose={handleLeaveOrgModalClose} />
          </SideImageContainerContent>
        )
    }
  }

  return <>{getDetailsContent(organizationsUiStateStore.state)}</>
}

export const MyOrgsView = observer(MyOrgsComponent)
