import { Modal } from '@postidigital/posti-components/'
import { Button, EnvelopeIcon, Input, Notification } from '@postidigital/posti-components/build/brand'
import { sendUserAction } from '@postidigital/posti-google-analytics'
import React, { FormEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ContentSection } from '../../../components'
import { ResponsiveBody } from '../../../components/typography/ResponsiveBody'
import { registrationStore } from '../../../store'
import { ButtonContainer } from '../components/RegistrationModal.styles'
import { defaultInputIconProps, ModalInputMinHeightContainer } from '../registration.styles'
import { EMAIL_IN_USE_CODE } from '../registrationForm/RegistrationForm'
import { sendConsumerRegistrationErrorUserAction } from '../RegistrationMainView'
import { getFormItemMessage, useFormFields } from '../utils/useFormFields'
import { validateEmail } from '../utils/validators'

type EditEmailFieldNames = 'email'

interface EditEmailComponentProps {
  onCancel: () => void
  onConfirm?: () => void
  onCloseModal: () => void
  onClearParentErrors?: () => void
  preventCancel?: boolean
  externalErrorMessage?: string
}

export const EditEmailComponent: React.FC<EditEmailComponentProps> = ({
  onCancel,
  onCloseModal,
  onClearParentErrors,
  preventCancel = false,
  externalErrorMessage = '',
}) => {
  const { t } = useTranslation()
  const [formFields, handleFieldChange] = useFormFields<EditEmailFieldNames>({
    email: {
      value: '',
      validator: validateEmail,
      isInvalid: false,
      touched: false,
      ref: useRef(),
      labelTranslationKey: 'registration.create.enterEmail',
      invalidMessageTranslationKey: 'registration.create.validation.enterEmail',
    },
  })
  const [error, setError] = useState<string>('')

  // Send error codes to analytics
  useEffect(() => {
    if (error) {
      sendConsumerRegistrationErrorUserAction('editEmailError')
    }
  }, [error])

  const isFormValid = useCallback(
    () => !Object.keys(formFields).some((fieldName) => formFields[fieldName as EditEmailFieldNames].isInvalid),
    [formFields]
  )

  const clearAllNotifications = () => {
    setError('')
  }

  const handleConfirm: FormEventHandler<HTMLFormElement> = useCallback(
    async (event) => {
      event.preventDefault()
      event.nativeEvent.stopImmediatePropagation()

      if (registrationStore.processingRequest) {
        return
      }

      handleFieldChange('validateAll')
      if (isFormValid()) {
        clearAllNotifications()

        registrationStore
          .updateRegistrationEmail(formFields.email.value)
          .then(() => {
            sendUserAction({
              actionType: 'changeEmail',
              stage: 'complete',
            })
            onCloseModal()
            onClearParentErrors?.()
          })
          .catch((response) => {
            if (response.status === 409 && response.message === EMAIL_IN_USE_CODE) {
              setError('registration.create.errorMessages.emailTaken')
            } else {
              setError('registration.verifyEmail.errorMessages.sendNewLinkError')
            }
            setImmediate(() => {
              formFields.email.ref?.current?.focus()
            })
          })
      } else {
        setTimeout(() => {
          formFields.email.ref?.current.focus()
        }, 0)
      }
    },
    [formFields.email.ref, formFields.email.value, handleFieldChange, isFormValid, onClearParentErrors, onCloseModal]
  )

  const handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  return (
    <Modal.MainContent>
      <form id="registration_email_reset_form" onSubmit={handleConfirm} noValidate>
        <ContentSection top={false}>
          {(error || externalErrorMessage) && (
            <Notification id="notification_container" contentType="alert" dismissible={false}>
              {t(error || externalErrorMessage)}
            </Notification>
          )}
          <ResponsiveBody>{t('registration.verifyEmail.reEnterEmailModal.instruction')}</ResponsiveBody>
          <ModalInputMinHeightContainer>
            <Input
              name="email"
              type="email"
              onChange={handleFieldChange}
              onBlur={handleFieldChange}
              id={'email'}
              ref={formFields.email.ref}
              value={formFields.email.value}
              isInvalid={(formFields.email.touched && formFields.email.isInvalid) || !!error}
              message={getFormItemMessage(formFields.email, t)}
              label={t(formFields.email.labelTranslationKey as string)}
              lightBackground
              leftTemplate={<EnvelopeIcon {...defaultInputIconProps} />}
            />
          </ModalInputMinHeightContainer>
        </ContentSection>
        <Modal.ButtonWrapper>
          <ButtonContainer>
            <Button
              type="button"
              disabled={preventCancel}
              size="sm"
              contentMode="secondary"
              hasBackground={false}
              onClick={handleCancel}
            >
              {t('general.cancel')}
            </Button>
            <Button hasBackground type="submit" size="sm" isLoading={registrationStore.processingRequest}>
              {t('registration.verifyEmail.reEnterEmailModal.sendLinkButton')}
            </Button>
          </ButtonContainer>
        </Modal.ButtonWrapper>
      </form>
    </Modal.MainContent>
  )
}
