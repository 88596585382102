import { Modal, ModalStateWrapper } from '@postidigital/posti-components'
import { SuccessAnimation } from '@postidigital/posti-components/build/animations'
import { CloseIcon } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { FC, useCallback, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { StoreContext } from '../../../store'
import { ContentSection } from '../../layout'
import { ResponsiveBody } from '../../typography/ResponsiveBody'

const ChangePasswordConfirmModal: FC<{ logout: () => void }> = ({ logout }) => {
  const closeRef = useRef<HTMLButtonElement | null>(null)
  const { t } = useTranslation()
  const {
    mainViewStore,
    changePasswordStore,
    infoStore: { isStrongAuth },
  } = useContext(StoreContext)
  const { result, resetResult } = changePasswordStore

  const closeModal = useCallback(() => {
    resetResult()

    if (result?.result === 'OK' && isStrongAuth) {
      return logout()
    }

    mainViewStore.reset()
  }, [isStrongAuth, logout, mainViewStore, resetResult, result?.result])

  return (
    <ModalStateWrapper open={result?.result === 'OK'}>
      <Modal>
        <Modal.CloseButton type="button" ref={closeRef} aria-label="Close" onClick={closeModal} icon={CloseIcon} />
        <Modal.Content style={{ padding: '2rem' }}>
          <ContentSection style={{ justifyContent: 'center', alignItems: 'center' }}>
            <SuccessAnimation width={'130px'} height={'130px'} />
            <ContentSection>
              <ResponsiveBody>
                <b>{t(`mainView.changePassword.success.${result?.result}`)}</b>
              </ResponsiveBody>
              {result?.result === 'OK' && isStrongAuth && (
                <ResponsiveBody>{t('mainView.changePassword.logout')}</ResponsiveBody>
              )}
            </ContentSection>
          </ContentSection>
        </Modal.Content>
      </Modal>
    </ModalStateWrapper>
  )
}

export default observer(ChangePasswordConfirmModal)
