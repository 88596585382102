import { Modal, ModalStateWrapper } from '@postidigital/posti-components'
import { observer } from 'mobx-react-lite'
import React, { PropsWithChildren } from 'react'

const capitalizeFirstLetter = (p: string) => p.charAt(0).toUpperCase() + p.slice(1).toLowerCase()

interface RegistrationModalProps {
  isOpen: boolean
  title: string
}

export const RegistrationModal: React.FC<PropsWithChildren<RegistrationModalProps>> = observer(
  ({ children, isOpen, title }) => {
    return (
      <ModalStateWrapper open={isOpen}>
        <Modal>
          <Modal.Content>
            <Modal.Header>
              <Modal.Title size="Five">{capitalizeFirstLetter(title)}</Modal.Title>
            </Modal.Header>
            {children}
          </Modal.Content>
        </Modal>
      </ModalStateWrapper>
    )
  }
)
