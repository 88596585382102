import { Expander } from '@postidigital/posti-components'
import { ExternalLinkIcon, Label, ServicesIcon } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { FC, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { StoreContext } from '../../store'
import { ResponsiveLink } from '../typography/ResponsiveLink'
import { SERVICE_LIST_CONFIG } from './ServiceList.config'

const ServiceListComponent: FC = () => {
  const { t } = useTranslation()
  const [servicesOpen, setServicesOpen] = useState<boolean>(false)
  const {
    layoutStore: { isAppWebView },
    infoStore: { isConsumer, isCorporate },
  } = useContext(StoreContext)

  const serviceLinks = useMemo(
    () =>
      Object.values(SERVICE_LIST_CONFIG).map(({ labelTranslationId, link, service }) => (
        <ResponsiveLink key={service} icon={ExternalLinkIcon} iconPosition="right" href={link} target="_blank">
          {t(labelTranslationId)}
        </ResponsiveLink>
      )),
    [t]
  )

  if (isAppWebView || (isCorporate && !isConsumer)) {
    return null
  }

  return (
    <Expander
      customBackgroundColorClosed="transparent"
      style={{ borderRadius: '40px', padding: '0.4rem 0 0 0', marginTop: '1rem' }}
      open={servicesOpen}
      onClick={() => setServicesOpen((isOpen) => !isOpen)}
    >
      <Expander.Title>
        <ServicesIcon style={{ margin: '0 4px 4px 12px' }} />
        <Label size="Three" color="#3b4a57">
          {t('serviceLinkLabel.services')}
        </Label>
      </Expander.Title>
      <Expander.Body
        style={{
          paddingLeft: '3rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          ...(servicesOpen ? { paddingBottom: '1rem' } : {}),
        }}
      >
        {serviceLinks}
      </Expander.Body>
    </Expander>
  )
}

export default observer(ServiceListComponent)
