import { Loading } from '@postidigital/posti-components'
import { LinkButtonProps } from '@postidigital/posti-components/build/basic-components/LinkButton/LinkButton'
import { observer } from 'mobx-react-lite'
import React, { FC, MouseEventHandler, useCallback, useRef, useState } from 'react'

import { ResponsiveLinkButton } from '../typography/ResponsiveLinkButton'

/**
 * A button that disables itself for a given amount of time after being clicked.
 * @param delay
 * @param onClick
 * @param children
 * @param props
 * @constructor
 */
export const DelayLinkButton: FC<
  Omit<LinkButtonProps, 'onClick'> & {
    delay: number
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>
    isLoading?: boolean
  }
> = observer(({ delay, onClick, isLoading, children, ...props }) => {
  const [linkDisabled, setLinkDisabled] = useState<boolean>(!!props.disabled)
  const timeout = useRef<NodeJS.Timeout | null>(null)

  const resetDisabledState = useCallback(() => {
    setLinkDisabled(!!props.disabled)

    if (timeout?.current) {
      clearTimeout(timeout.current)
    }
  }, [props.disabled])

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (event) => {
      setLinkDisabled(true)

      try {
        await onClick?.(event)
      } catch (e) {
        console.error(e)
      } finally {
        timeout.current = setTimeout(resetDisabledState, delay)
      }
    },
    [delay, onClick, resetDisabledState]
  )

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <ResponsiveLinkButton onClick={handleClick} {...props} disabled={linkDisabled}>
        {children || null}
      </ResponsiveLinkButton>
      {isLoading && (
        <span style={{ marginLeft: '1rem' }}>
          <Loading size="xs" />
        </span>
      )}
    </div>
  )
})
