import {
  Body,
  Button,
  Checkbox,
  EnvelopeIcon,
  ExternalLinkIcon,
  Input,
  LockIcon,
  Notification,
  Password,
  PhoneIcon,
  ProfileIcon,
} from '@postidigital/posti-components/build/brand'
import { AlertIndicatorIcon } from '@postidigital/posti-components/build/xyz'
import { XyzTheme } from '@postidigital/posti-theme'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'

import { ButtonContainer, ContentContainer, ContentSection, PostiPageFooter } from '../../../components'
import { FormErrorsTitleComponent } from '../../../components/forms/FormErrorsTitle'
import { ResponsiveBody } from '../../../components/typography/ResponsiveBody'
import { ResponsiveHeadline } from '../../../components/typography/ResponsiveHeadline'
import Localization, { Locale } from '../../../locales'
import { RegisterConsumerUserData } from '../../../service/registration_service/ServiceModels'
import { RegistrationStep, registrationStore } from '../../../store'
import { ExitRegistrationModal } from '../components/ExitRegistrationModal'
import { FormErrorLinks } from '../components/FormErrorLinks'
import {
  CheckboxContainer,
  CheckboxErrorContainer,
  defaultInputIconProps,
  HiddenNotificationContainer,
  StyledLinkWithExternalIcon,
} from '../registration.styles'
import { sendConsumerRegistrationErrorUserAction } from '../RegistrationMainView'
import { getFormItemMessage, useFormFields } from '../utils/useFormFields'
import { maxLength, noWhiteSpace, validateEmail, validatePassword, validatePhoneNumber } from '../utils/validators'
import { AlertNotification } from './AlertNotification'

export const EMAIL_IN_USE_CODE = 'email_not_available_to_use'

export enum REGISTRATION_FORM_ERROR {
  EMAIL_ALREADY_IN_USE_409 = 'email_already_in_use_409',
  VALIDATION_ERROR = 'validation_error',
  INTERNAL_ERROR = 'internal_error',
}

type FieldNames =
  | 'phoneNumber'
  | 'email'
  | 'password'
  | 'preferredName'
  | 'acceptTerms'
  | 'acceptMarketing'
  | 'acceptRegistryUpdate'

interface Props extends RouteComponentProps {
  // Existing, but incomplete user that was created long ago with UserID
  isOldUser: boolean
}

const CreateComponent: React.FC<Props> = observer(({ isOldUser }) => {
  const { t } = useTranslation()
  // TODO consider adding label, message etc to formFields object instead of input
  const [formFields, handleFieldChange] = useFormFields<FieldNames>({
    phoneNumber: {
      value:
        isOldUser && registrationStore.info?.consumerInfo.phoneNumberVerified
          ? registrationStore.info?.consumerInfo.phoneNumber
          : '',
      validator: validatePhoneNumber,
      isInvalid: false,
      touched: false,
      ref: useRef(),
      labelTranslationKey: 'registration.create.enterPhone',
      invalidMessageTranslationKey: 'registration.create.validation.enterPhone',
    },
    email: {
      value: isOldUser && registrationStore.info?.emailVerified ? registrationStore.info?.email : '',
      validator: validateEmail,
      isInvalid: false,
      touched: false,
      ref: useRef(),
      labelTranslationKey: 'registration.create.enterEmail',
      invalidMessageTranslationKey: 'registration.create.validation.enterEmail',
    },
    password: {
      value: '',
      validator: validatePassword,
      isInvalid: false,
      touched: false,
      ref: useRef(),
      labelTranslationKey: 'registration.create.enterPassword',
      invalidMessageTranslationKey: 'registration.create.validation.enterPassword',
    },
    preferredName: {
      value: '',
      validator: (value) => maxLength(value, 100) && noWhiteSpace(value),
      isInvalid: false,
      touched: false,
      ref: useRef(),
      labelTranslationKey: 'general.preferredName',
      invalidMessageTranslationKey: 'registration.create.validation.preferredName',
    },
    acceptTerms: {
      value: false,
      validator: (checked) => checked,
      isInvalid: false,
      type: 'checkbox',
      touched: false,
      ref: useRef(),
      invalidMessageTranslationKey: 'registration.create.validation.acceptTerms',
    },
    acceptMarketing: {
      value: false,
      validator: () => true,
      isInvalid: false,
      type: 'checkbox',
      touched: false,
    },
    acceptRegistryUpdate: {
      value: false,
      validator: () => true,
      isInvalid: false,
      type: 'checkbox',
      touched: false,
    },
  })
  const [formErrorCount, setFormErrorCount] = useState(0)
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false)
  const errorContainerRef = useRef<HTMLDivElement | null>(null)
  const alertNotificationRef = useRef<HTMLParagraphElement>(null)
  const [exitModalOpen, setExitModalOpen] = useState(false)
  const [error, setError] = useState<REGISTRATION_FORM_ERROR | null>(null)
  const lang: Locale = Localization.CurrentLocale as Locale

  useEffect(() => {
    const errorCount = Object.keys(formFields).filter(
      (fieldName) => formFields[fieldName as FieldNames].isInvalid && formFields[fieldName as FieldNames].touched
    ).length
    setFormErrorCount(errorCount)
  }, [formFields, formErrorCount])

  // Send error codes to analytics
  useEffect(() => {
    if (error) {
      sendConsumerRegistrationErrorUserAction(error)
    }
  }, [error])

  const isFormValid = () => !Object.keys(formFields).some((fieldName) => formFields[fieldName as FieldNames].isInvalid)

  const onSubmit = async () => {
    if (registrationStore.processingRequest) {
      return
    }

    setSubmitButtonClicked(true)
    handleFieldChange('validateAll')
    setError(null)

    if (isFormValid()) {
      const data: RegisterConsumerUserData = {
        phoneNumber: formFields.phoneNumber.value,
        email: formFields.email.value,
        password: formFields.password.value,
        preferredName: formFields.preferredName.value,
        acceptTerms: formFields.acceptTerms.value,
        acceptRegistryUpdate: formFields.acceptRegistryUpdate.value,
        acceptMarketing: formFields.acceptMarketing.value,
        language: lang,
      }

      registrationStore.createAccount(data, isOldUser).catch((response) => {
        if (response.status === 409) {
          if (response.message === EMAIL_IN_USE_CODE) {
            setError(REGISTRATION_FORM_ERROR.EMAIL_ALREADY_IN_USE_409)
          } else {
            registrationStore.setStep(RegistrationStep.PRE_CHECK_FAIL)
          }
        } else if (response.status === 400) {
          setError(REGISTRATION_FORM_ERROR.VALIDATION_ERROR)
        } else {
          setError(REGISTRATION_FORM_ERROR.INTERNAL_ERROR)
        }

        setImmediate(() => {
          alertNotificationRef?.current?.focus()
        })
      })
    } else {
      setImmediate(() => {
        errorContainerRef?.current?.focus()
      })
    }
  }

  return (
    <>
      <ContentContainer>
        <ResponsiveHeadline as="h1">
          {isOldUser ? t('registration.create.titleOldUsers') : t('registration.create.title')}
        </ResponsiveHeadline>
        <ContentSection gap="sm">
          <ResponsiveBody>
            <b>{t('registration.create.topic')}</b>
          </ResponsiveBody>
          <ResponsiveBody>{t('registration.create.topicExplanation')}</ResponsiveBody>
        </ContentSection>
        <form onSubmit={(e) => e.preventDefault()} noValidate>
          <ContentSection>
            {isOldUser && (
              <Notification id="notification_container" contentType="informational" dismissible={false}>
                {t('registration.create.oldUserNotification')}
              </Notification>
            )}
            <HiddenNotificationContainer
              tabIndex={-1}
              visible={submitButtonClicked && formErrorCount > 0}
              ref={errorContainerRef}
            >
              <Notification id="notification_container" contentType="alert" dismissible={false}>
                <>
                  <FormErrorsTitleComponent errorCount={formErrorCount} />
                  <FormErrorLinks formFields={formFields} />
                </>
              </Notification>
            </HiddenNotificationContainer>
            {error && <AlertNotification error={error as REGISTRATION_FORM_ERROR} ref={alertNotificationRef} />}
            <input type="hidden" value="something" />
            <Input
              name="phoneNumber"
              type="tel"
              onChange={handleFieldChange}
              onBlur={handleFieldChange}
              id={'phoneNumber'}
              ref={formFields.phoneNumber.ref}
              value={formFields.phoneNumber.value}
              isInvalid={formFields.phoneNumber.touched && formFields.phoneNumber.isInvalid}
              message={getFormItemMessage(formFields.phoneNumber, t)}
              label={t(formFields.phoneNumber.labelTranslationKey as string)}
              helpText={t('registration.create.phoneInstructions')}
              lightBackground
              leftTemplate={<PhoneIcon {...defaultInputIconProps} />}
              disabled={!!registrationStore.info?.consumerInfo?.phoneNumberVerified}
            />
            <Input
              autoComplete="off"
              name="email"
              type="email"
              onChange={handleFieldChange}
              onBlur={handleFieldChange}
              id={'email'}
              ref={formFields.email.ref}
              value={formFields.email.value}
              isInvalid={
                (formFields.email.touched && formFields.email.isInvalid) ||
                error === REGISTRATION_FORM_ERROR.EMAIL_ALREADY_IN_USE_409
              }
              message={getFormItemMessage(formFields.email, t)}
              label={t(formFields.email.labelTranslationKey as string)}
              helpText={t('registration.create.emailInstructions')}
              lightBackground
              leftTemplate={<EnvelopeIcon {...defaultInputIconProps} />}
              disabled={!!registrationStore.info?.emailVerified}
            />
            <Password
              autoComplete="new-password"
              name="password"
              onChange={handleFieldChange}
              onBlur={handleFieldChange}
              id={'password'}
              ref={formFields.password.ref}
              value={formFields.password.value}
              isInvalid={formFields.password.touched && formFields.password.isInvalid}
              message={getFormItemMessage(formFields.password, t)}
              label={t(formFields.password.labelTranslationKey as string)}
              helpText={t('registration.create.passwordInstructions')}
              lightBackground={true}
              leftTemplate={<LockIcon {...defaultInputIconProps} />}
            />
            <Input
              name="preferredName"
              type="text"
              onChange={handleFieldChange}
              onBlur={handleFieldChange}
              id={'preferredName'}
              ref={formFields.preferredName.ref}
              value={formFields.preferredName.value}
              isInvalid={formFields.preferredName.touched && formFields.preferredName.isInvalid}
              message={getFormItemMessage(formFields.preferredName, t)}
              label={`${t(formFields.preferredName.labelTranslationKey as string)} (${t(
                'registration.create.optional'
              ).toLowerCase()})`}
              helpText={t('registration.create.preferredNameInstructions')}
              lightBackground
              leftTemplate={<ProfileIcon {...defaultInputIconProps} />}
            />
          </ContentSection>
          <ContentSection>
            <ResponsiveBody>
              <b>{t('registration.create.termsOfUseHeadline')}</b>
            </ResponsiveBody>
            <CheckboxContainer>
              <Checkbox
                name="acceptTerms"
                onChange={handleFieldChange}
                onBlur={handleFieldChange}
                checked={formFields.acceptTerms.value}
                id={'acceptTerms'}
                ref={formFields.acceptTerms.ref}
                value={'acceptTerms'}
                isInvalid={formFields.acceptTerms.touched && formFields.acceptTerms.isInvalid}
                label={
                  <span>
                    {t('registration.create.acceptTerms.iAcceptThe')}
                    <StyledLinkWithExternalIcon
                      href={t('registration.create.links.termsOfUse')}
                      icon={ExternalLinkIcon}
                      target="_blank"
                    >
                      {t('registration.create.acceptTerms.termsOfUseLinkText')}
                    </StyledLinkWithExternalIcon>
                    {t('registration.create.acceptTerms.andHaveReadThe')}
                    <StyledLinkWithExternalIcon
                      href={t('registration.create.links.privacyStatement')}
                      icon={ExternalLinkIcon}
                      target="_blank"
                    >
                      {t('registration.create.acceptTerms.privacyStatementLinkText')}.
                    </StyledLinkWithExternalIcon>
                  </span>
                }
                labelPosition={'right'}
              />
              {formFields.acceptTerms.touched && formFields.acceptTerms.isInvalid && (
                <CheckboxErrorContainer>
                  <AlertIndicatorIcon aria-hidden={true} />
                  <Body size={'Five'} color={XyzTheme.color.signalRed}>
                    {t(formFields.acceptTerms.invalidMessageTranslationKey as string)}
                  </Body>
                </CheckboxErrorContainer>
              )}
            </CheckboxContainer>
            <CheckboxContainer>
              <Checkbox
                name="acceptRegistryUpdate"
                onChange={handleFieldChange}
                checked={formFields.acceptRegistryUpdate.value}
                id={'acceptRegistryUpdate'}
                value={'acceptRegistryUpdate'}
                label={`${t('registration.create.acceptRegistryUpdate.label')} * (${t(
                  'registration.create.optional'
                )})`}
                labelPosition={'right'}
              />
            </CheckboxContainer>
            <CheckboxContainer>
              <Checkbox
                name="acceptMarketing"
                onChange={handleFieldChange}
                checked={formFields.acceptMarketing.value}
                id={'acceptMarketing'}
                value={'acceptMarketing'}
                label={`${t('registration.create.acceptMarketing')} (${t('registration.create.optional')})`}
                labelPosition={'right'}
              />
            </CheckboxContainer>
            <Body size={'Five'}>
              {`* ${t('registration.create.acceptRegistryUpdate.additionalInfo')}`}
              <StyledLinkWithExternalIcon
                href={t('registration.create.links.disclosureBan')}
                icon={ExternalLinkIcon}
                target="_blank"
              >
                {t('registration.create.acceptRegistryUpdate.additionalInfoLinkText')}
              </StyledLinkWithExternalIcon>
            </Body>
            <Body size={'Five'}>{t('registration.create.youMayTerminate')}</Body>
          </ContentSection>
        </form>
        <ButtonContainer>
          <Button id="button_cancel" contentMode="secondary" onClick={() => setExitModalOpen(true)}>
            {t('registration.buttons.exit')}
          </Button>
          <Button
            hasBackground
            id="button_create_account"
            contentMode="primary"
            onClick={onSubmit}
            isLoading={registrationStore.processingRequest}
          >
            {t(!isOldUser ? 'registration.buttons.createAccount' : 'registration.buttons.updateAccount')}
          </Button>
        </ButtonContainer>
        <PostiPageFooter showCookiePreferences />
      </ContentContainer>
      <ExitRegistrationModal
        isOpen={exitModalOpen}
        title={t('registration.exitModal.title')}
        onCancel={() => setExitModalOpen(false)}
      >
        <ResponsiveBody>{t('registration.exitModal.text')}</ResponsiveBody>
      </ExitRegistrationModal>
    </>
  )
})

export const RegistrationForm = withRouter(CreateComponent)
