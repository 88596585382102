import { Loading } from '@postidigital/posti-components'
import {
  Body,
  BodySize,
  Button,
  Input,
  LockIcon,
  Notification,
  Password,
  PhoneIcon,
  ProfileIcon,
} from '@postidigital/posti-components/build/brand'
import { sendUserAction } from '@postidigital/posti-google-analytics'
import { BrandTheme } from '@postidigital/posti-theme'
import { observer } from 'mobx-react-lite'
import React, { MouseEventHandler, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { phoneNumberValidator } from '../../service/phoneNumberValidator'
import AcceptStore, { IAcceptState } from '../../store/views/acceptStore'
import { breakpoint2 } from '../../utils'
import { validateName } from '../../utils/validation'
import { FormErrorLinks } from '../../views/registration/components/FormErrorLinks'
import { getFormItemMessage, useFormFields } from '../../views/registration/utils/useFormFields'
import { validatePassword, validatePhoneNumber } from '../../views/registration/utils/validators'
import { ButtonContainer, ContentSection } from '..'
import { FormErrorsTitleComponent } from '../forms/FormErrorsTitle'
import { StaticInputLayout } from '../StaticInputLayout'
import { ResponsiveBody } from '../typography/ResponsiveBody'

type FieldNames = 'email' | 'firstName' | 'lastName' | 'password' | 'phoneNumber'

export const RegisterAccountFormComponent: React.FC = () => {
  const { t } = useTranslation()
  const store = useContext(AcceptStore)

  const [formFields, handleFieldChange] = useFormFields<FieldNames>({
    email: {
      ref: useRef(),
      touched: true,
      isInvalid: false,
      validator: () => true,
      value: store.invite.email || '',
      labelTranslationKey: 'general.email',
      invalidMessageTranslationKey: 'registration.create.validation.enterEmail',
    },
    password: {
      value: '',
      ref: useRef(),
      touched: false,
      isInvalid: false,
      validator: validatePassword,
      labelTranslationKey: 'registration.create.enterPassword',
      invalidMessageTranslationKey: 'registration.create.validation.enterPassword',
    },
    firstName: {
      value: '',
      ref: useRef(),
      touched: false,
      isInvalid: false,
      isEmpty: true,
      validator: validateName,
      labelTranslationKey: 'invite.register.firstName',
      invalidMessageTranslationKey: 'invite.register.firstNameNotValid',
      emptyMessageTranslationKey: 'invite.register.firstNameEmpty',
    },
    lastName: {
      value: '',
      ref: useRef(),
      touched: false,
      isInvalid: false,
      isEmpty: true,
      validator: validateName,
      labelTranslationKey: 'invite.register.lastName',
      invalidMessageTranslationKey: 'invite.register.lastNameNotValid',
      emptyMessageTranslationKey: 'invite.register.lastNameEmpty',
    },
    phoneNumber: {
      value: '',
      ref: useRef(),
      touched: false,
      isInvalid: false,
      validator: validatePhoneNumber,
      labelTranslationKey: 'invite.register.enterPhoneNumber',
      invalidMessageTranslationKey: 'registration.create.validation.enterPhone',
      optional: true,
    },
  })

  const [registering, setRegistering] = useState(false)
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false)

  const errorRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    errorRef?.current?.focus()
  }, [errorRef])

  useEffect(() => {
    sendUserAction({
      actionType: 'onboarding_create_account_information',
    })
  }, [])

  const isValid = useCallback(() => Object.values(formFields).filter((field) => field.isInvalid).length === 0, [
    formFields,
  ])

  const invalidFieldKeys = useMemo(
    () => (Object.keys(formFields) as (keyof typeof formFields)[]).filter((key) => formFields[key].isInvalid),
    [formFields]
  )

  const handleBackClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault()
      store.setState(IAcceptState.Accepting)
    },
    [store]
  )

  const handleCreateAccountClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      setSubmitButtonClicked(true)
      handleFieldChange('validateAll')
      const { firstName, lastName, password, phoneNumber } = formFields

      if (!isValid()) {
        errorRef?.current?.focus()
        return false
      }

      e.preventDefault()
      setRegistering(true)
      // store.firstName is used in the following view template, so it needs to be updated to store
      store.setFirstName(firstName.value)

      return store.register(
        firstName.value,
        lastName.value,
        password.value,
        phoneNumberValidator.normalize(phoneNumber.value, false)
      )
    },
    [formFields, isValid, store, handleFieldChange]
  )

  return (
    <>
      <ContentSection>
        <ResponsiveBody>{t('invite.register.preface', { orgName: store.invite.organizationName })}</ResponsiveBody>
        <ResponsiveBody>{t('invite.register.orgInfo', { orgName: store.invite.organizationName })}</ResponsiveBody>
        <ResponsiveBody>{t('invite.register.usernameInfo')}</ResponsiveBody>
        <StaticInputLayout Icon={ProfileIcon} label={t('general.email')} content={store.invite.email || '-'} />
      </ContentSection>
      <ContentSection>
        <StyledTitle as="div">{t('invite.register.formTitle')}</StyledTitle>
        <ErrorDetailsSection ref={errorRef}>
          {invalidFieldKeys.length > 0 && submitButtonClicked && (
            <Notification id="notification_container" contentType="alert" dismissible={false}>
              <>
                <FormErrorsTitleComponent errorCount={invalidFieldKeys.length} />
                <FormErrorLinks formFields={formFields} />
              </>
            </Notification>
          )}
        </ErrorDetailsSection>
        <input type="hidden" value="something" />
        <Password
          id="password"
          autoComplete="new-password"
          name="password"
          lightBackground
          onBlur={handleFieldChange}
          leftTemplate={<LockIcon />}
          onChange={handleFieldChange}
          ref={formFields.password.ref}
          value={formFields.password.value}
          isInvalid={formFields.password.isInvalid}
          helpText={t('invite.register.passwordHelptext')}
          hideIconAriaLabel={t('invite.register.hidePassword')}
          showIconAriaLabel={t('invite.register.showPassword')}
          label={t(formFields.password?.labelTranslationKey || '')}
          message={getFormItemMessage(formFields.password, t)}
        />
        <InputRow>
          <SideBySideInput
            id="firstName"
            name="firstName"
            lightBackground
            onBlur={handleFieldChange}
            onChange={handleFieldChange}
            ref={formFields.firstName.ref}
            value={formFields.firstName.value}
            isInvalid={formFields.firstName.isInvalid}
            label={t(formFields.firstName?.labelTranslationKey || '')}
            message={getFormItemMessage(formFields.firstName, t)}
          />
          <SideBySideInput
            id="lastName"
            name="lastName"
            lightBackground
            onBlur={handleFieldChange}
            onChange={handleFieldChange}
            ref={formFields.lastName.ref}
            value={formFields.lastName.value}
            isInvalid={formFields.lastName.isInvalid}
            label={t(formFields.lastName?.labelTranslationKey || '')}
            message={getFormItemMessage(formFields.lastName, t)}
          />
        </InputRow>
        <Input
          id="phoneNumber"
          name="phoneNumber"
          lightBackground
          onBlur={handleFieldChange}
          onChange={handleFieldChange}
          leftTemplate={<PhoneIcon />}
          ref={formFields.phoneNumber.ref}
          value={formFields.phoneNumber.value}
          isInvalid={formFields.phoneNumber.isInvalid}
          label={t(formFields.phoneNumber?.labelTranslationKey || '')}
          message={getFormItemMessage(formFields.phoneNumber, t)}
        />
        <StyledHelpText>{t('invite.register.phoneNumberHelp')}</StyledHelpText>
      </ContentSection>
      <ButtonContainer>
        <Button id="accept_register_back_button" contentMode="secondary" onClick={handleBackClick}>
          {t('invite.register.backButton')}
        </Button>
        <Button hasBackground id="accept_register_button" onClick={handleCreateAccountClick} disabled={registering}>
          {t('invite.register.createAccountButton')}
          {registering && <Loading size="xs" color={BrandTheme.color.white} style={{ marginLeft: '1rem' }} />}
        </Button>
      </ButtonContainer>
    </>
  )
}

export const RegisterAccountForm = observer(RegisterAccountFormComponent)

const StyledTitle = styled(Body).attrs({ size: BodySize.Two })`
  font-weight: 600;
`

const InputRow = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;

  @media ${breakpoint2.tabletAndUp} {
    flex-direction: row;
  }
`

const SideBySideInput = styled(Input)`
  flex-grow: 1;
`

const StyledHelpText = styled(Body).attrs({ size: BodySize.Five })`
  margin-left: 24px;
  margin-right: 24px;
`

const ErrorDetailsSection = styled('div').attrs({ tabIndex: -1 })``
