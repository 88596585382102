import { sendUserAction } from '@postidigital/posti-google-analytics'
import { observer } from 'mobx-react-lite'
import React, { FormEventHandler, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'

import { ContentContainer, ContentSection, PostiPageFooter } from '../../../components'
import { PostiPageLoading } from '../../../components/layout/postiPageLoading/PostiPageLoading'
import { PhoneVerificationForm } from '../../../components/PhoneVerificationForm'
import { ResponsiveBody } from '../../../components/typography/ResponsiveBody'
import { ResponsiveHeadline } from '../../../components/typography/ResponsiveHeadline'
import Localization, { Locale } from '../../../locales'
import { registrationStore, StoreContext } from '../../../store'
import { ExitRegistrationModal } from '../components/ExitRegistrationModal'
import { GeneralErrorNotificationWithRetryButton } from '../components/GeneralErrorNotificationWithRetryButton'
import { RegistrationModal } from '../components/RegistrationModal'
import { PhoneSvg } from '../registration.styles'
import { sendConsumerRegistrationErrorUserAction } from '../RegistrationMainView'
import { maxSixDigits } from '../utils/customTypeRestrictions'
import { useFormFields } from '../utils/useFormFields'
import { length, onlyDigits } from '../utils/validators'
import { VERIFY_PHONE_ERROR } from './AlertNotification'
import { EditPhoneComponent } from './EditPhone'

type VerifyPhoneFieldNames = 'code'

const VerifyPhoneComponent: React.FC = observer(() => {
  const { t } = useTranslation()
  const {
    registrationStore: { info, setPhone, infoRequestFailed },
  } = useContext(StoreContext)

  const [initialising, setInitialising] = useState(true)
  const [sendingCode, setSendingCode] = useState(false)
  const [error, setError] = useState<VERIFY_PHONE_ERROR | null>(null)
  const [newCodeSent, setNewCodeSent] = useState(false)
  const [requestingNewCode, setRequestingNewCode] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const clearAllNotifications = useCallback(() => {
    setError(null)
    setNewCodeSent(false)
    registrationStore.setInfoReFetched(false)
  }, [])

  const [formFields, handleFieldChange] = useFormFields<VerifyPhoneFieldNames>({
    code: {
      value: '',
      validator: (value) => length(value, 6) && onlyDigits(value),
      isInvalid: false,
      touched: false,
      ref: useRef(),
      labelTranslationKey: 'registration.verifyPhone.inputLabel',
      invalidMessageTranslationKey: 'registration.verifyPhone.validation.codeError',
      // Let's allow user to only input digits [0-9]. Need to use type="text"
      // together with inputMode="numeric" and custom restriction to make it happen.
      customTypeRestriction: (value) => maxSixDigits(value),
    },
  })
  const [exitModalOpen, setExitModalOpen] = useState(false)
  const lang: Locale = Localization.CurrentLocale as Locale

  // This handles the failure of subsequent info-request after
  // updateRegistrationEmail() when user changes their email
  useEffect(() => {
    if (infoRequestFailed) {
      clearAllNotifications()
      setError(VERIFY_PHONE_ERROR.INTERNAL_ERROR)
    }
  }, [clearAllNotifications, infoRequestFailed])

  useEffect(() => {
    if (!info?.phoneVerification) {
      // Initialise phone verification in server-side
      setInitialising(true)
      registrationStore
        .changePhoneNumber({
          phone: info?.consumerInfo.phoneNumber as string,
          language: lang,
        })
        .then(() => setNewCodeSent(true))
        .catch(() => {
          setError(VERIFY_PHONE_ERROR.INIT_FAIL)
        })
        .finally(() => {
          setInitialising(false)
        })
    } else {
      let newPhone: string = ''

      if (!info?.phoneVerification?.phoneNumber) {
        setError(VERIFY_PHONE_ERROR.INTERNAL_ERROR)
      } else {
        setNewCodeSent(true)
        newPhone = info?.phoneVerification?.phoneNumber
      }

      setPhone(newPhone)
      setInitialising(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Send error codes to analytics
  useEffect(() => {
    if (error) {
      sendConsumerRegistrationErrorUserAction(error)
    }
  }, [error])

  const isFormValid = useMemo(
    () => !Object.keys(formFields).some((fieldName) => formFields[fieldName as VerifyPhoneFieldNames].isInvalid),
    [formFields]
  )

  const changePhoneHandler = useCallback(() => {
    clearAllNotifications()
    setModalOpen(true)
    sendUserAction({
      actionType: 'changeTelNumber',
      stage: 'open',
    })
  }, [clearAllNotifications])

  const cancelHandler = useCallback(() => setExitModalOpen(true), [])

  const onContinue: FormEventHandler<HTMLFormElement> = useCallback(
    async (event) => {
      event.preventDefault()
      event.nativeEvent.stopImmediatePropagation()

      if (sendingCode || formFields.code.value.length !== 6) {
        return null
      }

      handleFieldChange('validateAll')
      clearAllNotifications()

      if (isFormValid) {
        setSendingCode(true)
        registrationStore
          .sendVerifyPhoneCode({
            phone: info!.consumerInfo.phoneNumber,
            code: formFields.code.value,
          })
          .catch((message) => {
            setError(message)
          })
          .finally(() => {
            setSendingCode(false)
          })
      } else {
        setImmediate(() => {
          formFields.code.ref?.current.focus()
        })
      }
    },
    [
      clearAllNotifications,
      formFields.code.ref,
      formFields.code.value,
      handleFieldChange,
      info,
      isFormValid,
      sendingCode,
    ]
  )

  const onSendNewCode = useCallback(async () => {
    clearAllNotifications()
    setRequestingNewCode(true)

    try {
      await registrationStore.changePhoneNumber({
        phone: info!.consumerInfo.phoneNumber,
        language: lang,
      })
      setNewCodeSent(true)
      // clear input field
      const customEvent = new CustomEvent('updateField', { detail: { fieldName: 'code', value: '' } })
      handleFieldChange(customEvent)
    } catch (message) {
      setError(VERIFY_PHONE_ERROR.SEND_NEW_CODE_ERROR)
    } finally {
      setRequestingNewCode(false)
    }
  }, [clearAllNotifications, handleFieldChange, info, lang])

  const handleTimerExpired = useCallback(() => {
    registrationStore.setPhoneVerificationExpiration(true)
  }, [])

  // When phone verification init fails, we only show the header and alert notification
  if (error === VERIFY_PHONE_ERROR.INIT_FAIL) {
    return (
      <ContentContainer>
        <ResponsiveHeadline as="h1">{t('registration.verifyPhone.title')}</ResponsiveHeadline>
        <ContentSection>
          <GeneralErrorNotificationWithRetryButton
            additionalMsg={t('registration.verifyPhone.errorMessages.tooManyTimesTryAgain')}
          />
        </ContentSection>
      </ContentContainer>
    )
  }

  if (initialising) {
    return <PostiPageLoading id="registrationLoading" />
  }

  return (
    <ContentContainer>
      <ResponsiveHeadline as="h1">{t('registration.verifyPhone.title')}</ResponsiveHeadline>
      <PhoneSvg />
      <PhoneVerificationForm
        info={info}
        error={error}
        onContinue={onContinue}
        formFields={formFields}
        newCodeSent={newCodeSent}
        sendingCode={sendingCode}
        cancelHandler={cancelHandler}
        onSendNewCode={onSendNewCode}
        requestingNewCode={requestingNewCode}
        handleFieldChange={handleFieldChange}
        changePhoneHandler={changePhoneHandler}
        handleTimerExpired={handleTimerExpired}
      />

      <PostiPageFooter showCookiePreferences />
      <RegistrationModal isOpen={modalOpen} title={t('registration.verifyPhone.reEnterPhone')}>
        <EditPhoneComponent onCancel={() => setModalOpen(false)} />
      </RegistrationModal>
      <ExitRegistrationModal
        isOpen={exitModalOpen}
        title={t('registration.exitModal.title')}
        onCancel={() => setExitModalOpen(false)}
      >
        <ResponsiveBody>{t('registration.exitModal.text')}</ResponsiveBody>
      </ExitRegistrationModal>
    </ContentContainer>
  )
})

export const VerifyPhone = withRouter(VerifyPhoneComponent)
