import { Expander } from '@postidigital/posti-components'
import { Notification } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import {
  ContentSection,
  MarketingDetailsExpanderContent,
  PreferredLanguageDropDown,
  SideImageContainerContent,
} from '../../components'
import { ResponsiveHeadline } from '../../components/typography/ResponsiveHeadline'
import { StoreContext } from '../../store'

const SettingsViewComponent: React.FC = () => {
  const { t } = useTranslation()
  const { userStore } = useContext(StoreContext)

  return (
    <SideImageContainerContent>
      <ResponsiveHeadline as="h1">{t(`sideMenu.mySettings`)}</ResponsiveHeadline>
      <ContentSection>
        {userStore.error && (
          <StyledNotification
            contentType={userStore.error === 'marketingSuccess' ? 'success' : 'alert'}
            dismissible={false}
          >
            {getErrorText(userStore.error, t) || ''}
          </StyledNotification>
        )}
        <PreferredLanguageDropDown />
      </ContentSection>
      <MarketingDetailsExpanderContent />
    </SideImageContainerContent>
  )
}

export const SettingsView = observer(SettingsViewComponent)

const getErrorText = (error: string, t: (string: string) => any): string | undefined => {
  if (error === 'failedToUpdateLanguage') {
    return t(`detailsView.langError`)
  } else if (error === 'marketingChanges') {
    return t(`detailsView.marketingError`)
  } else if (error === 'marketingSuccess') {
    return t(`detailsView.marketingSuccess`)
  } else {
    return undefined
  }
}

const StyledNotification = styled(Notification)`
  margin-top: 24px;
`

export const BrandExpander = styled(Expander)(
  ({
    theme: {
      brand: { color, spacing },
    },
  }) => css`
    box-shadow: 0 0 0 1px ${color.gray10};
    padding: ${spacing.space4}rem;

    & ${Expander.Body} {
      padding: 0;
    }
  `
)
