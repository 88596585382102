import { Button, Input, Notification, PhoneIcon } from '@postidigital/posti-components/build/brand'
import { BrandTheme } from '@postidigital/posti-theme'
import { observer } from 'mobx-react-lite'
import React, { ChangeEventHandler, FC, FormEventHandler, MouseEventHandler, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { IUserInfo } from '../../store/dataModels/interfaces'
import { defaultInputIconProps } from '../../views/registration/registration.styles'
import { FormItem } from '../../views/registration/utils/useFormFields'
import { AlertNotification, VERIFY_PHONE_ERROR } from '../../views/registration/verifyPhone/AlertNotification'
import CountdownTimer from '../../views/registration/verifyPhone/CountdownTimer'
import { DelayLinkButton } from '../buttons/DelayLinkButton'
import { ButtonContainer, ContentSection } from '../layout'
import { ResponsiveBody } from '../typography/ResponsiveBody'
import { ResponsiveLinkButton } from '../typography/ResponsiveLinkButton'

interface PhoneVerificationForm {
  info?: IUserInfo
  newCodeSent: boolean
  sendingCode: boolean
  requestingNewCode: boolean
  handleTimerExpired: () => void
  error: VERIFY_PHONE_ERROR | null
  formFields: Record<'code', FormItem>
  onContinue: FormEventHandler<HTMLFormElement>
  onSendNewCode: MouseEventHandler<HTMLButtonElement>
  cancelHandler: MouseEventHandler<HTMLButtonElement>
  handleFieldChange: ChangeEventHandler<HTMLInputElement>
  changePhoneHandler: MouseEventHandler<HTMLButtonElement>
}

const InstructionsWithCurrentPhoneNumber: React.FC<{ phoneNumber: string }> = ({ phoneNumber }) => {
  const { t } = useTranslation()

  if (!phoneNumber) {
    return null
  }

  return (
    <ContentSection top={false} gap="sm">
      <ResponsiveBody>
        {t('registration.verifyPhone.codeSentTo')} <b>{phoneNumber}</b>.
      </ResponsiveBody>
    </ContentSection>
  )
}

const PhoneVerificationFormComponent: FC<PhoneVerificationForm> = ({
  info,
  error,
  onContinue,
  newCodeSent,
  sendingCode,
  onSendNewCode,
  cancelHandler,
  requestingNewCode,
  changePhoneHandler,
  formFields,
  handleFieldChange,
  handleTimerExpired,
}) => {
  const { t } = useTranslation()

  const isPhoneVerificationExpired = useMemo(() => {
    return info?.phoneVerification?.isExpired
  }, [info?.phoneVerification?.isExpired])

  const renderTimer = !isPhoneVerificationExpired && error !== VERIFY_PHONE_ERROR.SEND_NEW_CODE_ERROR
  const { phoneNumber = '' } = info?.phoneVerification || info?.consumerInfo || {}

  const getCodeInputMessage = useCallback(
    (formField: FormItem): string => {
      if (formField.touched && formField.isInvalid) {
        return t(formFields.code.invalidMessageTranslationKey as string)
      } else if (formField.value && !formField.isInvalid) {
        return t('registration.verifyPhone.validation.codeSuccess')
      } else {
        return ''
      }
    },
    [formFields.code.invalidMessageTranslationKey, t]
  )

  return (
    <form id="registration_verify_phone_number_form" onSubmit={onContinue}>
      <ContentSection top={false}>
        <div>
          <InstructionsWithCurrentPhoneNumber phoneNumber={phoneNumber} />
          {renderTimer && <CountdownTimer info={info} onTimerExpired={handleTimerExpired} />}
          {!isPhoneVerificationExpired && <ResponsiveBody>{t('registration.verifyPhone.enterCode')}</ResponsiveBody>}
        </div>
        {error && <AlertNotification error={error} sendNewCode={onSendNewCode} />}
        {isPhoneVerificationExpired && (
          <Notification id="notification_container" contentType="alert" dismissible={false}>
            {t('registration.verifyPhone.expired')}
          </Notification>
        )}
        {newCodeSent && !isPhoneVerificationExpired && (
          <Notification id="notification_container" contentType="success" dismissible={false}>
            {t('registration.verifyPhone.newCodeSent')}
          </Notification>
        )}
      </ContentSection>
      <ContentSection gap="xl">
        <Input
          id="code"
          name="code"
          type="text"
          lightBackground
          inputMode="numeric"
          ref={formFields.code.ref}
          onBlur={handleFieldChange}
          onChange={handleFieldChange}
          value={formFields.code.value}
          disabled={isPhoneVerificationExpired}
          message={getCodeInputMessage(formFields.code)}
          leftTemplate={<PhoneIcon {...defaultInputIconProps} />}
          label={`${t(formFields.code.labelTranslationKey as string)}`}
          isInvalid={formFields.code.touched && formFields.code.isInvalid}
        />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <ResponsiveBody color={BrandTheme.color.black}>{t('registration.verifyPhone.didNotReceive')}</ResponsiveBody>
          <DelayLinkButton
            delay={5000}
            type="button"
            id="button_send_new_code"
            onClick={onSendNewCode}
            isLoading={requestingNewCode}
          >
            {t('registration.verifyPhone.sendNew')}
          </DelayLinkButton>
          <ResponsiveLinkButton type="button" id="button_edit_phone" onClick={changePhoneHandler}>
            {t('registration.verifyPhone.reEnterPhone')}
          </ResponsiveLinkButton>
        </div>
      </ContentSection>
      <ButtonContainer>
        <Button type="button" id="button_cancel" contentMode="secondary" onClick={cancelHandler}>
          {t('registration.buttons.exit')}
        </Button>
        <Button
          hasBackground
          type="submit"
          id="button_continue"
          contentMode="primary"
          isLoading={sendingCode}
          disabled={formFields.code.value.length !== 6}
        >
          {t('general.continue')}
        </Button>
      </ButtonContainer>
    </form>
  )
}

export const PhoneVerificationForm = observer(PhoneVerificationFormComponent)
