import { Body, Button, Headline } from '@postidigital/posti-components/build/brand'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import styled, { css } from 'styled-components'

import ErrorLarge from '../../static/img/error_large'

interface IProps {
  error: string
}

type Props = RouteComponentProps & IProps

export const ErrorComponentComponent: React.FC<Props> = ({ error, history }) => {
  const { t } = useTranslation()

  // TODO: this logic should be mostly removed from this component. It can have a default error, but otherwise texts should be passed as prop.
  //  Now it has a weird dependency with account termination: error = 'accountTermination.generic' will show specific message to that view.
  let errorTitle = t(`errors.${error}.title`, { defaultValue: '' })
  const shouldShowReloadButton = !errorTitle || error === 'general'
  if (!errorTitle) {
    errorTitle = t('errors.general.title')
  }
  let errorMessage = t(`errors.${error}.message`, { defaultValue: '' })

  const onClick = () => {
    if (shouldShowReloadButton) {
      window.location.reload()
    } else {
      history.push(`/`)
    }
  }

  return (
    <StyledErrorContainer>
      <ErrorLarge />
      <Headline size={'Four'} as={'h1'} style={{ marginTop: '1.5rem', textAlign: 'center' }}>
        {errorTitle}
      </Headline>
      {errorMessage && (
        <Body size={'Three'} style={{ marginTop: '0.5rem', textAlign: 'center' }}>
          {errorMessage}
        </Body>
      )}
      <Button style={{ marginTop: '2rem' }} onClick={onClick}>
        {shouldShowReloadButton && t(`errors.generalErrorWithRetry.tryAgain`)}
        {!shouldShowReloadButton && t(`errors.refresh`)}
      </Button>
    </StyledErrorContainer>
  )
}

export const ErrorComponent = withRouter(ErrorComponentComponent)

const StyledErrorContainer = styled.div(
  () => css`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 3rem;
    padding: 0 1rem;
  `
)
