import { Button, Input, Notification, PhoneIcon } from '@postidigital/posti-components/build/brand'
import React, { FC, FormEventHandler, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonContainer, ContentContainer, ContentSection, PostiPageFooter } from '../../components'
import { ResponsiveHeadline } from '../../components/typography/ResponsiveHeadline'
import Localization, { Locale } from '../../locales'
import { StoreContext } from '../../store'
import { defaultInputIconProps } from './registration.styles'
import { sendConsumerRegistrationErrorUserAction } from './RegistrationMainView'
import { getFormItemMessage, useFormFields } from './utils/useFormFields'
import { validatePhoneNumber } from './utils/validators'

type EditPhoneFieldNames = 'phoneNumber'

export const RegistrationMissingPhone: FC = () => {
  const { registrationStore } = useContext(StoreContext)
  const { t } = useTranslation()
  const [formFields, handleFieldChange] = useFormFields<EditPhoneFieldNames>({
    phoneNumber: {
      value: '',
      ref: useRef(),
      touched: false,
      isInvalid: false,
      validator: validatePhoneNumber,
      labelTranslationKey: 'registration.create.enterPhone',
      invalidMessageTranslationKey: 'registration.create.validation.enterPhone',
    },
  })
  const [error, setError] = useState(false)
  const lang: Locale = Localization.CurrentLocale as Locale

  // Send error codes to analytics
  useEffect(() => {
    if (error) {
      sendConsumerRegistrationErrorUserAction('missingPhoneError')
    }
  }, [error])

  const isFormValid = useCallback(
    () => !Object.keys(formFields).some((fieldName) => formFields[fieldName as EditPhoneFieldNames].isInvalid),
    [formFields]
  )

  const handleBlur = useCallback(() => {
    handleFieldChange('validateAll')
  }, [handleFieldChange])

  const handleConfirm: FormEventHandler<HTMLFormElement> = useCallback(
    async (event) => {
      event.preventDefault()
      event.nativeEvent.stopImmediatePropagation()

      if (registrationStore.processingRequest) {
        return
      }

      setError(false)
      handleFieldChange('validateAll')

      if (!isFormValid()) {
        return setImmediate(() => {
          formFields.phoneNumber.ref?.current.focus()
        })
      }

      await registrationStore
        .changePhoneNumber({ phone: formFields.phoneNumber.value, language: lang }, true)
        .catch(() => {
          setError(true)
        })
    },
    [formFields.phoneNumber.ref, formFields.phoneNumber.value, handleFieldChange, isFormValid, lang, registrationStore]
  )

  return (
    <ContentContainer>
      <ResponsiveHeadline as="h1">{t('registration.setMissingPhone.title')}</ResponsiveHeadline>
      <ContentSection>
        {error && (
          <Notification id="notification_container" contentType="alert" dismissible={false}>
            {t('registration.setMissingPhone.errorMessages.setPhoneNumberError')}
          </Notification>
        )}
        <Notification contentType="warning" dismissible={false}>
          {t('registration.setMissingPhone.instruction')}
        </Notification>
      </ContentSection>
      <ContentSection>
        <form id="phone_number_set_modal_form" onSubmit={handleConfirm}>
          <Input
            id="phoneNumber"
            name="phoneNumber"
            type="tel"
            onChange={handleFieldChange}
            onBlur={handleBlur}
            ref={formFields.phoneNumber.ref}
            value={formFields.phoneNumber.value}
            isInvalid={formFields.phoneNumber.touched && formFields.phoneNumber.isInvalid}
            message={getFormItemMessage(formFields.phoneNumber, t)}
            label={t(formFields.phoneNumber.labelTranslationKey as string)}
            helpText={t('registration.create.phoneInstructions')}
            lightBackground
            leftTemplate={<PhoneIcon {...defaultInputIconProps} />}
          />
          <ButtonContainer style={{ justifyContent: 'space-between' }}>
            <Button
              hasBackground
              type="submit"
              id="button_set_missing_phone"
              size="sm"
              isLoading={registrationStore.processingRequest}
            >
              {t('general.continue')}
            </Button>
          </ButtonContainer>
          <PostiPageFooter showCookiePreferences />
        </form>
      </ContentSection>
    </ContentContainer>
  )
}
