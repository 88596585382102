import { Loading } from '@postidigital/posti-components'
import { Body, BodySize, Button } from '@postidigital/posti-components/build/brand'
import { sendUserAction } from '@postidigital/posti-google-analytics'
import { BrandTheme } from '@postidigital/posti-theme'
import { observer } from 'mobx-react-lite'
import React, { MouseEventHandler, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Localization from '../../locales'
import AcceptStore, { IAcceptState } from '../../store/views/acceptStore'
import { ButtonContainer, ContentSection, InviteShortInfo, RoundedPageSection } from '..'
import { ResponsiveBody } from '../typography/ResponsiveBody'

export const AcceptInviteDialogComponent: React.FC = ({}) => {
  const { t } = useTranslation()
  const { accept, setState, invite } = useContext(AcceptStore)
  const [accepting, setAccepting] = useState(false)

  const handleAccept: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      setAccepting(true)
      e.preventDefault()

      if (invite.userExists) {
        return accept()
      }

      setState(IAcceptState.Registering)
    },
    [accept, invite.userExists, setState]
  )

  const handleCancel: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    sendUserAction({
      actionType: 'element_click',
      name: 'Reject OneAccount invite',
    })
    location.href = `https://posti.fi/${Localization.CurrentLocale}`
  }, [])

  useEffect(() => {
    sendUserAction({
      actionType: invite.userExists ? 'onboarding_accept_organization_started' : 'onboarding_create_account_started',
      businessId: invite.businessId,
      inviteOrigin: invite.invitedByPosti ? 'posti' : 'myorganization',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ContentSection>
        <RoundedPageSection>
          <ContentSection top={false}>
            <ResponsiveBody>{t('invite.accept.salute', { email: invite.email })}</ResponsiveBody>
            <ResponsiveBody>{t('invite.accept.preface')}</ResponsiveBody>
            <InviteShortInfo
              orgName={invite.organizationName}
              businessId={invite.businessId}
              invitorEmail={invite.invitorEmail}
              invitorName={invite.invitorName}
              invitedByPosti={invite.invitedByPosti}
            />
            {!invite.invitedByPosti && (
              <StyledBottomText size={BodySize.Three}>{t('invite.accept.supportMessage')}</StyledBottomText>
            )}
          </ContentSection>
        </RoundedPageSection>
      </ContentSection>
      <ButtonContainer position="start">
        <Button id="accept_decline_button" contentMode="secondary" onClick={handleCancel}>
          {t('invite.accept.declineButton')}
        </Button>
        <Button id="accept_invite_button" onClick={handleAccept} disabled={accepting} hasBackground>
          {invite.userExists ? t('invite.accept.acceptButton') : t('invite.accept.acceptAndCreateButton')}
          {accepting && <Loading size="xs" color={BrandTheme.color.white} style={{ marginLeft: '1rem' }} />}
        </Button>
      </ButtonContainer>
    </>
  )
}

export const AcceptInviteDialog = observer(AcceptInviteDialogComponent)

const StyledBottomText = styled(Body)`
  margin-top: 16px;
`
