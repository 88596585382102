import { action, makeObservable, observable } from 'mobx'

import { IDetailsUiState } from './dataModels/interfaces'

export class OrganizationsUiStateStore {
  state: IDetailsUiState = IDetailsUiState.Main

  setState = (state: IDetailsUiState) => {
    this.state = state
  }

  reset = () => {
    this.state = IDetailsUiState.Main
  }

  constructor() {
    makeObservable(this, {
      state: observable,
      setState: action,
      reset: action,
    })
  }
}

export const organizationsUiStateStore = new OrganizationsUiStateStore()
