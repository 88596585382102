import { Button } from '@postidigital/posti-components/build/brand'
import React, { useCallback, useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  ButtonContainer,
  Centered,
  ContentContainer,
  ContentSection,
  PostiPage,
  PostiPageFooter,
  TopNav,
} from '../../components'
import { ResponsiveBody } from '../../components/typography/ResponsiveBody'
import { ResponsiveHeadline } from '../../components/typography/ResponsiveHeadline'
import AllSetImage from '../../static/img/all_set.svg'
import { AccountTerminationTypeEnum, StoreContext } from '../../store'

export const TerminateAccountDoneView: React.FC = () => {
  const { t } = useTranslation()
  const { terminateAccountViewStore: store } = useContext(StoreContext)
  const allowedTransComponents: any = { p: <p />, italic: <i />, strong: <strong />, a: <a /> }

  const handleDoneButtonClick = useCallback(() => {
    if (store.terminationType == AccountTerminationTypeEnum.OrphanedAccountTermination) {
      window.location.href = 'https://www.posti.fi'
    } else {
      window.location.href = '/'
    }
  }, [store.terminationType])

  return (
    <>
      <TopNav hideNavigation={true} hasShadow={true} fullWidth={true} showCookieIcon />
      <PostiPage>
        <ContentContainer>
          <Centered>
            <AllSetImage />
            <ContentSection>
              <ResponsiveHeadline as="h1">{t(`termination.done.${store.terminationType}.title`)}</ResponsiveHeadline>
              <ResponsiveBody>
                <Trans
                  i18nKey={`termination.done.${store.terminationType}.message`}
                  components={allowedTransComponents}
                />
              </ResponsiveBody>
            </ContentSection>
            <ButtonContainer position="center">
              <Button id="btnGoBack" contentMode="primary" hasBackground onClick={handleDoneButtonClick}>
                {t(`termination.done.${store.terminationType}.button`)}
              </Button>
            </ButtonContainer>
          </Centered>
          <PostiPageFooter />
        </ContentContainer>
      </PostiPage>
    </>
  )
}
