import { Loading } from '@postidigital/posti-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const ViewLoading: React.FC = () => {
  const { t } = useTranslation()

  return (
    <StyledCenter>
      <Loading isBrand preset={'postiOrange60'} size="xl" statusText={t('general.loading')} />
    </StyledCenter>
  )
}

const StyledCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35%;
`
