import { Button } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { FC, MouseEventHandler, useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonContainer, ContentSection } from '../../components'
import { ResponsiveBody } from '../../components/typography/ResponsiveBody'
import Localization from '../../locales'
import { StoreContext, SUCCESS_STEP } from '../../store'

const SuccessStep: FC = () => {
  const { t } = useTranslation()
  const {
    corporateAccountRecoveryStore: { success },
  } = useContext(StoreContext)

  useEffect(() => {
    if (success !== SUCCESS_STEP.PASSWORD_SUCCESS) {
      // corporateAccountRecoveryStore.setStep(CORPORATE_RECOVERY_STEP.PASSWORD)
    }
  }, [success])

  const sourceUrl: string = useMemo(() => {
    const url = new URL(window.location.href)
    const searchParams = new URLSearchParams(url.searchParams)
    const source = searchParams.get('source')

    if (!source) {
      return ''
    }

    if (!['posti.fi', 'posti.com', 'tyvi.fi', 'posticloud.fi'].some((domain) => source.includes(domain))) {
      return ''
    }

    let sourceString = ''

    /**
     * Check if parameter exists and the value is a valid url
     * If Yes, then look it up in the url rewrite map and use the mapped value if present
     * If not present, then use the domain of the source url with the source protocol;
     */
    try {
      const sourceUrl = new URL(source)
      const url = sourceUrl.hostname + sourceUrl.pathname
      const urlRewrite = window.postienv.corporateRecoveryLoginUrlRewrite[url]

      sourceString = urlRewrite ?? sourceUrl.origin
    } catch (err) {
      sourceString = ''
    }

    return sourceString
  }, [])

  const cookieInfoLink: Record<typeof Localization.CurrentLocale, string> = useMemo(
    () => ({
      fi: 'https://www.posti.fi/fi/yrityksille/asiointikanavat',
      sv: 'https://www.posti.fi/sv/for-foretag/servicekanaler',
      en: 'https://www.posti.fi/en/for-businesses/service-channels',
    }),
    []
  )

  const navigate: MouseEventHandler<HTMLButtonElement> = useCallback((event) => {
    window.location.href = event.currentTarget.value
  }, [])

  return (
    <div>
      <ContentSection>
        <ResponsiveBody as="p">{t('accountRecovery.corporate.successStep.content')}</ResponsiveBody>
      </ContentSection>
      <ButtonContainer style={{ flexWrap: 'wrap' }}>
        {sourceUrl && (
          <Button
            size="sm"
            type="button"
            id="button_login"
            value={sourceUrl}
            contentMode="primary"
            iconPosition="right"
            onClick={navigate}
            hasBackground
          >
            {t('accountRecovery.corporate.successStep.ctaLabelLogin')}
          </Button>
        )}
        <Button
          size="sm"
          type="button"
          id="button_channels"
          contentMode="primary"
          value={cookieInfoLink[Localization.CurrentLocale]}
          hasBackground
          onClick={navigate}
        >
          {t('accountRecovery.corporate.successStep.ctaLabelBusinessChannels')}
        </Button>
      </ButtonContainer>
    </div>
  )
}

export default observer(SuccessStep)
