import { Modal, ModalStateWrapper } from '@postidigital/posti-components'
import { ErrorAnimation } from '@postidigital/posti-components/build/animations'
import { CloseIcon } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IOrganizationPhoneNumberEditingState, organizationsUiStateStore, StoreContext } from '../../store'
import { ContentSection } from '../layout'
import { ResponsiveBody } from '../typography/ResponsiveBody'

const OrganizationPhoneNumberChangeFailedComponent: React.FC = () => {
  const { t } = useTranslation()
  const { editOrganizationPhoneNumberStore } = useContext(StoreContext)
  const { editingState } = editOrganizationPhoneNumberStore

  let successMessageTimer: any = null

  const resetAndExit = useCallback(() => {
    organizationsUiStateStore.reset()
    editOrganizationPhoneNumberStore.reset()
  }, [editOrganizationPhoneNumberStore])

  const onCloseModal = useCallback(() => {
    if (successMessageTimer) {
      clearTimeout(successMessageTimer)
    }
    if (editingState == IOrganizationPhoneNumberEditingState.Saving) {
      resetAndExit()
    } else {
      editOrganizationPhoneNumberStore.setEditingState(IOrganizationPhoneNumberEditingState.NotEditing)
    }
  }, [editOrganizationPhoneNumberStore, editingState, resetAndExit, successMessageTimer])

  return (
    <ModalStateWrapper open>
      <Modal>
        <Modal.CloseButton type="button" aria-label="Close" onClick={onCloseModal} icon={CloseIcon} />
        <Modal.Content style={{ padding: '2rem' }}>
          <ContentSection top={false}>
            <StyledVerticalContainer>
              <ErrorAnimation width={'130px'} height={'130px'} />
              <ResponsiveBody> {t('myOrgsView.editPhoneNumber.error')}</ResponsiveBody>
            </StyledVerticalContainer>
          </ContentSection>
        </Modal.Content>
      </Modal>
    </ModalStateWrapper>
  )
}

export const OrganizationPhoneNumberChangeFailed = observer(OrganizationPhoneNumberChangeFailedComponent)

const StyledVerticalContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
