import { Notification } from '@postidigital/posti-components/build/brand'
import { BrandTheme } from '@postidigital/posti-theme'
import React, { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'

import { ResponsiveLinkButton } from '../../../components/typography/ResponsiveLinkButton'

export enum VERIFY_PHONE_ERROR {
  INCORRECT_CODE = 'incorrect_code',
  TOO_SOON = 'too_soon_after_failed_attempt',
  TOO_MANY = 'too_many_failed_attempts',
  INIT_FAIL = 'phone_verification_init_failed',
  INTERNAL_ERROR = 'internal_error',
  SEND_NEW_CODE_ERROR = 'send_new_code_error',
}

export const AlertNotification: React.FC<{ error: string; sendNewCode?: MouseEventHandler<HTMLButtonElement> }> = ({
  error,
  sendNewCode,
}) => {
  const { t } = useTranslation()
  let localisedMessage

  switch (error) {
    case VERIFY_PHONE_ERROR.INCORRECT_CODE:
      localisedMessage = t('registration.verifyPhone.errorMessages.invalidCode')
      break
    case VERIFY_PHONE_ERROR.TOO_SOON:
      localisedMessage = t('registration.verifyPhone.errorMessages.tooSoonAfterFailed')
      break
    case VERIFY_PHONE_ERROR.TOO_MANY:
      localisedMessage = t('registration.verifyPhone.errorMessages.failedCode')
      break
    case VERIFY_PHONE_ERROR.INTERNAL_ERROR:
      localisedMessage = t('general.error')
      break
    case VERIFY_PHONE_ERROR.SEND_NEW_CODE_ERROR:
      localisedMessage = t('registration.verifyPhone.errorMessages.sendNewCodeError')
      break
    default:
      localisedMessage = t('registration.verifyPhone.errorMessages.invalidCode')
  }

  return (
    <Notification id="notification_container" contentType="alert" dismissible={false}>
      <>
        {localisedMessage}
        {error === VERIFY_PHONE_ERROR.INCORRECT_CODE && (
          <>
            &nbsp;{t('registration.verifyPhone.errorMessages.pleaseTryAgainOr')}&nbsp;
            <ResponsiveLinkButton
              style={{ fontSize: 'inherit', color: BrandTheme.color.blue50 }}
              size="xxs"
              onClick={sendNewCode}
            >
              {t('registration.verifyPhone.errorMessages.requestNew')}
            </ResponsiveLinkButton>
          </>
        )}
      </>
    </Notification>
  )
}
