// interfaces that have to be shared in many files
// If a interface is only used in one file, keep it that the file

export interface IConsumerIdentity {
  communicationLanguage: string
  postalAddress: IPostalAddress
  preferredName: string
  marketingPermissions: any
  isTerminated: boolean
  terminationDate: string
  termsAndConditionsAccepted: boolean
  consumerPhoneNumber: IConsumerPhoneNumber
  contracts: IConsumerContract[]
}
export interface IIdentities {
  firstName: string
  lastName: string
  email: string
  consumerIdentity: IConsumerIdentity
  businessIdentities: IBusinessIdentities
}
export interface IConsumerInfo {
  phoneNumber: string
  phoneNumberVerified: boolean
  termsAndConditionsAccepted: boolean
}

interface IPostalAddress {
  addressLines: IAddressItem[]
  countryCode: string
}

interface IAddressItem {
  value: string
  type: string
}

export interface IOrganizations {
  businessId: string
  name: string
  mainUsers: IMainUsers[]
  selectedCustomerNumbers: IContractNumberModel[]
  selectedLogisticsContractNumbers: IContractNumberModel[]
  selectedTransportIds: IContractNumberModel[]
  selectedBusinessPartnerNumbers: IContractNumberModel[]
  selectedRoleGroups: IRoleGroupModel[]
}

export interface IRoleGroupModel {
  name: Record<ILocalizedNameModel, string>
  value: string
  roles: IRoleModel[]
}

export interface IContractNumberModel {
  label: string
  value: string
}

export interface IRoleModel {
  name: Record<ILocalizedNameModel, string>
  value: string
}

export type ILocalizedNameModel = 'en' | 'fi' | 'sv'

interface IMainUsers {
  email: string
  firstName: string
  lastName: string
}

export interface IBusinessIdentities {
  organizationPhoneNumber: string
  organizations: IOrganizations[]
}

export interface IConsumerContract {
  key: string
  acceptDate: string
  titles: Record<ILocalizedNameModel, string>
  links: Record<ILocalizedNameModel, string>
}

export interface IConsumerPhoneNumber {
  verified: boolean
  value: string
}

export interface IChangePasswordResult {
  result: string
}

export interface IChangeNameResult {
  result: string
}

export enum IMainViewState {
  Info,
  EditEmail,
  ChangePassword,
  EditPreferredName,
  EmailVerificationSuccess,
  EmailVerificationFailure,
  EditPhone,
  VerifyPhone,
}

export enum IDetailsUiState {
  Main,
  EditPhone,
}

export interface IOrgInvite {
  email: string
  organizationName: string
  businessId: string
  invitorEmail: string
  invitorName: string
  invitedByPosti: boolean
  userExists: boolean
  accepted: boolean
  hasPrimaryService: boolean
  hasSecondaryService: boolean
  primaryReturnUrl: string
}

export interface IUserInfo {
  consumerAccountExists: boolean
  businessAccountExists: boolean
  hasAccess: boolean
  email: string
  emailVerified: boolean
  registrationStronglyAuthenticated: boolean
  consumerInfo: IConsumerInfo
  registrationVersion: string
  registrationMinimumAge?: boolean
  phoneVerification?: IPhoneNumberVerification | IPhoneNumberVerificationResponse | null
}

export interface IConfirmEmailResult {
  verified: boolean
  registration: boolean
}

export interface IPhoneNumberVerificationResponse {
  phoneNumber: string
  expiryDuration: IDuration
  isExpired: boolean
}

export interface IPhoneNumberVerification {
  phoneNumber: string
  expiryDate: Date
  isExpired: boolean
}

export interface IDuration {
  seconds: number
}

export interface ISendVerifyPhoneCodeRequest {
  phone: string
  code: string
}

export interface IChangeConsumerPhoneRequest {
  phone: string
  language: ILocalizedNameModel
}
