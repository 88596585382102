import { action, makeObservable, observable } from 'mobx'

import { FeatureFlags } from '../../featureFlags'
import { serviceAdapter } from '../../service/serviceAdapter'
import { InfoStore } from '../infoStore'
import { UserStore } from '../userStore'

export enum AccountTerminationTypeEnum {
  NotDefined = 'NotDefined',
  ConsumerAccountTermination = 'ConsumerAccountTermination',
  BusinessAccountTermination = 'BusinessAccountTermination',
  OrphanedAccountTermination = 'OrphanedAccountTermination',
}

export enum AccountTeminationFlowState {
  Input = 'input',
  Deleting = 'deleting',
  Error = 'error',
  Logout = 'logout',
  Done = 'done'
}

export enum AccountTerminationFeedbackEnum {
  DontKnowHowToUseProduct = 'DontKnowHowToUseProduct',
  FoundAnotherProduct = 'FoundAnotherProduct',
  DidNotUseEnough = 'DidNotUseEnough',
  MissingFeatures = 'MissingFeatures',
  LeavingFinland = 'LeavingFinland',
  PrivacyConsiderations = 'PrivacyConsiderations',
  Other = 'Other'
}

export class TerminateAccountViewStore {
  // NOTE: fixed type-check errors in this file by disabling null checks. It is safe to do in this case, because this.reset() always sets values.
  // Ideally, this would not bunch of observables in a store, but use component state variables instead.
  // Refactoring this properly would need quite a bit of time so probably not worth doing unless there are other changes too.
  public isInitialized: boolean

  public flowState: AccountTeminationFlowState

  public isConsumerUser: boolean

  public isConsumerTerminationPending: boolean

  public isBusinessUser: boolean

  public userOrganizations: { orgId: string, orgName: string, isLastMainUser: boolean }[]

  public shouldShowAdminWarning: boolean

  public firstname: string

  public terminationType: AccountTerminationTypeEnum

  public confirmationUnderstoodChecked: boolean

  public areYouSureModalVisible: boolean

  public errorCode: string

  public terminationReason: AccountTerminationFeedbackEnum | null

  public terminationReasonDetails: string

  constructor() {
    makeObservable(this, {
      isInitialized: observable,
      flowState: observable,
      isConsumerUser: observable,
      isConsumerTerminationPending: observable,
      isBusinessUser: observable,
      userOrganizations: observable,
      shouldShowAdminWarning: observable,
      firstname: observable,
      terminationType: observable,
      confirmationUnderstoodChecked: observable,
      areYouSureModalVisible: observable,
      errorCode: observable,
      terminationReason: observable,
      terminationReasonDetails: observable,
      handleLocationChange: action,
      reset: action,
      initialize: action,
      deleteAccount: action,
      handleAccountDeletedSuccesfully: action
    })

    this.reset()
  }

  handleLocationChange = (path: string) => {
    // We want to restart the termination process if user navigates away from the flow
    // This prevents user from coming back to the flow with back-button after modifying their account
    if (!this.isInitialized) {
      return
    }
    if (!path.toLowerCase().startsWith('/terminateaccount')) {
      this.reset()
    }
  }

  reset = () => {
    this.isInitialized = false
    this.flowState = AccountTeminationFlowState.Input
    this.terminationType = AccountTerminationTypeEnum.NotDefined
    this.isConsumerUser = false
    this.isConsumerTerminationPending = false
    this.isBusinessUser = false
    this.userOrganizations = []
    this.shouldShowAdminWarning = false
    this.firstname = ''
    this.confirmationUnderstoodChecked = false
    this.areYouSureModalVisible = false
    this.errorCode = ''
    this.terminationReason = null
    this.terminationReasonDetails = ''
  }

  isAccountTerminationEnabledForUser = (infoStore: InfoStore, userStore: UserStore) => {
    const isConsumer = infoStore.isConsumer
    const isConsumerTerminationPending = isConsumer && userStore.identities.consumerIdentity.isTerminated === true
    const isCorporate = infoStore.isCorporate
    const isOrphan = !isConsumer && !isCorporate

    let terminationEnabled = false
    // eslint-disable-next-line prettier/prettier
    terminationEnabled ||= isConsumer && !isConsumerTerminationPending && FeatureFlags.EnableAccountTerminationForConsumers
    terminationEnabled ||= isCorporate && FeatureFlags.EnableAccountTerminationForBusinessUsers
    terminationEnabled ||= isOrphan
    terminationEnabled &&= FeatureFlags.EnableAccountTermination
    return terminationEnabled
  }


  initialize = (infoStore: InfoStore, userStore: UserStore) => {
    this.reset()
    this.isInitialized = true

    // Try to get user's preferred name
    if (infoStore.isConsumer)
      this.firstname = userStore.identities?.consumerIdentity.preferredName ?? ''
    if (!this.firstname)
      this.firstname = userStore.identities?.firstName ?? ''

    // Get consumer user data
    if (infoStore.isConsumer) {
      this.isConsumerUser = true
      this.isConsumerTerminationPending = userStore.identities.consumerIdentity.isTerminated === true
    }

    // Get business user data
    if (infoStore.isCorporate) {
      this.isBusinessUser = true
      if (userStore.identities.businessIdentities?.organizations) {
        for (let o of userStore.identities.businessIdentities.organizations) {
          const isLastMainUser = (o.mainUsers && o.mainUsers.length == 1 && o.mainUsers[0].email.toLowerCase() == userStore.identities.email.toLowerCase())
          this.shouldShowAdminWarning = this.shouldShowAdminWarning || isLastMainUser
          const orgData = {
            orgId: o.businessId,
            orgName: o.name,
            isLastMainUser: isLastMainUser,
          }
          this.userOrganizations.push(orgData)
        }
        this.userOrganizations = this.userOrganizations.sort((a, b) => a.orgName.localeCompare(b.orgName))
      }
    }
  }

  getErrorTranslationKey = () => {
    const code = this.errorCode ? this.errorCode : 'generic'
    return `accountTermination.${ code }`
  }

  // @ts-ignore
  deleteAccount = async (lang: string): Promise<{ logOut: boolean }> => {
    this.flowState = AccountTeminationFlowState.Deleting

    let payload = {
      terminationType: '',
      terminationReason: '',
      terminationReasonDetails: '',
    }
    payload.terminationReason = this.terminationReason as string
    payload.terminationReasonDetails = this.terminationReasonDetails
    switch (this.terminationType) {
      case AccountTerminationTypeEnum.OrphanedAccountTermination:
        payload.terminationType = 'ORPHAN'
        break
      case AccountTerminationTypeEnum.BusinessAccountTermination:
        payload.terminationType = 'BUSINESS'
        break
      case AccountTerminationTypeEnum.ConsumerAccountTermination:
        payload.terminationType = 'CONSUMER'
        break
      default:
        throw new Error(`Unsupported termination type: ${ this.terminationType }`)
    }

    try {
      let response = await serviceAdapter.sendPostRequest('/api/myaccount/terminate', payload)
      let data: any

      try {
        data = await response.json()
      } catch (err) {
      }

      if (response.status >= 400 || data?.status !== 'OK') {
        this.errorCode = data?.errorCode ?? 'generic'
        this.flowState = AccountTeminationFlowState.Error
      }

      if (data.status == 'OK') {
        this.handleAccountDeletedSuccesfully(lang, data)
        return { logOut: data.shouldLogout }
      }
    } catch (err) {
      if (!this.errorCode) {
        this.errorCode = 'generic'
      }
      this.flowState = AccountTeminationFlowState.Error
      return { logOut: false }
    }
  }

  handleAccountDeletedSuccesfully = async (lang: string, data: { shouldLogout: boolean }) => {
    if (data.shouldLogout) {
      this.flowState = AccountTeminationFlowState.Logout
    } else {
      this.flowState = AccountTeminationFlowState.Done
    }
  }
}

export const terminateAccountViewStore = new TerminateAccountViewStore()
