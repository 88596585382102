import { Notification } from '@postidigital/posti-components/build/brand'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { VERIFY_EMAIL_ERROR } from './VerifyEmail'

export const AlertNotification: React.FC<{ error: VERIFY_EMAIL_ERROR }> = ({ error }) => {
  const { t } = useTranslation()
  let localisedMessage

  switch (error) {
    case VERIFY_EMAIL_ERROR.EMAIL_IN_USE_ERROR:
      localisedMessage = t('registration.create.errorMessages.emailTaken')
      break
    case VERIFY_EMAIL_ERROR.SEND_NEW_LINK_ERROR:
      localisedMessage = t('registration.verifyEmail.errorMessages.sendNewLinkError')
      break
    case VERIFY_EMAIL_ERROR.INTERNAL_ERROR:
      localisedMessage = t('general.error')
      break
  }

  return (
    <Notification
      id="notification_container"
      contentType="alert"
      dismissible={false}
      style={{ marginBottom: '1.5rem' }}
    >
      <>{localisedMessage}</>
    </Notification>
  )
}
