import { action, makeObservable, observable } from 'mobx'

import { serviceAdapter } from '../service/serviceAdapter'

export class OrgStore {
  isLoading: boolean = true

  showModal: boolean = false

  savingChanges: boolean = false

  savingPhone: boolean = false

  leavingOrg: string = ''

  setShowModal = (showModal: boolean): void => {
    this.showModal = showModal
  }

  setSavingPhone = (savingPhone: boolean): void => {
    this.savingPhone = savingPhone
  }

  setLeavingOrg = (businessId: string): void => {
    this.leavingOrg = businessId
  }

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      showModal: observable,
      savingChanges: observable,
      savingPhone: observable,
      leavingOrg: observable,
      setShowModal: action,
      setSavingPhone: action,
      setLeavingOrg: action,
      updateOrganizationPhoneToBackend: action,
    })
  }

  async updateOrganizationPhoneToBackend(phone: string) {
    try {
      this.savingPhone = true

      let response = await serviceAdapter.sendPostRequest('/api/myaccount/changeorganizationphone', {
        phone,
      })
      this.savingPhone = false
      if (response.status >= 400) {
        throw new Error('Bad response from server')
      }
    } catch (err) {
      this.savingPhone = false
      console.error(err)
    }
  }
}

export const orgStore = new OrgStore()
