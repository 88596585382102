import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import { Redirect } from 'react-router-dom'

import { ErrorComponent } from '../../components'
import { FeatureFlags } from '../../featureFlags'
import { serviceAdapter } from '../../service/serviceAdapter'
import { AccountTeminationFlowState, AccountTerminationTypeEnum, StoreContext } from '../../store'
import { TerminateAccountConfirmationView } from './TerminateAccountConfirmationView'
import { TerminateAccountDoneView } from './TerminateAccountDoneView'
import { TerminateAccountFeedbackView } from './TerminateAccountFeedbackView'
import { TerminateAccountProcessingView } from './TerminateAccountProcessingView'
import { TerminateAccountTypeSelectionView } from './TerminateAccountTypeSelectionView'

interface AccountTerminationRouterProps {
  step?: string
}

type AccountTerminationProps = RouteComponentProps<AccountTerminationRouterProps>

enum TerminationStepsEnum {
  STEP_IDENTITY_SELECTION = 'selecttype',
  STEP_FEEDBACK = 'feedback',
  STEP_CONFIRM = 'confirm',
  STEP_PROCESSING = 'processing',
  STEP_DONE = 'done',
  STEP_ERROR = 'error',
}

const AccountTerminationMainViewComponent: React.FC<AccountTerminationProps> = ({ history, match }) => {
  const { userStore, terminateAccountViewStore: store } = useContext(StoreContext)
  const { i18n } = useTranslation()
  const isRouteValid = () => Object.values(TerminationStepsEnum).includes(step as TerminationStepsEnum)
  const RedirectToRoot = <Redirect to="/" />

  useEffect(() => {
    if (store.flowState === AccountTeminationFlowState.Logout) {
      serviceAdapter.handleLogOut()
    }
  }, [store.flowState])

  const isOrphanedUser = () => {
    return !store.isConsumerUser && !store.isBusinessUser
  }

  const cancelAccountTerminationFlow = () => {
    store.reset()
    history.push('/')
  }

  //
  // ACCOUNT SELECTION ACTIONS
  //
  const onAccountSelectionBackClick = () => {
    cancelAccountTerminationFlow()
  }

  const onAccountSelectionNextClick = () => {
    if (FeatureFlags.EnableAccountTerminationFeedback) {
      history.push(`/terminateAccount/${TerminationStepsEnum.STEP_FEEDBACK}`)
    } else {
      history.push(`/terminateAccount/${TerminationStepsEnum.STEP_CONFIRM}`)
    }
  }

  //
  // FEEDBACK ACTIONS
  //
  const onFeedbackBackClick = () => {
    history.push(`/terminateAccount/${TerminationStepsEnum.STEP_IDENTITY_SELECTION}`)
  }

  const onFeedbackNextClick = () => {
    history.push(`/terminateAccount/${TerminationStepsEnum.STEP_CONFIRM}`)
  }

  //
  // CONFIRMATION ACTIONS
  //
  const onConfirmBackClick = () => {
    if (FeatureFlags.EnableAccountTerminationFeedback) {
      history.push(`/terminateAccount/${TerminationStepsEnum.STEP_FEEDBACK}`)
    } else if (isOrphanedUser()) {
      cancelAccountTerminationFlow()
    } else {
      history.push(`/terminateAccount/${TerminationStepsEnum.STEP_IDENTITY_SELECTION}`)
    }
  }

  const onConfirmAccountDeletion = useCallback(async () => {
    const { logOut } = (await store.deleteAccount(i18n.language)) || {}
    if (!logOut) {
      await userStore.fetchIdentities()
    }
  }, [i18n.language, store, userStore])

  //
  // INPUT FLOW
  //
  const handleInputFlowState = (step: string) => {
    // Orphaned Users should never be shown account selection
    if (isOrphanedUser() && step == TerminationStepsEnum.STEP_IDENTITY_SELECTION) {
      store.terminationType = AccountTerminationTypeEnum.OrphanedAccountTermination
      if (FeatureFlags.EnableAccountTerminationFeedback) {
        return <Redirect to={`/terminateAccount/${TerminationStepsEnum.STEP_FEEDBACK}`} />
      }
      return <Redirect to={`/terminateAccount/${TerminationStepsEnum.STEP_CONFIRM}`} />
    }

    // Termination understood checkbox and are you sure dialog are always reset when leaving the view
    store.confirmationUnderstoodChecked =
      store.confirmationUnderstoodChecked && step == TerminationStepsEnum.STEP_CONFIRM
    store.areYouSureModalVisible = store.areYouSureModalVisible && step == TerminationStepsEnum.STEP_CONFIRM

    // Render correct screen
    switch (step) {
      case TerminationStepsEnum.STEP_IDENTITY_SELECTION:
        return (
          <TerminateAccountTypeSelectionView
            onBackClick={onAccountSelectionBackClick}
            onNextClick={onAccountSelectionNextClick}
          />
        )

      case TerminationStepsEnum.STEP_FEEDBACK:
        return <TerminateAccountFeedbackView onBackClick={onFeedbackBackClick} onNextClick={onFeedbackNextClick} />

      case TerminationStepsEnum.STEP_CONFIRM:
        return (
          <TerminateAccountConfirmationView
            onBackClick={onConfirmBackClick}
            onCancelTermination={cancelAccountTerminationFlow}
            onConfirmTermination={onConfirmAccountDeletion}
          />
        )
      default:
        return RedirectToRoot
    }
  }

  //
  // MAIN
  //

  // Check that route is valid and that the account termination feature is enabled
  let step = match.params.step ?? TerminationStepsEnum.STEP_IDENTITY_SELECTION
  if (!FeatureFlags.EnableAccountTermination) {
    return RedirectToRoot
  }
  if (!store.isInitialized) {
    return RedirectToRoot
  }
  if (!isRouteValid()) {
    return RedirectToRoot
  }

  if (store.flowState === AccountTeminationFlowState.Deleting && step != TerminationStepsEnum.STEP_PROCESSING) {
    return <Redirect to={`/terminateAccount/${TerminationStepsEnum.STEP_PROCESSING}`} />
  }

  if (store.flowState === AccountTeminationFlowState.Done && step != TerminationStepsEnum.STEP_DONE) {
    return <Redirect to={`/terminateAccount/${TerminationStepsEnum.STEP_DONE}`} />
  }

  if (store.flowState === AccountTeminationFlowState.Error && step != TerminationStepsEnum.STEP_ERROR) {
    return <Redirect to={`/terminateAccount/${TerminationStepsEnum.STEP_ERROR}`} />
  }

  // Render correct screen based on state and url
  switch (store.flowState) {
    case AccountTeminationFlowState.Input:
      return handleInputFlowState(step)
    case AccountTeminationFlowState.Deleting:
      return <TerminateAccountProcessingView />
    case AccountTeminationFlowState.Error:
      return <ErrorComponent error={store.getErrorTranslationKey()} />
    case AccountTeminationFlowState.Done:
      return <TerminateAccountDoneView />
    default:
      return RedirectToRoot
  }
}

export const AccountTerminationMainView = withRouter(observer(AccountTerminationMainViewComponent))
