import { action, computed, makeObservable, observable, runInAction } from 'mobx'

import { phoneNumberValidator } from '../service/phoneNumberValidator'
import { serviceAdapter } from '../service/serviceAdapter'

export enum IOrganizationPhoneNumberEditingState {
  NotEditing = 1,
  Saving,
  Success,
}

export class EditOrganizationPhoneNumberStore {
  constructor() {
    makeObservable(this, {
      newPhoneNumber: observable,
      editingState: observable,
      generalError: observable,
      isNewPhoneNumberValid: computed,
      isNewPhoneNumberInputInvalid: computed,
      isSavingPossible: computed,
      setNewPhoneNumber: action,
      setEditingState: action,
      reset: action,
      setGeneralError: action,
    })

    this.setInitialState()
  }

  newPhoneNumber: string

  editingState: IOrganizationPhoneNumberEditingState

  generalError: boolean

  lang: string | undefined

  get isNewPhoneNumberValid(): boolean {
    // Organization phone can be any phone number or empty
    return phoneNumberValidator.isValid(this.newPhoneNumber, true)
  }

  get isNewPhoneNumberInputInvalid(): boolean {
    return !!this.newPhoneNumber && !this.isNewPhoneNumberValid && this.newPhoneNumber.length > 0
  }

  get isSavingPossible(): boolean {
    if (!this.newPhoneNumber) return true
    return this.newPhoneNumber.length > 3 && this.isNewPhoneNumberValid
  }

  setInitialState() {
    this.newPhoneNumber = ''
    this.editingState = IOrganizationPhoneNumberEditingState.NotEditing
    this.generalError = false
  }

  setNewPhoneNumber(value: string) {
    this.newPhoneNumber = value
  }

  setEditingState(editingState: IOrganizationPhoneNumberEditingState) {
    this.editingState = editingState

    if (this.editingState == IOrganizationPhoneNumberEditingState.Saving) {
      this.save()
    }
  }

  reset() {
    this.generalError = false
    this.setInitialState()
  }

  async save() {
    try {
      let response = await serviceAdapter.sendPostRequest('/api/myaccount/changeorganizationphone', {
        phone: this.newPhoneNumber,
      })

      if (response.status >= 400) {
        runInAction(() => {
          this.generalError = true
        })
        return
      }

      runInAction(() => {
        this.setEditingState(IOrganizationPhoneNumberEditingState.Success)
        this.generalError = false
      })
    } catch (err) {
      console.error(err)
    }
  }

  setGeneralError(error: boolean) {
    this.generalError = error
  }
}

export const editOrganizationPhoneNumberStore = new EditOrganizationPhoneNumberStore()
