import moment from 'moment-timezone'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ResponsiveBody } from '../../../components/typography/ResponsiveBody'
import { IPhoneNumberVerification, IUserInfo } from '../../../store/dataModels/interfaces'

type CountdownTimerProps = {
  onTimerExpired: () => void
  info?: IUserInfo
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ onTimerExpired, info }) => {
  const { t } = useTranslation()

  const targetDateTime: Date = (info?.phoneVerification as IPhoneNumberVerification)?.expiryDate! as Date

  const calculateTimeLeft = useCallback(() => {
    const now = moment()
    const target = moment(targetDateTime)

    const isExpired = now.isAfter(target)

    if (isExpired) {
      return { expired: true }
    }

    const diff = target.diff(now)

    const duration = moment.duration(diff)
    const days = Math.floor(Math.abs(duration.asDays()))
    const hours = Math.abs(duration.hours())
    const minutes = Math.abs(duration.minutes())
    const secondsRemaining = Math.abs(duration.seconds())

    return { days, hours, minutes, seconds: secondsRemaining, expired: false }
  }, [targetDateTime])

  const [{ days, hours, minutes, seconds, expired }, setTimeLeft] = useState<{
    days: number
    hours: number
    minutes: number
    seconds: number
    expired: boolean
  }>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    expired: false,
  })

  const noTimeLeft = useMemo(() => [days, hours, minutes, seconds].every((time) => time === 0), [
    days,
    hours,
    minutes,
    seconds,
  ])

  useEffect(() => {
    if (!expired) {
      const timer = setInterval(() => {
        const newTimeLeft = calculateTimeLeft()
        setTimeLeft((prevTimeLeft) => ({
          ...prevTimeLeft,
          ...newTimeLeft,
        }))

        if (newTimeLeft.expired) {
          if (onTimerExpired) {
            onTimerExpired() // Call the callback when the timer expires
          }
        }
      }, 1000)

      return () => clearInterval(timer)
    }
  }, [targetDateTime, calculateTimeLeft, onTimerExpired, expired])

  if (info?.phoneVerification?.isExpired) {
    return null
  }

  if (noTimeLeft) {
    return null
  }

  return (
    <ResponsiveBody translate="no">
      {`${t('registration.verifyPhone.countdown')}`} {days > 0 && <b>{`${days} d `}</b>}
      {hours > 0 && <b>{`${hours} h `}</b>}
      {minutes > 0 && <b>{`${minutes} m `}</b>}
      {seconds > 0 && <b>{`${seconds} s`}</b>}
    </ResponsiveBody>
  )
}

export default CountdownTimer
