import { BrandTheme } from '@postidigital/posti-theme'
import styled from 'styled-components'

export const SideImageContainerContent = styled.div`
  max-width: 640px;
  position: relative;
  flex-basis: 100%;

  @media (min-width: ${BrandTheme.breakpoint.lg}) {
    flex-basis: 50%;
  }
`
