import {
  ButtonGroup,
  FeedbackBadIcon,
  FeedbackButton,
  FeedbackGoodIcon,
  FeedbackGreatIcon,
  FeedbackOkIcon,
  FeedbackTerribleIcon,
} from '@postidigital/posti-components'
import { Body, Headline } from '@postidigital/posti-components/build/brand'
import { ButtonGroupProps } from '@postidigital/posti-components/build/composite-components/ButtonGroup/ButtonGroup.types'
import { sendUserAction } from '@postidigital/posti-google-analytics'
import { observer } from 'mobx-react-lite'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'

const CSATSelectorComponent: FC = () => {
  const [sent, setSent] = useState(false)
  const { t } = useTranslation()

  const handleOnSelect: ButtonGroupProps['onChange'] = (_, value) => {
    if (sent) {
      return
    }

    setSent(true)
    // Send csat value to GA
    sendUserAction({
      actionType: 'gaveRating',
      ratingValue: `${value}`,
    })
  }

  const renderCSATSelector = () => (
    <ButtonGroup onChange={handleOnSelect} aria-label={t('csat.title')} selectedValue="">
      <FeedbackButton
        aria-label={t('csat.terrible.aria')}
        label={t('csat.terrible.label')}
        value="1"
        icon={FeedbackTerribleIcon}
      />
      <FeedbackButton aria-label={t('csat.bad.aria')} label={t('csat.bad.label')} value="2" icon={FeedbackBadIcon} />
      <FeedbackButton aria-label={t('csat.ok.aria')} label={t('csat.ok.label')} value="3" icon={FeedbackOkIcon} />
      <FeedbackButton aria-label={t('csat.good.aria')} label={t('csat.good.label')} value="4" icon={FeedbackGoodIcon} />
      <FeedbackButton
        aria-label={t('csat.great.aria')}
        label={t('csat.great.label')}
        value="5"
        icon={FeedbackGreatIcon}
      />
    </ButtonGroup>
  )

  return (
    <>
      <Headline as="h2" size="Six" style={{ marginBottom: '1rem' }}>
        {sent ? t('csat.thanks.title') : t('csat.title')}
      </Headline>
      {!sent && renderCSATSelector()}
      {sent && <Body size="Five">{t('csat.thanks.text')}</Body>}
    </>
  )
}

export const CSATSelector = withRouter(observer(CSATSelectorComponent))
