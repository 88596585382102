import { LockIcon, Notification } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { FC, FormEventHandler, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import {
  CancelButton,
  ChangePasswordErrorNotification,
  ChangePasswordInputList,
  EditButton,
  SaveButton,
  StyledButtonWrapper,
} from '../../components'
import { StaticInputLayout } from '../../components/StaticInputLayout'
import { StoreContext } from '../../store'
import { breakpoint2 } from '../../utils'
import { validatePasswordComplexity } from '../../utils/validation'
import { IEditComponentProps } from './accountRecovery'

const RecoverPassword: FC<IEditComponentProps> = ({ editState, type, toggleEditState }) => {
  const { t } = useTranslation()
  const {
    changePasswordStore,
    infoStore: { isStrongAuth },
  } = useContext(StoreContext)

  const { oldPassword, newPassword, newPasswordRepeat, result, setOldPassword, resetResult } = changePasswordStore
  const passwordsMatch = newPassword === newPasswordRepeat

  const toggleEdit = useCallback(() => {
    toggleEditState(type)

    if (!editState) {
      resetResult()
    }
  }, [editState, resetResult, toggleEditState, type])

  const canSave = useMemo(() => {
    if ((!oldPassword && !isStrongAuth) || !newPassword || !newPasswordRepeat) {
      return false
    }

    return validatePasswordComplexity(newPassword) && passwordsMatch
  }, [newPassword, newPasswordRepeat, oldPassword, passwordsMatch, isStrongAuth])

  const handleSave: FormEventHandler<HTMLFormElement> = useCallback(
    async (event) => {
      event.preventDefault()
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()

      setOldPassword('na')

      if (!canSave) {
        return null
      }

      await changePasswordStore.savePassword()

      toggleEdit()
    },
    [canSave, changePasswordStore, setOldPassword, toggleEdit]
  )

  return (
    <>
      <StyledButtonWrapper>
        <StaticInputLayout label={t(`general.password`)} content="********" Icon={LockIcon} />
        {!editState && <EditButton id="edit_password" onClick={toggleEdit} />}
      </StyledButtonWrapper>
      {result?.result === 'OK' && (
        <Notification contentType="success" dismissible={false}>
          <span>{t('mainView.changePassword.success.OK')}</span>
        </Notification>
      )}
      <ChangePasswordErrorNotification />
      {editState && (
        <PasswordForm id="edit_account_password_form" onSubmit={handleSave}>
          <ChangePasswordInputList />
          <ButtonGroup>
            <CancelButton type="button" id="cancel_password_change_button" onClick={toggleEdit} />
            <SaveButton type="submit" id="change_password_button" disabled={!canSave} />
          </ButtonGroup>
        </PasswordForm>
      )}
    </>
  )
}

export default observer(RecoverPassword)

export const PasswordForm = styled('form')(
  () => css`
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: end;
    width: 100%;
    flex-wrap: wrap;
  `
)

const ButtonGroup = styled('div')(
  () => css`
    display: flex;
    gap: 2rem;
    width: 100%;
    flex-wrap: wrap;

    button {
      width: 100%;
    }

    @media ${breakpoint2.tabletAndUp} {
      flex-wrap: nowrap;
      justify-content: end;

      button {
        width: auto;
      }
    }
  `
)
