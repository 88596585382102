import styled from 'styled-components'

export const StyledExpanderContentContainer = styled('div')`
  padding: 1rem 0.5rem;
`

export const StyledExpanderTitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`
