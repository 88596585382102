import { KeyboardKeys } from '@postidigital/posti-components'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { FormItem, getFormItemMessage } from '../utils/useFormFields'
import { StyledLink, StyledList } from './FormErrorLinks.styles'

interface FormErrorLinksProps {
  formFields: Record<string, FormItem>
}

export const FormErrorLinks: React.FC<FormErrorLinksProps> = ({ formFields }) => {
  const { t } = useTranslation()
  const onClick = (e: React.MouseEvent | React.KeyboardEvent, fieldName: string) => {
    e.preventDefault()
    formFields[fieldName]?.ref?.current.focus()
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLElement>, id: string) => {
    if (e.key !== KeyboardKeys.Enter && e.key !== KeyboardKeys.Space) {
      return null
    }
    e.preventDefault()
    onClick(e, id)
  }

  const elems = Object.keys(formFields)
    .filter((fieldName) => formFields[fieldName].isInvalid)
    .map((fieldName, i) => {
      return (
        <li key={i}>
          {i + 1}.&nbsp;
          <StyledLink tabIndex={0} onClick={(e) => onClick(e, fieldName)} onKeyPress={(e) => onKeyPress(e, fieldName)}>
            {getFormItemMessage(formFields[fieldName], t)}
          </StyledLink>
        </li>
      )
    })

  return <StyledList>{elems}</StyledList>
}
