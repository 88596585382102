import { Expander, KeyboardKeys } from '@postidigital/posti-components'
import { AdminIcon, Headline, ProfileIcon } from '@postidigital/posti-components/build/brand'
import { BrandTheme, XyzTheme } from '@postidigital/posti-theme'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import styled from 'styled-components'

import {
  ButtonContainer,
  ContentSection,
  ContractNumbersList,
  EditOrgButton,
  LeaveOrgButton,
  MainUserSection,
  RolesList,
  StyledExpanderContentContainer,
  StyledExpanderTitleContainer,
} from '../../components'
import Localization from '../../locales'
import { StoreContext } from '../../store'
import { IOrganizations, IRoleGroupModel } from '../../store/dataModels/interfaces'
import { arrayIsEmpty } from '../../utils/helpers'
import { BrandExpander } from '../../views/consumerDetailsView/SettingsView'

interface IProps {
  organization: IOrganizations
}

type Props = RouteComponentProps & IProps

export const OrganisationExpanderComponent: React.FC<Props> = ({ organization }) => {
  const [open, setOpen] = useState(false)
  const { orgStore } = useContext(StoreContext)
  const { setLeavingOrg, setShowModal } = orgStore

  const { t } = useTranslation()
  const currentLanguage = Localization.CurrentLocale

  const { name, businessId, selectedRoleGroups, selectedCustomerNumbers, mainUsers } = organization

  const isMainUser = checkMainuser(selectedRoleGroups)
  const mainUserToJS = toJS(mainUsers)

  const handleOpen = useCallback(() => {
    setOpen(!open)
  }, [open])

  const onExpanderKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLButtonElement>) => {
      if (e.key !== KeyboardKeys.Enter && e.key !== KeyboardKeys.Space) {
        return null
      }
      e.preventDefault()
      handleOpen()
    },
    [handleOpen]
  )

  const handleLeaveClick = useCallback(() => {
    setLeavingOrg(businessId)
    setShowModal(true)
  }, [businessId, setLeavingOrg, setShowModal])

  const orgAdminClickHandler = useCallback((language: string, organizationId: string) => {
    let orgAdminUrl = window['postienv']?.orgAdminUrl + '/organisation/{organizationId}'
    orgAdminUrl = orgAdminUrl.replace('{language}', language)
    orgAdminUrl = orgAdminUrl.replace('{organizationId}', organizationId)
    window.open(orgAdminUrl, '_blank')
  }, [])

  const ExpanderTitle = () => {
    return (
      <>
        {isMainUser ? (
          <AdminIcon width="2em" height="2em" color={XyzTheme.color.brandPurple} />
        ) : (
          <ProfileIcon width="2em" height="2em" color={XyzTheme.color.neutralIconGray} />
        )}
        <StyledTitleWrapper>{`${name} (${businessId})`}</StyledTitleWrapper>
      </>
    )
  }

  const ExpanderContent = () => (
    <ContentSection top={false}>
      <div>
        <RolesList organisation={organization} />
      </div>

      <ContentSection>
        <Headline as="div" size="Eight">
          {t(`myOrgsView.customerNumbersContracts`)}
        </Headline>

        {selectedCustomerNumbers && <ContractNumbersList organisation={organization} />}
      </ContentSection>

      {!arrayIsEmpty(mainUserToJS) && (
        <div>
          <Headline as="div" size="Nine">
            {t(`myOrgsView.mainUsers`)}
          </Headline>
          <StyledMainUsersWrapper>
            {mainUserToJS.map((mainUser) => {
              const { email, firstName, lastName } = mainUser
              return <MainUserSection key={email} email={email} name={`${firstName} ${lastName}`} />
            })}
          </StyledMainUsersWrapper>
        </div>
      )}

      <ButtonContainer style={{ flexWrap: 'wrap' }}>
        <LeaveOrgButton id={'leave_organization_button_' + businessId} onClick={handleLeaveClick} />

        {isMainUser && (
          <EditOrgButton
            id={'edit_organization_button_' + businessId}
            onClick={() => orgAdminClickHandler(currentLanguage, businessId)}
          />
        )}
      </ButtonContainer>
    </ContentSection>
  )

  return (
    <BrandExpander
      isBrand
      open={open}
      alignChevronToCenter
      customBackgroundColorClosed="white"
      customBackgroundColorOpened="white"
      customBackgroundColorOnHover="white"
      chevronColor={BrandTheme.color.gray50}
    >
      <Expander.Title
        onClick={handleOpen}
        onKeyDown={onExpanderKeyPress}
        aria-controls={'organization_expander_body_' + businessId}
        id={'organization_expander_title_' + businessId}
      >
        <StyledExpanderTitleContainer>
          <ExpanderTitle />
        </StyledExpanderTitleContainer>
      </Expander.Title>
      <Expander.Body id={'organization_expander_body_' + businessId}>
        <StyledExpanderContentContainer>
          <ExpanderContent />
        </StyledExpanderContentContainer>
      </Expander.Body>
    </BrandExpander>
  )
}

const checkMainuser = (selectedRoles: IRoleGroupModel[]): boolean => {
  return selectedRoles.some((roleGroup) => {
    return roleGroup.roles.some((role) => {
      return role.value === 'MainUser'
    })
  })
}

export const OrganisationExpander = withRouter(observer(OrganisationExpanderComponent))

const StyledTitleWrapper = styled('div')`
  margin-left: 40px;
  color: #000000;
`

const StyledMainUsersWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 16px;
`
