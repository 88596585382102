import { Body } from '@postidigital/posti-components/build/brand'
import { BodyProps } from '@postidigital/posti-components/build/design-tokens/brand-typography'
import { observer } from 'mobx-react-lite'
import React, { ComponentPropsWithRef, FC } from 'react'

import { layoutStore } from '../../store'

type ResponsiveBodyComponentProps = Omit<BodyProps, 'size'> & {
  size?: BodyProps['size']
} & ComponentPropsWithRef<typeof Body>

const ResponsiveBodyComponent: FC<ResponsiveBodyComponentProps> = ({ children, ...bodyProps }) => {
  const size = bodyProps.size || (!layoutStore.isDesktop ? 'Four' : 'Three')

  return (
    <Body {...bodyProps} size={size} style={{ margin: 0, ...bodyProps.style }}>
      {children}
    </Body>
  )
}

export const ResponsiveBody = observer(ResponsiveBodyComponent)
