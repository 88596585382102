import { Modal, ModalStateWrapper } from '@postidigital/posti-components'
import { Button } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { FormEvent, MutableRefObject, PropsWithChildren, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { serviceAdapter } from '../../../service/serviceAdapter'
import { ButtonContainer } from './RegistrationModal.styles'

interface ExitRegistrationModalProps {
  isOpen: boolean
  title: string
  onCancel: () => void
  onConfirm?: () => void
}

export const ExitRegistrationModal: React.FC<PropsWithChildren<ExitRegistrationModalProps>> = observer(
  ({ children, isOpen, title, onCancel, onConfirm }) => {
    const formRef: MutableRefObject<any> = useRef(null)
    const { t } = useTranslation()

    const handleCancel = () => {
      onCancel()
    }

    const handleConfirm = (e: FormEvent) => {
      e.preventDefault()
      onConfirm ? onConfirm() : serviceAdapter.handleLogOut()
    }

    return (
      <ModalStateWrapper open={isOpen}>
        <Modal>
          <Modal.Content>
            <Modal.Header>
              <Modal.Title size="Five">{title}</Modal.Title>
            </Modal.Header>
            <Modal.MainContent>
              {children}
              <Modal.ButtonWrapper>
                <ButtonContainer>
                  <Button size="sm" contentMode="secondary" onClick={handleCancel}>
                    {t('general.cancel')}
                  </Button>
                  <form ref={formRef} id="logout_form" method="post" onSubmit={handleConfirm}>
                    <Button hasBackground size="sm">
                      {t('registration.buttons.exit')}
                    </Button>
                  </form>
                </ButtonContainer>
              </Modal.ButtonWrapper>
            </Modal.MainContent>
          </Modal.Content>
        </Modal>
      </ModalStateWrapper>
    )
  }
)
