import moment from 'moment-timezone'

import { IDuration, IPhoneNumberVerificationResponse, IUserInfo } from '../store/dataModels/interfaces'

const setPhoneVerificationExpiryDate = (expiryDuration: IDuration) => {
  const now = moment()
  return now.add(expiryDuration, 'seconds').toDate()
}

export const userInfoResponseToUserInfoMapper = (userInfo?: IUserInfo) => {
  if (!userInfo) {
    throw userInfo
  }

  if (userInfo?.phoneVerification !== undefined && userInfo?.phoneVerification !== null) {
    const phoneVerificationResponse = userInfo?.phoneVerification as IPhoneNumberVerificationResponse

    if ('phoneNumber' in userInfo.phoneVerification) {
      userInfo.phoneVerification = {
        phoneNumber: userInfo.phoneVerification.phoneNumber,
        isExpired: userInfo.phoneVerification.isExpired,
        expiryDate: setPhoneVerificationExpiryDate(phoneVerificationResponse.expiryDuration),
      }
    }
  }

  return userInfo
}
