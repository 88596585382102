import { ArrowRightIcon, Headline } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { breakpoint2 } from '../../utils'
import CookieLink from '../general/CookieLink'
import { ResponsiveLink } from '../typography/ResponsiveLink'

const PostiPageFooterComponent: React.FC<{
  className?: string
  id?: string
  titleTranslationKey?: string
  customerServiceLink?: string
  customerServiceLabel?: string
  showCookiePreferences?: boolean
}> = ({
  id,
  className,
  showCookiePreferences,
  customerServiceLink = 'termination.footer.path',
  titleTranslationKey = 'termination.footer.title',
  customerServiceLabel = 'termination.footer.label',
}) => {
  const { t, i18n } = useTranslation()
  const idString = id ? id : 'footer'
  const titleId = idString + '_title'
  const serviceId = idString + '_contents'

  /**
   * One trust button and container dom element ref
   */
  const oneTrustObserver = useRef<MutationObserver | null>(null)

  const removeObserver = () => {
    if (oneTrustObserver?.current) {
      oneTrustObserver.current.disconnect()
      oneTrustObserver.current = null
    }
  }

  useEffect(() => {
    /**
     * Wait for the onetrust module to initialize and attach event listeners to it.
     */
    if (showCookiePreferences) {
      // Options for the observer (which mutations to observe)
      const config = { attributes: false, childList: true, subtree: false }
      const oneTrustModal = document.getElementById('onetrust-consent-sdk')

      if (oneTrustModal) {
        // @ts-ignore
        OneTrust?.OnConsentChanged(initNewRelic)
      } else {
        oneTrustObserver.current = new MutationObserver(() => {
          const oneTrustModal = document.getElementById('onetrust-consent-sdk')

          if (oneTrustModal) {
            // Initialize OneTrust modal, because it loses event listeners on navigation
            // @ts-ignore
            OneTrust?.changeLanguage(i18n.language)

            // Attach a callback to the consent change handler to re-evaluate new relic state
            // @ts-ignore
            OneTrust?.OnConsentChanged(initNewRelic)

            removeObserver()
          }
        })

        oneTrustObserver.current.observe(document.body, config)
      }
    }

    return () => {
      removeObserver()
    }
  }, [i18n.language, showCookiePreferences])

  return (
    <PostiFooterContainer id={idString} className={className}>
      {titleTranslationKey && (
        <Headline size="Nine" style={{ paddingBottom: '20px' }} id={titleId}>
          {t(titleTranslationKey)}
        </Headline>
      )}
      <ResponsiveLink
        id={serviceId}
        key={serviceId}
        href={t(customerServiceLink)}
        icon={ArrowRightIcon}
        target="_blank"
        tabIndex={0}
      >
        {t(customerServiceLabel)}
      </ResponsiveLink>
      {showCookiePreferences && <CookieLink idString={idString} />}
    </PostiFooterContainer>
  )
}

export const PostiPageFooter = observer(PostiPageFooterComponent)

export const postiPageFooterHeight = 210

export const PostiFooterContainer = styled('div')(
  ({
    theme: {
      brand: { spacing },
    },
  }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: ${spacing.space10}rem;

    & a {
      padding: 0;
    }

    @media ${breakpoint2.tabletAndUp} {
      margin-top: ${spacing.space26}rem;
    }
  `
)
