import { action, makeObservable, observable, runInAction } from 'mobx'

import { serviceAdapter } from '../service/serviceAdapter'
import { mainViewStore } from './mainViewStore'
import { userStore } from './userStore'

export class EditPreferredNameStore {
  newPreferredName: string = ''

  savingChanges: boolean = false

  error: boolean = false

  changeSuccess: boolean = false

  hideNotificationTimeout: ReturnType<typeof setTimeout> | undefined = undefined

  initialize = (value: string) => {
    this.error = false
    this.changeSuccess = false
    this.savingChanges = false
    this.newPreferredName = value
    if (this.hideNotificationTimeout) {
      clearTimeout(this.hideNotificationTimeout)
      this.hideNotificationTimeout = undefined
    }
  }

  setNewPreferredName = (value: string) => {
    this.newPreferredName = value
  }

  savePreferredName = async () => {
    if (this.savingChanges) {
      return
    }

    this.savingChanges = true
    try {
      let response = await serviceAdapter.sendPostRequest('/api/myaccount/preferredname', {
        name: this.newPreferredName,
      })

      if (response.status < 400) {
        runInAction(() => {
          this.error = false
          this.savingChanges = false
          this.changeSuccess = true
          userStore.setPreferredName(this.newPreferredName)
          this.hideNotiAfterTimeout(1000, () => {
            mainViewStore.reset()
          })
        })
      } else {
        this.error = true
        this.savingChanges = false
        this.changeSuccess = false
        this.hideNotiAfterTimeout()
      }
    } catch (err) {
      this.error = true
      this.savingChanges = false
      this.changeSuccess = false
      this.hideNotiAfterTimeout()
      console.error(err)
    }
  }

  hideNotiAfterTimeout = async (timeout?: number, callback?: () => void) => {
    if (this.hideNotificationTimeout) {
      clearTimeout(this.hideNotificationTimeout)
    }

    this.hideNotificationTimeout = setTimeout(
      () => {
        this.error = false
        this.changeSuccess = false
        if (callback) {
          callback()
        }
      },
      timeout ? timeout : 5000
    )
  }

  constructor() {
    makeObservable(this, {
      newPreferredName: observable,
      savingChanges: observable,
      error: observable,
      changeSuccess: observable,
      initialize: action,
      setNewPreferredName: action,
      savePreferredName: action,
      hideNotiAfterTimeout: action,
    })
  }
}

export const editPreferredNameStore = new EditPreferredNameStore()
