import { GlobalStyleNormalization } from '@postidigital/posti-components'
import { initDatalayer } from '@postidigital/posti-google-analytics'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { I18nextProvider } from 'react-i18next'
import { Route, Switch } from 'react-router'
import { BrowserRouter, Redirect } from 'react-router-dom'
import styled, { createGlobalStyle, css } from 'styled-components'

import {
  ErrorBoundary,
  ErrorComponent,
  PostiPage,
  SideNav,
  TerminateAccountGuard,
  TopNav,
  ViewLoading,
} from '../components'
import { FeatureFlags } from '../featureFlags'
import Localization, { i18nInstance } from '../locales'
import { StoreContext, StoreContextValue } from '../store'
import { breakpoint } from '../utils'
import ThemeWrapper from '../utils/ThemeWrapper'
import { AcceptInviteView } from './acceptInviteFlow'
import { AccountIncompleteView } from './AccountIncompleteView'
import { AccountRecovery, AccountRecoveryInfo } from './accountRecovery'
import { AccountTerminationMainView } from './accountTermination/AccountTerminationMainView'
import { AccountVerifiedView } from './accountVerified/AccountVerifiedView'
import { AddressView } from './consumerDetailsView/AddressView'
import { SettingsView } from './consumerDetailsView/SettingsView'
import { CorporateAccountRecovery } from './corporateAccountRecovery'
import { MainView } from './mainView'
import { MyOrgsView } from './myOrgsView'
import { RegistrationMainView } from './registration'

// Ensure localization is initialized
Localization.initialize()

// Set custom global styles here. Most of these should come from GlobalStyleNormalization
const GlobalStyle = createGlobalStyle`
  * {
    -webkit-tap-highlight-color: transparent;
  }
`

interface IProps {
  path: string | string[]
  component: React.ComponentClass<any> | React.FC<any>
  exact?: boolean
  showSideNavigation?: boolean
  consumerRoute?: boolean
}

// navRoutes are inside the app components that requires log in
const NavRoute: React.FC<IProps> = observer(
  ({ exact, path, showSideNavigation = true, component: Component, consumerRoute }) => {
    const { infoStore, layoutStore, userStore } = useContext(StoreContext)
    const [storesFetched, setStoresFetched] = useState(false)
    const [unauthorised, setUnauthorised] = useState(false)
    const [error, setError] = useState(false)

    const renderSideNavigation = useMemo(() => showSideNavigation && layoutStore.isDesktop, [
      layoutStore.isDesktop,
      showSideNavigation,
    ])

    useEffect(() => {
      const fetchData = async () => {
        await Promise.all([infoStore.fetchUserInfo(), userStore.fetchIdentities()])
      }

      fetchData()
        .catch((response) => {
          // Keep showing the loading screen if used is unauthorised, because it will trigger redirect to ubi login page.
          if (response.status === 401) {
            setUnauthorised(true)
          } else {
            setError(true)
          }
        })
        .finally(() => {
          setStoresFetched(true)
        })
    }, [infoStore, userStore])

    if (!storesFetched || unauthorised) {
      return <ViewLoading />
    }

    if (error || (consumerRoute && !infoStore.isConsumer)) {
      return <ErrorComponent error={'general'} />
    }

    if (!infoStore.consumerRegistrationComplete && !infoStore.isCorporate) {
      // If consumer account is not complete and user does not have a corporate account, then we know that user is in the middle of registration, but
      // for some reason in a wrong route. Let's redirect to /registration.
      return <Redirect to="/registration" />
    }

    return (
      <Route
        exact={exact}
        path={path}
        render={(props) => (
          <>
            <TopNav showCookieIcon />
            <PostiPage>
              <StyledContentWrapper>
                {renderSideNavigation && <SideNav />}
                <StyledContainer>
                  <Component {...props} />
                </StyledContainer>
              </StyledContentWrapper>
            </PostiPage>
          </>
        )}
      />
    )
  }
)

export const IndexComponent: React.FC = () => {
  useEffect(() => {
    // There are two GTM environments: prod and dev
    if (window['postienv']?.env === 'production') {
      // initDatalayer points to prod when gtm_auth and gtm_preview are left empty
      initDatalayer('GTM-THJ4VJ8', `${window['postienv']?.postiNonce}`)
    } else {
      // GTM dev env is used for all environments except production
      initDatalayer('GTM-THJ4VJ8', `${window['postienv']?.postiNonce}`, {
        gtm_auth: 'pWmaYkCOMr-1IxnexakZTw',
        gtm_preview: 'env-186',
      })
    }
  }, [])

  return (
    <ThemeWrapper>
      <I18nextProvider i18n={i18nInstance}>
        <GlobalStyle />
        <GlobalStyleNormalization />
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <ErrorBoundary>
            <TerminateAccountGuard>
              <StoreContext.Provider value={StoreContextValue}>
                <Switch>
                  <NavRoute exact path="/" component={MainView} />
                  <NavRoute exact path="/address" component={AddressView} consumerRoute />
                  <NavRoute exact path="/settings" component={SettingsView} consumerRoute />
                  <NavRoute exact path="/organisations" component={MyOrgsView} />
                  <Route exact path="/acceptinvite" component={AcceptInviteView} />
                  <Route exact path="/verify-email/:code" component={AccountVerifiedView} />
                  <Route exact path="/terminateAccount/:step?" component={AccountTerminationMainView} />
                  {FeatureFlags.EnableConsumerRegistration && (
                    <Route exact path="/registration" component={RegistrationMainView} />
                  )}
                  {/*user gets redirected here when they log in to sso, and email is not verified (ldap mail attribute missing)*/}
                  <Route exact path="/incomplete" component={AccountIncompleteView} />
                  <Route exact path="/corporate-recovery" component={CorporateAccountRecovery} />
                  <Route exact path="/account-recovery" component={AccountRecovery} />
                  <Route exact path="/account-recovery-info" component={AccountRecoveryInfo} />
                  <Route path="/myaccount">
                    <Redirect to="/" />
                  </Route>
                  <Route path="/details">
                    <Redirect to="/" />
                  </Route>
                  <Route path="/myaccount/details">
                    <Redirect to="/" />
                  </Route>
                  <Route path="/myaccount/verifyinfo">
                    <Redirect to="/" />
                  </Route>
                  <Route render={() => <ErrorComponent error="badUrl" />} />
                </Switch>
              </StoreContext.Provider>
            </TerminateAccountGuard>
          </ErrorBoundary>
        </BrowserRouter>
      </I18nextProvider>
    </ThemeWrapper>
  )
}

export const Index = observer(IndexComponent)

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100rem;
  margin: 0 auto;
`

// For some reason webstorm complains about min-width rule below even that it's correct. Let's just disable the error.
// noinspection CssInvalidPropertyValue
const StyledContainer = styled.div(
  ({
    theme: {
      brand: { spacing },
    },
  }) => css`
    padding: ${spacing.space12}rem ${spacing.space6}rem ${spacing.space8}rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    background: white;

    @media ${breakpoint.mobile} {
      padding: ${spacing.space2}rem ${spacing.space4}rem ${spacing.space20}rem;
      min-width: 304px;

      @media ${breakpoint.sm} {
        padding: ${spacing.space2}rem ${spacing.space6}rem ${spacing.space20}rem;
      }
    }
  `
)
