import { Loading } from '@postidigital/posti-components'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { LoadingContainer } from './postiPageLoading.styles'

interface Props {
  id: string
}

export const PostiPageLoading: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation()

  return (
    <LoadingContainer id={id}>
      <Loading isBrand preset={'postiOrange60'} size="xl" statusText={t('general.loading')} />
    </LoadingContainer>
  )
}
