import { Input } from '@postidigital/posti-components/build/brand'
import styled, { css } from 'styled-components'

export const AbsoluteMessageInput = styled(Input)(
  () => css`
    flex-grow: 1;
    width: 100%;

    & > div[id$='-message'] {
      position: absolute;
    }
  `
)
