import { Loading } from '@postidigital/posti-components'
import { observer } from 'mobx-react-lite'
import React, { FC, ReactNode, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { MinYSpacingWrapper, PostiPage, PostiPageFooter, TopNav } from '../../components'
import { ResponsiveHeadline } from '../../components/typography/ResponsiveHeadline'
import { CORPORATE_RECOVERY_STEP, StoreContext } from '../../store'
import { TextContainer } from '../registration/registration.styles'
import EmailStep from './EmailStep'
import PasswordStep from './PasswordStep'
import SuccessStep from './SuccessStep'

const getMailParam = () => {
  const url = new URL(window.location.href)
  const searchParams = new URLSearchParams(url.searchParams)
  return searchParams.get('mail') || ''
}

const contentMap: Record<CORPORATE_RECOVERY_STEP, { title: string; component: ReactNode }> = {
  [CORPORATE_RECOVERY_STEP.EMAIL]: {
    title:
      getMailParam() === '0'
        ? 'accountRecovery.corporate.emailStep.mailReset.title'
        : 'accountRecovery.corporate.emailStep.title',
    component: <EmailStep mail={getMailParam()} />,
  },
  [CORPORATE_RECOVERY_STEP.PASSWORD]: {
    title: 'accountRecovery.corporate.passwordStep.title',
    component: <PasswordStep />,
  },
  [CORPORATE_RECOVERY_STEP.SUCCESS]: {
    title: 'accountRecovery.corporate.successStep.title',
    component: <SuccessStep />,
  },
}

const CorporateAccountRecoveryView: FC = () => {
  const { t } = useTranslation()

  const {
    corporateAccountRecoveryStore: { step, isSaving },
  } = useContext(StoreContext)
  const { title, component } = useMemo(() => contentMap[step], [step])

  return (
    <>
      {isSaving && (
        <AbsoluteLoaderBackground>
          <CenteredLoader isBrand preset="postiOrange60" size="xl" />
        </AbsoluteLoaderBackground>
      )}
      <TopNav hideNavigation hideLogout />
      <PostiPage>
        <MinYSpacingWrapper>
          <TextContainer>
            <ResponsiveHeadline as="h1">{t(title)}</ResponsiveHeadline>
            {component}
            <PostiPageFooter showCookiePreferences />
          </TextContainer>
        </MinYSpacingWrapper>
      </PostiPage>
    </>
  )
}

export default observer(CorporateAccountRecoveryView)

const AbsoluteLoaderBackground = styled('div')(
  () => css`
    top: 0;
    left: 0;
    width: 100%;
    z-index: 101;
    height: 100%;
    position: absolute;
    background: rgba(38, 44, 48, 0.64);
  `
)

const CenteredLoader = styled(Loading)(
  () => css`
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `
)
