import { observer } from 'mobx-react-lite'
import React, {
  ChangeEventHandler,
  FC,
  FormEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { ContentSection, SideImageContainerContent } from '../../components'
import { PhoneVerificationForm } from '../../components/PhoneVerificationForm'
import { editPhoneNumberStore, infoStore, IPhoneNumberEditingState, StoreContext } from '../../store'
import { IMainViewState } from '../../store/dataModels/interfaces'
import { maxSixDigits } from '../registration/utils/customTypeRestrictions'
import { useFormFields } from '../registration/utils/useFormFields'
import { length, onlyDigits } from '../registration/utils/validators'
import { VERIFY_PHONE_ERROR } from '../registration/verifyPhone/AlertNotification'

type VerifyPhoneFieldNames = 'code'

const VerifyConsumerPhoneComponent: FC = () => {
  const [formFields, handleFieldChange] = useFormFields<VerifyPhoneFieldNames>({
    code: {
      value: '',
      validator: (value) => length(value, 6) && onlyDigits(value),
      isInvalid: false,
      touched: false,
      ref: useRef(),
      labelTranslationKey: 'registration.verifyPhone.inputLabel',
      invalidMessageTranslationKey: 'registration.verifyPhone.validation.codeError',
      customTypeRestriction: (value) => maxSixDigits(value),
    },
  })

  const isFormValid = useMemo(
    () => !Object.keys(formFields).some((fieldName) => formFields[fieldName as VerifyPhoneFieldNames].isInvalid),
    [formFields]
  )

  const {
    infoStore: { userInfo },
    userStore,
    detailsUiStateStore,
    mainViewStore,
    editPhoneNumberStore: { newPhoneNumber, editingState },
  } = useContext(StoreContext)
  const [sendingCode, setSendingCode] = useState<boolean>(false)
  const [newCodeSent, setNewCodeSent] = useState<boolean>(true)
  const [requestingNewCode, setRequestingNewCode] = useState<boolean>(false)

  const [error, setError] = useState<VERIFY_PHONE_ERROR | null>(null)

  const clearAllNotifications = useCallback(() => {
    setError(null)
    setNewCodeSent(false)
    setSendingCode(false)
  }, [])

  const resetAndExit = useCallback(() => {
    detailsUiStateStore.reset()
    editPhoneNumberStore.reset()
    mainViewStore.reset()
  }, [detailsUiStateStore, mainViewStore])

  const cancelHandler = useCallback(() => {
    resetAndExit()
    mainViewStore.setState(IMainViewState.Info)
  }, [mainViewStore, resetAndExit])

  const inputChangeHandler: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const code = event.target.value

      handleFieldChange(event)
      editPhoneNumberStore.setCode(code)
    },
    [handleFieldChange]
  )

  const changePhoneHandler = useCallback(() => {
    resetAndExit()
    mainViewStore.setState(IMainViewState.EditPhone)
  }, [mainViewStore, resetAndExit])

  const onSendNewCode = useCallback(async () => {
    setRequestingNewCode(true)
    clearAllNotifications()

    try {
      await editPhoneNumberStore.sendVerifyCode()
      setNewCodeSent(true)
      // clear input field
      const customEvent = new CustomEvent('updateField', { detail: { fieldName: 'code', value: '' } })
      handleFieldChange(customEvent)
    } catch (message) {
      setError(VERIFY_PHONE_ERROR.SEND_NEW_CODE_ERROR)
    } finally {
      setRequestingNewCode(false)
    }
  }, [clearAllNotifications, handleFieldChange])

  const onContinue: FormEventHandler<HTMLFormElement> = useCallback(
    async (event) => {
      event.preventDefault()
      event.nativeEvent.stopImmediatePropagation()

      if (sendingCode || formFields.code.value.length !== 6) {
        return null
      }

      handleFieldChange('validateAll')
      clearAllNotifications()

      if (isFormValid) {
        setSendingCode(true)
        editPhoneNumberStore
          .verifyCode()
          .then(() => {
            resetAndExit()
            mainViewStore.setState(IMainViewState.Info)
          })
          .catch((message) => {
            setError(message)
          })
          .finally(() => {
            setSendingCode(false)
          })
      } else {
        setImmediate(() => {
          formFields.code.ref?.current.focus()
        })
      }
    },
    [
      clearAllNotifications,
      formFields.code.ref,
      formFields.code.value.length,
      handleFieldChange,
      isFormValid,
      mainViewStore,
      resetAndExit,
      sendingCode,
    ]
  )

  const handleTimerExpired = useCallback(() => {
    infoStore.setPhoneVerificationExpiration(true)
  }, [])

  useEffect(() => {
    if (editingState == IPhoneNumberEditingState.CodeWasVerified) {
      userStore.setPhoneNumber(newPhoneNumber)
      editPhoneNumberStore.setNewPhoneNumber('')
    }
  }, [editingState, newPhoneNumber, userStore])

  return (
    <SideImageContainerContent>
      <ContentSection>
        <PhoneVerificationForm
          error={error}
          info={userInfo}
          onContinue={onContinue}
          formFields={formFields}
          newCodeSent={newCodeSent}
          sendingCode={sendingCode}
          cancelHandler={cancelHandler}
          onSendNewCode={onSendNewCode}
          requestingNewCode={requestingNewCode}
          handleFieldChange={inputChangeHandler}
          changePhoneHandler={changePhoneHandler}
          handleTimerExpired={handleTimerExpired}
        />
      </ContentSection>
    </SideImageContainerContent>
  )
}

export const VerifyConsumerPhone = observer(VerifyConsumerPhoneComponent)
