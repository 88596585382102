import { DefaultIconProps } from '@postidigital/posti-components/build/design-tokens/icons/icons.types'
import React, { FC } from 'react'
import styled, { css } from 'styled-components'

import { defaultInputIconProps } from '../../views/registration/registration.styles'
import { ResponsiveBody } from '../typography/ResponsiveBody'
import { MailBox } from './StaticInputLayout.style'

export interface IStaticInputLayout {
  Icon?: FC<DefaultIconProps>
  label: string
  content: string
}

export const StaticInputLayout: FC<IStaticInputLayout> = ({ Icon, label, content }) => {
  return (
    <MailBox>
      <IconContainer>{Icon && <Icon {...defaultInputIconProps} />}</IconContainer>
      <div>
        <ResponsiveBody size={'Five'}>{label}</ResponsiveBody>
        <ResponsiveBody size={'Five'} style={{ wordBreak: 'break-all' }}>
          <b>{content}</b>
        </ResponsiveBody>
      </div>
    </MailBox>
  )
}

const IconContainer = styled('div')(
  () => css`
    width: 100%;
    max-width: 24px;
    min-width: 24px;
  `
)
