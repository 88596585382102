import { LockIcon, Password } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { FC, FocusEventHandler, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { StoreContext } from '../../../store'

type PasswordChangeFields = 'newPassword' | 'newPasswordRepeat'

const ChangePasswordInputList: FC = observer(() => {
  const { t } = useTranslation()
  const [touched, setTouched] = useState<Record<PasswordChangeFields, boolean>>({
    newPassword: false,
    newPasswordRepeat: false,
  })

  const {
    changePasswordStore,
    infoStore: { isStrongAuth },
  } = useContext(StoreContext)
  const {
    oldPassword,
    newPassword,
    newPasswordRepeat,
    setOldPassword,
    setNewPassword,
    setNewPasswordRepeat,
  } = changePasswordStore
  const passwordsMatch = newPassword === newPasswordRepeat

  const validatePasswordComplexity = useCallback((value: any) => {
    const regExp = /^(?=.*[^a-zA-Z0-9])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,254}$/

    return regExp.test(value)
  }, [])

  const handleInputBlur: FocusEventHandler<HTMLInputElement> = useCallback((event) => {
    setTouched((prevState) => ({ ...prevState, [event.target.name]: true }))
  }, [])

  return (
    <>
      {!isStrongAuth && (
        <FullWidthInputContainer id="oldPassword">
          <Password
            autoFocus
            lightBackground
            id="oldPassword-input"
            label={t(`mainView.changePassword.oldPassword`)}
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            leftTemplate={<LockIcon width="24px" />}
          />
        </FullWidthInputContainer>
      )}

      <FullWidthInputContainer id="newPassword">
        <Password
          name="newPassword"
          lightBackground
          id="newPassword-input"
          label={t(`mainView.changePassword.newPassword`)}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          onBlur={handleInputBlur}
          message={
            newPassword && !validatePasswordComplexity(newPassword)
              ? t('mainView.changePassword.error.NEW_PASSWORD_NOT_VALID')
              : ''
          }
          isInvalid={!!(newPassword && !validatePasswordComplexity(newPassword))}
          leftTemplate={<LockIcon width="24px" />}
        />
      </FullWidthInputContainer>
      <FullWidthInputContainer id="newPasswordAgain">
        <Password
          name="newPasswordRepeat"
          lightBackground
          id="newPasswordAgain-input"
          label={t(`mainView.changePassword.newPasswordAgain`)}
          value={newPasswordRepeat}
          onChange={(e) => setNewPasswordRepeat(e.target.value)}
          onBlur={handleInputBlur}
          isInvalid={touched['newPasswordRepeat'] && !passwordsMatch}
          message={touched['newPasswordRepeat'] && !passwordsMatch ? t(`mainView.changePassword.noMatch`) : ''}
          leftTemplate={<LockIcon width="24px" />}
        />
      </FullWidthInputContainer>
    </>
  )
})

export default ChangePasswordInputList

const FullWidthInputContainer = styled('div')(
  () => css`
    width: 100%;
    flex-grow: 1;
  `
)
