import { Button } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router'

import { ButtonContainer, ContentContainer, ContentSection, PostiPage, PostiPageFooter, TopNav } from '../components'
import { ResponsiveBody } from '../components/typography/ResponsiveBody'
import { ResponsiveHeadline } from '../components/typography/ResponsiveHeadline'
import Localization, { Locale } from '../locales'
import { InspectingDocSvg } from './registration/registration.styles'

const AccountIncompleteViewComponent: React.FC = observer(({}) => {
  const { t } = useTranslation()
  const lang: Locale = Localization.CurrentLocale as Locale

  const onContinue = () => {
    window.location.href = `/forgot?lang=${lang}`
  }

  return (
    <>
      <TopNav hasShadow={false} hideLogout={true} showCookieIcon />
      <PostiPage>
        <ContentContainer>
          <InspectingDocSvg />
          <ResponsiveHeadline as="h1">{t('registration.incomplete.title')}</ResponsiveHeadline>
          <ContentSection>
            <ResponsiveBody>{t('registration.incomplete.text')}</ResponsiveBody>
          </ContentSection>
          <ButtonContainer position="end">
            <Button onClick={onContinue} hasBackground>
              {t('registration.accountAlreadyExists.buttonLoginWithEmail')}
            </Button>
          </ButtonContainer>
          <PostiPageFooter />
        </ContentContainer>
      </PostiPage>
    </>
  )
})

export const AccountIncompleteView = withRouter(AccountIncompleteViewComponent)
