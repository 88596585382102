import { action, makeObservable, observable } from 'mobx'

import { IMainViewState } from './dataModels/interfaces'

// TODO this store should be removed and affected state moved inside components. It adds unnecessary complexity an dependencies.
export class MainViewStore {
  constructor() {
    makeObservable(this, {
      state: observable,
      setState: action,
      reset: action,
    })

    this.state = IMainViewState.Info
  }

  state: IMainViewState

  setState = (state: IMainViewState) => {
    this.state = state
  }

  reset = () => {
    this.state = IMainViewState.Info
  }
}

export const mainViewStore = new MainViewStore()
