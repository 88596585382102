import styled, { css } from 'styled-components'

import { topNavHeight } from '../../TopNav/TopNav'
import { postiPageFooterHeight } from '../PostiPageFooter'

export const LoadingContainer = styled.div(
  () => css`
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - ${topNavHeight}px - ${postiPageFooterHeight}px);
    height: 100%;
  `
)
