import { MenuItem } from '@postidigital/posti-components'
import { Label } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import styled, { css } from 'styled-components'

import { StoreContext } from '../../store'
import { breakpoint } from '../../utils'
import { ServiceList } from '../ServiceList'
import { getExampleSideMenuStructure, SideMenuPropsMainItem } from './Structure'

export const StyledUl = styled.ul(
  () => css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 0;
    padding: 0;
  `
)

interface SideMenuProps {
  structure: SideMenuPropsMainItem[]
}

const SideMenu: React.FC<SideMenuProps> = ({ structure }) => {
  return (
    <StyledUl role="menu">
      {structure.map((item: SideMenuPropsMainItem) => {
        return (
          <TransparentMenuItem
            icon={item.icon}
            onSelect={() => item.onClick(item.route)}
            value={item.route}
            selected={item.isActive(item.route)}
            key={item.route}
          >
            <Label size="Three">{item.label}</Label>
          </TransparentMenuItem>
        )
      })}
    </StyledUl>
  )
}

type Props = RouteComponentProps

const SideNavComponent: React.FC<Props> = ({ location, history }) => {
  const { t } = useTranslation()
  const { layoutStore, infoStore, mainViewStore, userStore } = useContext(StoreContext)

  if (!infoStore.isLoading && layoutStore.isDesktop) {
    const isConsumer = infoStore.isConsumer
    const hasOrgs = userStore.hasOrganizations
    const isStrongAuth = infoStore.isStrongAuth
    const structure = getExampleSideMenuStructure(
      history,
      location,
      t,
      isConsumer,
      hasOrgs,
      isStrongAuth,
      mainViewStore.reset
    ).filter((item) => !item.mobileOnly)

    return (
      <StyledSideNav>
        <SideMenu structure={structure} />

        <ServiceList />
      </StyledSideNav>
    )
  }
  return null
}

export const SideNav = withRouter(observer(SideNavComponent))

const StyledSideNav = styled.div`
  padding: 24px;
  min-height: calc(100vh - 80px);
  height: 100%;
  width: 100%;
  max-width: 22rem;

  @media ${breakpoint.mobile} {
    display: none;
  }
`

const TransparentMenuItem = styled(MenuItem)(
  ({
    selected,
    theme: {
      brand: { color },
    },
  }) => css`
    ${!selected &&
    css`
      & > div {
        background: transparent;
      }
    `}
    ${selected &&
    css`
      & > div,
      & > div:hover {
        background: ${color.gray20};
      }
    `}
    & > div:focus {
      box-shadow: ${color.postiOrange60} 0 0 7px;
    }

    svg {
      margin-right: 8px;
    }
  `
)
