import { MultipleUsersIcon, ProfileIcon } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'

import { ContentSection, MinYSpacingWrapper, PostiPage, PostiPageFooter, TopNav, ViewLoading } from '../../components'
import { StaticInputLayout } from '../../components/StaticInputLayout'
import { ResponsiveBody } from '../../components/typography/ResponsiveBody'
import { ResponsiveHeadline } from '../../components/typography/ResponsiveHeadline'
import { useCountdown } from '../../hooks/useCountdown'
import { serviceAdapter } from '../../service/serviceAdapter'
import { StoreContext } from '../../store'
import { TextContainer } from '../registration/registration.styles'
import { AccountRecoveryLogoutModal, RecoverPassword } from './'
import { RecoverEmail } from './recoverEmail'

export type EditNameType = 'email' | 'password'
export type RecoveryEditStateType = Record<EditNameType, boolean>

export interface IEditComponentProps {
  editState: boolean
  type: EditNameType
  toggleEditState: (type: EditNameType) => void
}

const AccountRecovery: FC<RouteComponentProps> = ({ history }) => {
  const { t } = useTranslation()
  const { infoStore, userStore } = useContext(StoreContext)
  const [storesFetched, setStoresFetched] = useState(false)
  const [authenticated, setAuthenticated] = useState(true)
  const [editState, setEditState] = useState<RecoveryEditStateType>({ email: false, password: false })
  const name = userStore.identities?.consumerIdentity?.preferredName || userStore.identities?.firstName

  useEffect(() => {
    const fetchData = () => Promise.all([infoStore.fetchUserInfo(true), userStore.fetchIdentities(true)])

    fetchData()
      .catch(() => {
        setAuthenticated(false)
      })
      .finally(() => {
        setStoresFetched(true)
      })
  }, [infoStore, userStore])

  useEffect(() => {
    if (storesFetched && !authenticated) {
      history.push('/')
    }
  }, [authenticated, history, storesFetched, userStore])

  const { data, reset } = useCountdown({ time: 5, onFinish: serviceAdapter.handleLogOut })

  const toggleEditState: IEditComponentProps['toggleEditState'] = useCallback(
    (type) => {
      reset()

      setEditState((prevState) => ({
        ...prevState,
        [type]: !prevState[type],
      }))
    },
    [reset]
  )

  if (!storesFetched) {
    return <ViewLoading />
  }

  return (
    <>
      <TopNav hideNavigation />
      <PostiPage>
        <MinYSpacingWrapper>
          <TextContainer>
            <ContentSection top={false}>
              <ResponsiveHeadline as="h1">{t('accountRecovery.reset.title')}</ResponsiveHeadline>
              <ResponsiveBody>
                <strong>
                  {t('accountRecovery.reset.subTitle', {
                    name: name ? ` ${name}` : '',
                  })}
                </strong>
              </ResponsiveBody>
              <ResponsiveBody>{t('accountRecovery.reset.content0')}</ResponsiveBody>
              <ResponsiveBody>
                <MultipleUsersIcon height="19px" width="19px" style={{ marginRight: '16px' }} />
                {t('accountRecovery.reset.content1')}
              </ResponsiveBody>
              <StaticInputLayout
                label={t('mainView.name')}
                content={`${userStore.identities.firstName} ${userStore.identities.lastName}`}
                Icon={ProfileIcon}
              />
              <RecoverEmail type="email" toggleEditState={toggleEditState} editState={editState.email} />
              <RecoverPassword type="password" toggleEditState={toggleEditState} editState={editState.password} />
            </ContentSection>
            <PostiPageFooter showCookiePreferences />
          </TextContainer>
        </MinYSpacingWrapper>
      </PostiPage>
      <AccountRecoveryLogoutModal countDownData={data} reset={reset} logout={serviceAdapter.handleLogOut} />
    </>
  )
}

export default withRouter(observer(AccountRecovery))
