import { Tooltip } from '@postidigital/posti-components'
import { CogIcon, IconButton } from '@postidigital/posti-components/build/brand'
import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ResponsiveLink } from '../typography/ResponsiveLink'

const CookieLink: FC<{ idString: string; iconOnly?: boolean; color?: string }> = ({
  idString,
  iconOnly = false,
  color,
}) => {
  const { t } = useTranslation()
  const cookieId = idString + '_cookie-preferences'

  const toggleCookieModal = useCallback(() => {
    // @ts-ignore
    window.OneTrust.ToggleInfoDisplay()
  }, [])

  if (iconOnly) {
    return (
      <Tooltip title={t('footer.cookiePreferences')} id={cookieId} placement="bottom-end">
        <IconButton
          icon={CogIcon}
          id={cookieId}
          key={cookieId}
          tabIndex={0}
          onClick={toggleCookieModal}
          aria-label={t('footer.cookiePreferences')}
        />
      </Tooltip>
    )
  }

  return (
    <ResponsiveLink icon={CogIcon} id={cookieId} key={cookieId} tabIndex={0} onClick={toggleCookieModal} color={color}>
      {t('footer.cookiePreferences')}
    </ResponsiveLink>
  )
}

export default CookieLink
