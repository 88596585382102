import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  errorCount: number
}

// Note: Finnish language does not work here, but will always return just errorTitle: "Tarkista vielä:". This is intended.
export const FormErrorsTitleComponent: React.FC<Props> = ({ errorCount }) => {
  const { t } = useTranslation()

  const getErrorNumeral = (numErrors: number) => {
    switch (numErrors) {
      case 1:
        return t(`invite.register.one`)
      case 2:
        return t(`invite.register.two`)
      case 3:
        return t(`invite.register.three`)
      case 4:
        return t(`invite.register.four`)
      default:
        return ''
    }
  }

  return (
    <div>
      {t(`invite.register.errorTitle`, {
        is: errorCount > 1 ? t(`invite.register.isPlural`) : t(`invite.register.isSingular`),
        numeral: getErrorNumeral(errorCount),
        problem: errorCount > 1 ? t(`invite.register.problemPlural`) : t(`invite.register.problemSingular`),
      })}
    </div>
  )
}
