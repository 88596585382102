import { Loading } from '@postidigital/posti-components'
import { Body, BodySize } from '@postidigital/posti-components/build/brand'
import { sendPageInfo } from '@postidigital/posti-google-analytics'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  AcceptInviteDialog,
  ContentContainer,
  DoneInfo,
  InviteError,
  PostiPage,
  PostiPageFooter,
  RegisterAccountForm,
  TopNav,
} from '../components'
import { ResponsiveHeadline } from '../components/typography/ResponsiveHeadline'
import Localization from '../locales'
import AcceptStore, { IAcceptState } from '../store/views/acceptStore'

const AcceptInviteViewComponent: React.FC = () => {
  const { t } = useTranslation()
  const store = useContext(AcceptStore)

  useEffect(() => {
    store.setCode(location.search.substr(location.search.indexOf('code=') + 5))
    const loadInvite = async () => {
      const inviteRequiresAccepting = await store.loadInvite()
      if (inviteRequiresAccepting) {
        sendPageInfo({
          language: Localization.CurrentLocale,
          solution: 'oneaccount-onboarding',
          domain: window.location.hostname,
          pagePath: '/acceptinvite',
          pageName: 'oneaccount-onboarding',
        })
      }
    }
    loadInvite()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getContinuation = () => {
    if (store.invite.hasPrimaryService) {
      // Null check in case the invite doesn't have returnurl.
      if (store.invite.primaryReturnUrl !== null && store.invite.primaryReturnUrl !== '') {
        return [store.invite.primaryReturnUrl, t('invite.done.omapostiproButton'), 'OmaPosti login']
      }
      const links = window['postienv']?.acceptContinuationLinks
      return [links.omapostipro[Localization.CurrentLocale], t('invite.done.omapostiproButton'), 'OmaPosti login']
    }
    if (store.invite.hasSecondaryService) {
      const links = window['postienv']?.acceptContinuationLinks
      return [links.smartship[Localization.CurrentLocale], t('invite.done.smartshipButton'), 'Smartship login']
    }
    return ['/', t('invite.done.myaccountButton'), 'Myaccount login']
  }

  const getContent = () => {
    switch (store.state) {
      case IAcceptState.Loading:
        return (
          <StyledLoading>
            <Loading isBrand preset={'postiOrange60'} size="xl" statusText={t('invite.loading')} />
            <StyledBody>{t('invite.loading')}</StyledBody>
          </StyledLoading>
        )
      case IAcceptState.Accepting:
        return <AcceptInviteDialog />
      case IAcceptState.Registering:
        return <RegisterAccountForm />
      case IAcceptState.Registered: {
        const continuation = getContinuation()
        return (
          <DoneInfo
            created={true}
            firstName={store.firstName}
            href={continuation[0]}
            buttonText={continuation[1]}
            continuationName={continuation[2]}
          />
        )
      }
      case IAcceptState.PreviouslyAccepted:
      case IAcceptState.Accepted: {
        const continuation = getContinuation()
        return (
          <DoneInfo
            created={false}
            href={continuation[0]}
            buttonText={continuation[1]}
            continuationName={continuation[2]}
          />
        )
      }
      case IAcceptState.Invalid:
        return <InviteError invalidInvite={true} />
      case IAcceptState.ErrorOccurred:
        return <InviteError invalidInvite={false} />
    }
  }

  return (
    <>
      <TopNav hideNavigation={true} hasShadow={false} fullWidth={true} hideLogout showCookieIcon />
      <PostiPage>
        <ContentContainer>
          {(store.state == IAcceptState.Accepting || store.state == IAcceptState.Registering) && (
            <ResponsiveHeadline as="h1">
              {store.state == IAcceptState.Accepting &&
                t('invite.acceptTitle', { orgName: store.invite.organizationName })}
              {store.state == IAcceptState.Registering &&
                t('invite.registerTitle', { orgName: store.invite.organizationName })}
            </ResponsiveHeadline>
          )}
          {getContent()}
          <PostiPageFooter
            titleTranslationKey="invite.footer.title"
            customerServiceLink="invite.footer.path"
            customerServiceLabel="invite.footer.label"
          />
        </ContentContainer>
      </PostiPage>
    </>
  )
}

export const AcceptInviteView = observer(AcceptInviteViewComponent)

const StyledLoading = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`

const StyledBody = styled(Body).attrs({ size: BodySize.Three })`
  margin-top: 20px;
`
