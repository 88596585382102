import { phoneNumberValidator } from '../../../service/phoneNumberValidator'

export const validatePhoneNumber = (value: string) => {
  if (!value) {
    return false
  }
  const normalized = phoneNumberValidator.normalize(value, false)
  return phoneNumberValidator.isValidFinnishMobileNumber(normalized)
}

export const validateEmail = (value: string) => {
  if (!value || value.length > 254) {
    return false
  }
  const re = /^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/g
  const regex = RegExp(re)
  return regex.test(value)
}

/**
 * Validates a password string based on the following criteria:
 * - Must contain at least one special character (non-alphanumeric).
 * - Must contain at least one digit (0-9).
 * - Must contain at least one lowercase letter (a-z).
 * - Must contain at least one uppercase letter (A-Z).
 * - Must be between 8 and 254 characters in length.
 *
 * @param {string} value - The password string to be validated.
 * @returns {boolean} Returns true if the password meets the specified criteria, otherwise false.
 */
export const validatePassword = (value: string) => {
  const regExp = /^(?=.*[^a-zA-Z0-9])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,254}$/
  return regExp.test(value)
}

export const maxLength = (value: string, maxLen: number) => {
  return !value || value.length <= maxLen
}

export const length = (value: string, len: number) => {
  return value?.length === len
}

const DIGITS_REGEX = /^\d+$/

export const onlyDigits = (value: string) => {
  return DIGITS_REGEX.test(value) || value === ''
}

const NO_WHITE_SPACE_REGEX = /^\S*$/

export const noWhiteSpace = (value: string) => {
  return NO_WHITE_SPACE_REGEX.test(value)
}
