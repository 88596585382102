import { LinkProps } from '@postidigital/posti-components/build/basic-components/Link/Link.types'
import { Link } from '@postidigital/posti-components/build/brand'
import { BrandTheme } from '@postidigital/posti-theme'
import { observer } from 'mobx-react-lite'
import React, { ComponentPropsWithRef, FC } from 'react'
import styled, { css, useTheme } from 'styled-components'

import { layoutStore } from '../../store'

type ResponsiveLinkComponentProps = Omit<LinkProps, 'size'> & {
  size?: LinkProps['size']
} & ComponentPropsWithRef<typeof Link>

const ResponsiveLinkComponent: FC<ResponsiveLinkComponentProps> = ({ children, ...linkProps }) => {
  const {
    brand: { color },
  } = useTheme()
  const size = linkProps.size || (!layoutStore.isDesktop ? 'xs' : 'md')
  const linkColor = linkProps.color || linkProps.style?.color || color.postiOrange70

  return (
    <StyledLink
      {...linkProps}
      size={size}
      iconColor={linkColor}
      style={{ margin: 0, padding: 0, fontWeight: BrandTheme.fontWeight.bold, ...linkProps.style, color: linkColor }}
    >
      {children}
    </StyledLink>
  )
}

const StyledLink = styled(Link)<{ iconColor: string }>(
  ({ iconColor }) => css`
    & > svg > path {
      ${iconColor &&
      css`
        // Lack of handles for specificity
        stroke: ${iconColor} !important;
      `}
    }
  `
)

export const ResponsiveLink = observer(ResponsiveLinkComponent)
