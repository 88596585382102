import { ExternalLinkIcon } from '@postidigital/posti-components/build/brand'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Localization from '../../locales'
import { IConsumerContract, ILocalizedNameModel } from '../../store/dataModels/interfaces'
import { ContentSection } from '../layout'
import { ResponsiveBody } from '../typography/ResponsiveBody'
import { ResponsiveHeadline } from '../typography/ResponsiveHeadline'
import { ResponsiveLink } from '../typography/ResponsiveLink'

interface ServiceContractListProps {
  items: IConsumerContract[]
}

export const ServiceContractList: React.FC<ServiceContractListProps> = ({ items }) => {
  return (
    <>
      <ContractListContainer>
        {items.map((item) => {
          return <ContractListItem key={item.key} item={item} />
        })}
      </ContractListContainer>
    </>
  )
}

const ContractListItem: React.FC<{ item: IConsumerContract }> = ({ item }) => {
  const { t } = useTranslation()

  const formatDate = function (value: string | Date) {
    if (!value) {
      return ''
    }
    let date = value instanceof Date ? value : new Date(value)
    return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
  }

  return (
    <ContentSection top={false} gap="lg">
      <ResponsiveHeadline>{t(`detailsView.serviceContractsTitle`)}</ResponsiveHeadline>
      <div>
        <ContractTitle>
          {item.links[Localization.CurrentLocale as ILocalizedNameModel] ? (
            <ResponsiveLink
              icon={ExternalLinkIcon}
              target="_blank"
              href={item.links[Localization.CurrentLocale as ILocalizedNameModel]}
            >
              {item.titles[Localization.CurrentLocale as ILocalizedNameModel]}
            </ResponsiveLink>
          ) : (
            item.titles[Localization.CurrentLocale as ILocalizedNameModel]
          )}
        </ContractTitle>

        {item.key == 'ONE_AGREEMENT' && (
          <ContractTitle>
            <ResponsiveLink
              icon={ExternalLinkIcon}
              target="_blank"
              href={window['postienv']?.rekisteriseloste_links[Localization.CurrentLocale]}
            >
              {t(`detailsView.rekisteriseloste`)}
            </ResponsiveLink>
          </ContractTitle>
        )}

        {item.acceptDate && (
          <ResponsiveBody>
            {t(`detailsView.contractAccepted`)} {formatDate(item.acceptDate)}
          </ResponsiveBody>
        )}
      </div>
    </ContentSection>
  )
}

const ContractListContainer = styled.ul`
  padding: 0;
  margin: 0;
`

const ContractTitle = styled.div`
  width: 100%;

  & > a {
    display: inline-flex;
  }
`
