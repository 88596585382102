import {
  ArrowLeftIcon,
  Body,
  CheckmarkCircledIcon,
  Headline,
  Notification,
} from '@postidigital/posti-components/build/brand'
import { BrandTheme } from '@postidigital/posti-theme'
import { observer } from 'mobx-react-lite'
import React, { PropsWithChildren, useCallback, useContext, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import {
  BackButton,
  ButtonContainer,
  ContentContainer,
  ContentSection,
  NextButton,
  PostiPage,
  PostiPageFooter,
  TopNav,
} from '../../components'
import { ResponsiveBody } from '../../components/typography/ResponsiveBody'
import { ResponsiveHeadline } from '../../components/typography/ResponsiveHeadline'
import { ResponsiveLink } from '../../components/typography/ResponsiveLink'
import { FeatureFlags } from '../../featureFlags'
import BusinessIcon from '../../static/img/business_profile_icon.svg'
import ConsumerIcon from '../../static/img/consumer_profile_icon.svg'
import { AccountTerminationTypeEnum, StoreContext } from '../../store'

interface TerminateAccountTypeSelectionViewProps {
  onBackClick: () => void
  onNextClick: () => void
}

const TerminateAccountTypeSelectionViewComponent: React.FC<TerminateAccountTypeSelectionViewProps> = (props) => {
  const { t } = useTranslation()
  const { terminateAccountViewStore: store } = useContext(StoreContext)

  const warningRef = useRef<HTMLDivElement | null>(null)
  const [isAccountTypeRequiredVisible, setAccountTypeRequiredVisible] = useState<boolean>(false)

  const consumerOptionVisible =
    store.isConsumerUser && !store.isConsumerTerminationPending && FeatureFlags.EnableAccountTerminationForConsumers
  const consumerOptionDisabled = store.isBusinessUser && !FeatureFlags.AllowConsumerAccountTerminationForBusinessUsers
  const consumerOptionWarning = consumerOptionDisabled
    ? t('termination.selection.consumer.b2b_needs_to_be_terminated_first')
    : ''
  const b2bOptionVisible = store.isBusinessUser && FeatureFlags.EnableAccountTerminationForBusinessUsers
  const translationSuffix =
    consumerOptionVisible && b2bOptionVisible ? 'both' : consumerOptionVisible ? 'consumer' : 'business'

  const onNextClick = useCallback(() => {
    if (store.terminationType !== AccountTerminationTypeEnum.NotDefined) {
      return props.onNextClick()
    }

    setAccountTypeRequiredVisible(true)

    if (warningRef?.current) {
      warningRef.current.focus()
    }
  }, [props, store.terminationType])

  const onAccountTypeSelectionChange = useCallback(
    (value: AccountTerminationTypeEnum) => {
      if (isAccountTypeRequiredVisible) {
        setAccountTypeRequiredVisible(false)
      }
      store.terminationType = value
    },
    [isAccountTypeRequiredVisible, store]
  )

  const renderConsumerAccountCard = useCallback(() => {
    if (!consumerOptionVisible) {
      return null
    }

    return (
      <AccountCard
        id="consumer"
        selected={store.terminationType == AccountTerminationTypeEnum.ConsumerAccountTermination}
        onClick={() => {
          onAccountTypeSelectionChange(AccountTerminationTypeEnum.ConsumerAccountTermination)
        }}
        disabled={consumerOptionDisabled}
      >
        <ConsumerIcon aria-hidden={true} />
        <Headline color="currentcolor" size="Three" as="h2">
          {t('termination.selection.consumer.title')}
        </Headline>
        <Body size="Five">{t('termination.selection.consumer.description')}</Body>
        {consumerOptionWarning && <OptionCardWarning>{consumerOptionWarning}</OptionCardWarning>}
      </AccountCard>
    )
  }, [
    consumerOptionDisabled,
    consumerOptionVisible,
    consumerOptionWarning,
    onAccountTypeSelectionChange,
    store.terminationType,
    t,
  ])

  const renderBusinessAccountCard = useCallback(() => {
    if (!b2bOptionVisible) {
      return null
    }

    return (
      <AccountCard
        id="business"
        selected={store.terminationType == AccountTerminationTypeEnum.BusinessAccountTermination}
        onClick={() => {
          onAccountTypeSelectionChange(AccountTerminationTypeEnum.BusinessAccountTermination)
        }}
      >
        <BusinessIcon aria-hidden={true} />
        <Headline color="currentcolor" size="Three" as="h2">
          {t('termination.selection.business.title')}
        </Headline>
        <ul id="userOrganizationsList">
          {store.userOrganizations.map((o) => (
            <li key={`org_id_${o.orgId}`}>
              <Body size="Five">
                {o.orgName} ({o.orgId})
              </Body>
            </li>
          ))}
        </ul>
        {store.shouldShowAdminWarning && (
          <LastAdminWarning organizations={store.userOrganizations.filter((o) => o.isLastMainUser)} />
        )}
      </AccountCard>
    )
  }, [
    b2bOptionVisible,
    onAccountTypeSelectionChange,
    store.shouldShowAdminWarning,
    store.terminationType,
    store.userOrganizations,
    t,
  ])

  const allowedTransComponents: any = { p: <p />, italic: <i />, strong: <strong /> }

  return (
    <>
      <TopNav hideNavigation={true} hasShadow={false} fullWidth={true} showCookieIcon />
      <PostiPage>
        <ContentContainer>
          <ResponsiveLink size="lg" icon={ArrowLeftIcon} iconPosition="left" href="/">
            {t('termination.selection.backlink')}
          </ResponsiveLink>
          <ContentSection>
            <ResponsiveHeadline as="h1">{t(`termination.selection.title_${translationSuffix}`)}</ResponsiveHeadline>
          </ContentSection>
          <ContentSection>
            <ResponsiveBody as="div">
              <Trans i18nKey={`termination.selection.intro_${translationSuffix}`} components={allowedTransComponents} />
            </ResponsiveBody>

            {isAccountTypeRequiredVisible && (
              <div ref={warningRef}>
                <Notification contentType="alert" tabIndex={-1} dismissible={false}>
                  {t('termination.selection.error_accountTypeRequired')}
                </Notification>
              </div>
            )}

            <AccountSelector>
              {renderConsumerAccountCard()}
              {renderBusinessAccountCard()}
            </AccountSelector>
          </ContentSection>
          <ButtonContainer position="space-between">
            <BackButton id="btnBack" onClick={props?.onBackClick}>
              {t('termination.selection.back')}
            </BackButton>
            <NextButton id="btnNext" onClick={onNextClick}>
              {t('termination.selection.next')}
            </NextButton>
          </ButtonContainer>
          <PostiPageFooter />
        </ContentContainer>
      </PostiPage>
    </>
  )
}

const AccountSelector = styled.div``

const OptionCardWarning: React.FC<PropsWithChildren<{ className?: string; id?: string }>> = (props) => {
  const id = props.id ? props.id : 'cardwarning'

  return (
    <Notification id={id} contentType="warning" dismissible={false}>
      <>{props.children}</>
    </Notification>
  )
}

interface LastAdminWarningProps {
  className?: string
  organizations: { orgId: string; orgName: string; isLastMainUser: boolean }[]
}

const LastAdminWarning: React.FC<LastAdminWarningProps> = (props) => {
  const { t, i18n } = useTranslation()
  const orgAdminUrl = window['postienv']?.orgAdminUrl.replace('{language}', i18n.language)

  const organizationNames = props.organizations
    .map((o) => o.orgName)
    .reduce((acc, current, index, arr) => {
      if (acc == '') {
        return current
      }
      return index < arr.length - 1
        ? `${acc}, ${current}`
        : `${acc} ${t('termination.selection.business.and')} ${current}`
    }, '')

  const allowedTransComponents: any = { p: <p />, italic: <i />, strong: <strong /> }

  return (
    <Notification dismissible={false}>
      <>
        <Trans
          i18nKey="termination.selection.business.admin_warning"
          values={{ organizationNames: organizationNames }}
          components={allowedTransComponents}
        />
        <a href={orgAdminUrl}>{t('termination.selection.business.admin_warning_link')}</a>
      </>
    </Notification>
  )
}

interface AccountCardProps {
  id: string
  className?: string
  selected: boolean
  disabled?: boolean
  onClick?: (id: string) => void
}

const AccountCardComponent: React.FC<PropsWithChildren<AccountCardProps>> = (props) => {
  const handleCardClick = (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    if (props.disabled) {
      return
    }
    if (!(e instanceof KeyboardEvent) || e.key == ' ' || e.key == 'Enter') {
      props.onClick && props.onClick(props.id)
    }
  }

  let wrapperProps: { [k: string]: any } = {}
  if (props.selected) {
    wrapperProps['data-selected'] = true
  }
  if (props.disabled) {
    wrapperProps['data-disabled'] = true
  }
  if (props.disabled) {
    wrapperProps['aria-disabled'] = true
  }
  if (!props.disabled) {
    wrapperProps['tabIndex'] = 0
  }

  return (
    <div
      className={props.className}
      id={`${props.id}_container`}
      onClick={handleCardClick}
      onKeyDown={handleCardClick}
      role="radio"
      aria-checked={props.selected}
      {...wrapperProps}
    >
      {props.children}
      {props.selected && (
        <AccountCardCheckMarkContainer>
          <CheckmarkCircledIcon color={BrandTheme.color.postiOrange50} width="32px" height="32px" aria-hidden={true} />
        </AccountCardCheckMarkContainer>
      )}
    </div>
  )
}

const AccountCardCheckMarkContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  width: 32px;
  height: 32px;

  & > svg {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
`

const AccountCard = styled(AccountCardComponent)(
  ({
    theme: {
      brand: { color },
    },
  }) => css`
    box-sizing: border-box;
    position: relative;
    min-height: 144px;

    background-color: white;
    border-radius: 8px;
    outline-style: none;
    border: solid 3px transparent;

    box-shadow: 0 15px 22px rgba(0, 0, 0, 0.06), 0 3px 8px rgba(0, 0, 0, 0.08), 0 1px 1px rgba(0, 0, 0, 0.08),
      0 3px 1px rgba(0, 0, 0, 0.04);

    margin-top: 24px;
    padding: 24px 24px 24px 64px;

    font-size: 13px;
    cursor: pointer;

    &[data-disabled] {
      border: solid 3px ${color.gray1};
      background-color: ${color.gray1};
      color: ${color.gray50};
      cursor: default;
    }

    &[data-disabled] > svg path {
      fill: ${color.gray50};
    }

    &:not([data-disabled]):hover {
      background-color: #eaedf1;
    }

    &:not([data-disabled]):hover #lastAdminWarning {
      background-color: #e7e3dd;
    }

    &[data-selected] {
      border: solid 3px ${color.postiOrange50};
    }

    &:focus {
      box-shadow: ${color.postiOrange60} 0 0 7px;
    }

    & h2 {
      font-size: 18px;
      margin: 0 0 8px 0;
    }

    // Don't let header overlap with checkmark icon
    & > h1,
    & > h2,
    & > h3 {
      margin-right: 32px;
    }

    // Overwrite svg position & color
    & > svg {
      position: absolute;
      top: 24px;
      left: 24px;
    }

    & > svg path {
      fill: ${color.gray70};
    }

    & #userOrganizationsList {
      padding: 0;
      margin: 0;
      list-style: none;
    }
  `
)

export const TerminateAccountTypeSelectionView = observer(TerminateAccountTypeSelectionViewComponent)
