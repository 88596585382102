import React, { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'

interface IPinCodeInputProps {
  id?: string
  size?: number
  groupSize?: number
  value?: string
  lightBackground?: boolean
  disabled?: boolean

  onChange?: (value: string) => void
  onAutoSubmit?: (value: string) => void
}

export const PinCodeInput: React.FC<IPinCodeInputProps> = ({
  id = '',
  size = 6,
  value,
  groupSize = 3,
  lightBackground = false,
  disabled = false,
  onChange,
  onAutoSubmit,
}) => {
  const [code, setCode] = useState<string>(value == null ? '' : value)

  // Always use passed in value insted of state if present
  let newCode = value != null ? value : code

  const getPinDigit = (pos: number): string => {
    return newCode.substr(pos, 1)
  }

  const onDigitChange = (pos: number, digitValue: string) => {
    if (digitValue === '' || /^[0-9]+$/.test(digitValue)) {
      newCode = newCode.substr(0, pos) + digitValue
      if (newCode.length > size) {
        newCode = newCode.substr(0, size)
      }
      setCode(newCode)

      const nextPos = newCode.length + (digitValue ? 0 : -1)
      if (nextPos >= 0 && nextPos < size) {
        inputRefs[nextPos].current.focus()
      }

      if (onChange) {
        onChange(newCode)
      }
      if (onAutoSubmit && newCode.length === size) {
        onAutoSubmit(newCode)
      }
    }
  }

  const onDigitFocus = (pos: number) => {
    if (pos > newCode.length) {
      const nextPos = newCode.length
      if (nextPos >= 0 && nextPos < size) {
        inputRefs[nextPos].current.focus()
      }
    }
  }

  const inputs = []
  const inputRefs: any = []
  for (let i = 0; i < size; i++) {
    const digitId = id + '_' + i
    const ref = React.createRef<HTMLInputElement>()
    inputRefs.push(ref)
    inputs.push(
      <PinCodeDigit
        key={digitId}
        id={digitId}
        ref={ref}
        lightBackground={lightBackground}
        disabled={disabled}
        groupSize={groupSize}
        autoFocus={i == 0}
        value={getPinDigit(i)}
        onChange={(digit) => onDigitChange(i, digit)}
        onFocus={() => onDigitFocus(i)}
      />
    )
  }

  return <PinCodeInputContainer id={id}>{inputs}</PinCodeInputContainer>
}

interface IPinCodeDigitProps {
  id: string
  value: string
  groupSize: number
  lightBackground?: boolean
  disabled?: boolean
  autoFocus?: boolean
  onChange: (value: string) => void
  onFocus: () => void
}

const PinCodeDigit = React.forwardRef<HTMLInputElement, IPinCodeDigitProps>(
  ({ id, value, groupSize, lightBackground = false, disabled = false, autoFocus = false, onChange, onFocus }, ref) => {
    const handleInput = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        let newValue = e.target.value
        if (value !== '' && value !== newValue && newValue.startsWith(value)) {
          newValue = newValue.substr(value.length)
        }
        onChange(newValue)
      },
      [onChange, value]
    )

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        const BACKSPACE = 'Backspace'
        const DELETE = 'Delete'
        const LEFT = 'ArrowLeft'

        if ([BACKSPACE, DELETE, LEFT].includes(e.key)) {
          e.preventDefault()
          onChange('')
        }

        if (/[0-9]/.test(e.key)) {
          e.preventDefault()
          onChange(e.key)
        }
      },
      [onChange]
    )

    return (
      <StyledDigitInput
        type="number"
        groupSize={groupSize}
        lightBackground={lightBackground}
        id={id}
        name={id}
        autoFocus={autoFocus}
        size={1}
        ref={ref}
        value={value}
        disabled={disabled}
        onChange={handleInput}
        onKeyDown={handleKeyDown}
        onFocus={onFocus}
      />
    )
  }
)

const PinCodeInputContainer = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
`

const StyledDigitInput = styled.input<{ groupSize: number; lightBackground: boolean }>(
  ({
    theme: {
      brand: { color },
    },
    groupSize,
  }) => css`
    background: transparent;
    border-style: solid;
    border-color: transparent;
    border-width: 3px;
    border-radius: 4px;
    box-shadow: 0 0 0 1px ${color.gray10};
    text-align: center;
    margin: 0 8px 0 0;
    outline: none;
    min-height: 4rem;
    width: 3rem;
    transition: border-color 0.2s ease-in-out, border-width 0.2s ease-in-out;

    // Input type is number to help mobile users
    // -> Hide the spinner buttons on desktop devices
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:hover:not(:focus) {
      background-color: ${color.white};
    }

    &:focus {
      border-color: ${color.postiOrange50};
    }

    &:last-child {
      margin-right: 0;
    }

    &:nth-child(${groupSize + 'n'}):not(:last-child) {
      margin-right: 24px;
    }
  `
)
