import { Modal } from '@postidigital/posti-components'
import { Button, Input, Notification, PhoneIcon } from '@postidigital/posti-components/build/brand'
import { sendUserAction } from '@postidigital/posti-google-analytics'
import React, { FormEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ContentSection } from '../../../components'
import { ResponsiveBody } from '../../../components/typography/ResponsiveBody'
import Localization, { Locale } from '../../../locales'
import { registrationStore } from '../../../store'
import { ButtonContainer } from '../components/RegistrationModal.styles'
import { defaultInputIconProps, ModalInputMinHeightContainer } from '../registration.styles'
import { sendConsumerRegistrationErrorUserAction } from '../RegistrationMainView'
import { getFormItemMessage, useFormFields } from '../utils/useFormFields'
import { validatePhoneNumber } from '../utils/validators'

type EditPhoneFieldNames = 'phoneNumber'

interface EditPhoneComponentProps {
  onCancel: () => void
}

export const EditPhoneComponent: React.FC<EditPhoneComponentProps> = ({ onCancel }) => {
  const { t } = useTranslation()
  const [formFields, handleFieldChange] = useFormFields<EditPhoneFieldNames>({
    phoneNumber: {
      value: '',
      validator: validatePhoneNumber,
      isInvalid: false,
      touched: false,
      ref: useRef(),
      labelTranslationKey: 'registration.create.enterPhone',
      invalidMessageTranslationKey: 'registration.create.validation.enterPhone',
    },
  })
  const [error, setError] = useState(false)
  const lang: Locale = Localization.CurrentLocale as Locale

  // Send error codes to analytics
  useEffect(() => {
    if (error) {
      sendConsumerRegistrationErrorUserAction('editPhoneError')
    }
  }, [error])

  const isFormValid = useCallback(
    () => !Object.keys(formFields).some((fieldName) => formFields[fieldName as EditPhoneFieldNames].isInvalid),
    [formFields]
  )

  const handleConfirm: FormEventHandler<HTMLFormElement> = useCallback(
    async (event) => {
      event.preventDefault()
      event.nativeEvent.stopImmediatePropagation()

      if (registrationStore.processingRequest) {
        return
      }
      setError(false)
      handleFieldChange('validateAll')
      if (isFormValid()) {
        // reloadInfo=true will cause re-render of the whole view so no other action needed after calling registrationStore.changePhoneNumber()
        registrationStore
          .changePhoneNumber({ phone: formFields.phoneNumber.value, language: lang }, true)
          .then(() => {
            sendUserAction({
              actionType: 'changeTelNumber',
              stage: 'complete',
            })
          })
          .catch(() => {
            setError(true)
          })
      } else {
        setTimeout(() => {
          formFields.phoneNumber.ref?.current.focus()
        }, 0)
      }
    },
    [formFields.phoneNumber.ref, formFields.phoneNumber.value, handleFieldChange, isFormValid, lang]
  )

  const handleCancel = useCallback(() => {
    onCancel()
  }, [onCancel])

  return (
    <Modal.MainContent>
      <form id="phone_number_reset_modal_form" onSubmit={handleConfirm}>
        <ContentSection top={false}>
          {error && (
            <Notification id="notification_container" contentType="alert" dismissible={false}>
              {t('registration.verifyPhone.errorMessages.sendNewCodeError')}
            </Notification>
          )}
          <ResponsiveBody>{t('registration.verifyPhone.reEnterPhoneModal.instruction')}</ResponsiveBody>
          <ModalInputMinHeightContainer>
            <Input
              name="phoneNumber"
              type="tel"
              onChange={handleFieldChange}
              onBlur={handleFieldChange}
              id={'phoneNumber'}
              ref={formFields.phoneNumber.ref}
              value={formFields.phoneNumber.value}
              isInvalid={formFields.phoneNumber.touched && formFields.phoneNumber.isInvalid}
              message={getFormItemMessage(formFields.phoneNumber, t)}
              label={t(formFields.phoneNumber.labelTranslationKey as string)}
              lightBackground
              leftTemplate={<PhoneIcon {...defaultInputIconProps} />}
            />
          </ModalInputMinHeightContainer>
        </ContentSection>

        <Modal.ButtonWrapper>
          <ButtonContainer>
            <Button
              type="button"
              id="button_edit_phone_cancel"
              size="sm"
              contentMode="secondary"
              hasBackground={false}
              onClick={handleCancel}
            >
              {t('general.cancel')}
            </Button>
            <Button
              hasBackground
              type="submit"
              id="button_edit_phone_send_new"
              size="sm"
              isLoading={registrationStore.processingRequest}
            >
              {t('registration.verifyPhone.reEnterPhoneModal.sendCodeButton')}
            </Button>
          </ButtonContainer>
        </Modal.ButtonWrapper>
      </form>
    </Modal.MainContent>
  )
}
