import { Button, Headline, InboxIcon, PlusIcon, PostiLockersIcon } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'

import { ButtonContainer, ContentSection, MinYSpacingWrapper, PostiPageFooter } from '../../components'
import { ResponsiveBody } from '../../components/typography/ResponsiveBody'
import { ResponsiveHeadline } from '../../components/typography/ResponsiveHeadline'
import Localization from '../../locales'
import { StoreContext } from '../../store'
import { IconAndText, IconBulletList, TextContainer } from './registration.styles'

const iconProps = {
  width: '24px',
  height: '24px',
  'aria-hidden': true,
}

interface IProps {
  proceed: () => void
}

type Props = RouteComponentProps & IProps

const BeforeComponent: React.FC<Props> = ({ proceed }) => {
  const { t } = useTranslation()
  const { layoutStore } = useContext(StoreContext)

  return (
    <MinYSpacingWrapper>
      <TextContainer>
        <ResponsiveHeadline as="h1">{t('registration.before.title')}</ResponsiveHeadline>
        <ContentSection>
          <Headline size="Eight">{t('registration.before.hello')}</Headline>
          <ResponsiveBody>{t('registration.before.welcomeToPosti')}</ResponsiveBody>
        </ContentSection>
        <IconBulletList>
          <IconAndText>
            <InboxIcon {...iconProps} />
            <ResponsiveBody>{t('registration.before.bullets.omaPosti')}</ResponsiveBody>
          </IconAndText>
          <IconAndText>
            <PostiLockersIcon {...iconProps} />
            <ResponsiveBody>{t('registration.before.bullets.pickupPoint')}</ResponsiveBody>
          </IconAndText>
        </IconBulletList>
        <ButtonContainer>
          <Button
            size="sm"
            id="button_cancel"
            contentMode="secondary"
            onClick={() => (location.href = `https://posti.fi/${Localization.CurrentLocale}`)}
          >
            {t('general.cancel')}
          </Button>
          <Button
            size="sm"
            id="button_proceed_to_create"
            iconPosition={'left'}
            contentMode="primary"
            onClick={proceed}
            hasBackground
            icon={layoutStore.isDesktop ? PlusIcon : undefined}
          >
            {t('registration.buttons.proceedToCreate')}
          </Button>
        </ButtonContainer>
        <PostiPageFooter showCookiePreferences />
      </TextContainer>
    </MinYSpacingWrapper>
  )
}

export const Before = withRouter(observer(BeforeComponent))
