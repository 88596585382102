import { ArrowLeftIcon, Notification, Radio, Textarea } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { ChangeEventHandler, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import {
  BackButton,
  ButtonContainer,
  ContentContainer,
  ContentSection,
  NextButton,
  PostiPage,
  PostiPageFooter,
  RoundedPageSection,
  TopNav,
} from '../../components'
import { ResponsiveBody } from '../../components/typography/ResponsiveBody'
import { ResponsiveHeadline } from '../../components/typography/ResponsiveHeadline'
import { ResponsiveLink } from '../../components/typography/ResponsiveLink'
import Localization from '../../locales'
import { AccountTerminationFeedbackEnum, StoreContext } from '../../store'

interface TerminateAccountFeedbackViewProps {
  onBackClick: () => void
  onNextClick: () => void
}

const TerminateAccountFeedbackViewComponent: React.FC<TerminateAccountFeedbackViewProps> = ({
  onBackClick,
  onNextClick,
}) => {
  const { terminateAccountViewStore: store } = useContext(StoreContext)
  const { t } = useTranslation()

  const handleBackClick = useCallback(() => {
    store.terminationReasonDetails = ''
    store.terminationReason = null
    onBackClick()
  }, [onBackClick, store])

  const onSelectionChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { value, checked } = event.target
      if (!value || !checked) {
        return null
      }

      store.terminationReason = value as AccountTerminationFeedbackEnum
    },
    [store]
  )

  const renderFeedbackRadio = (key: AccountTerminationFeedbackEnum) => {
    const translationKey: string = key.charAt(0).toLowerCase() + key.slice(1)
    return (
      <Radio
        name="feedbackOption"
        labelPosition="right"
        label={t(`termination.feedback.${translationKey}`)}
        value={key}
        key={`feedback_option_${key}`}
        checked={store.terminationReason == key}
        onChange={onSelectionChange}
      />
    )
  }

  return (
    <>
      <TopNav hideNavigation={true} hasShadow={false} fullWidth={true} showCookieIcon />
      <PostiPage>
        <ContentContainer>
          <ResponsiveLink size="lg" icon={ArrowLeftIcon} iconPosition="left" href="/">
            {t('termination.feedback.backlink')}
          </ResponsiveLink>
          <ContentSection>
            <ResponsiveHeadline as="h1">{t(`termination.feedback.title`)}</ResponsiveHeadline>
            <ResponsiveBody>
              <b>{t(`termination.feedback.subtitle`)}</b>
            </ResponsiveBody>
          </ContentSection>
          <ContentSection>
            <RoundedPageSection>
              {renderFeedbackRadio(AccountTerminationFeedbackEnum.DontKnowHowToUseProduct)}
              {renderFeedbackRadio(AccountTerminationFeedbackEnum.FoundAnotherProduct)}
              {renderFeedbackRadio(AccountTerminationFeedbackEnum.DidNotUseEnough)}
              {renderFeedbackRadio(AccountTerminationFeedbackEnum.MissingFeatures)}
              {renderFeedbackRadio(AccountTerminationFeedbackEnum.LeavingFinland)}
              {renderFeedbackRadio(AccountTerminationFeedbackEnum.PrivacyConsiderations)}
              {renderFeedbackRadio(AccountTerminationFeedbackEnum.Other)}
            </RoundedPageSection>
            {store.terminationReason == AccountTerminationFeedbackEnum.PrivacyConsiderations && (
              <Notification contentType="informational" dismissible={false}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('termination.feedback.privacyInfo', {
                      url: window['postienv']?.marketingpermissionsManagementLinks[Localization.CurrentLocale],
                    }),
                  }}
                />
              </Notification>
            )}
            {(store.terminationReason == AccountTerminationFeedbackEnum.Other ||
              store.terminationReason == AccountTerminationFeedbackEnum.DontKnowHowToUseProduct ||
              store.terminationReason == AccountTerminationFeedbackEnum.MissingFeatures) && (
              <Textarea
                lightBackground
                maxLength={500}
                value={store.terminationReasonDetails}
                onChange={(e) => (store.terminationReasonDetails = e.target.value)}
                style={{
                  resize: 'none',
                }}
                label={t(`termination.feedback.textInput`)}
                id="tellMore"
              />
            )}
          </ContentSection>

          <ButtonContainer position="space-between">
            <BackButton id="btnBack" onClick={handleBackClick}>
              {t(`termination.feedback.back_button`)}
            </BackButton>
            <NextButton id="btnNext" onClick={onNextClick}>
              {t(`termination.feedback.next_button`)}
            </NextButton>
          </ButtonContainer>
          <PostiPageFooter />
        </ContentContainer>
      </PostiPage>
    </>
  )
}

export const TerminateAccountFeedbackView = observer(TerminateAccountFeedbackViewComponent)
