import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  HeaderSubTitle,
  HeaderTitle,
  PageSection,
  PostiPage,
  PostiPageContents,
  PostiPageFooter,
  PostiPageHeader,
  TopNav,
  ViewLoading,
} from '../../components'

export const TerminateAccountProcessingView: React.FC = () => {
  const { t } = useTranslation()
  return (
    <>
      <TopNav hideNavigation={true} hasShadow={false} fullWidth={true} showCookieIcon />
      <PostiPage>
        <PostiPageHeader>
          <HeaderTitle>{t('termination.processing.title')}</HeaderTitle>
          <HeaderSubTitle>{t('termination.processing.subtitle')}</HeaderSubTitle>
        </PostiPageHeader>
        <PostiPageContents>
          <PageSection>
            <ViewLoading />
          </PageSection>
        </PostiPageContents>
      </PostiPage>
      <PostiPageFooter />
    </>
  )
}
