import { Modal, ModalStateWrapper } from '@postidigital/posti-components'
import { CloseIcon } from '@postidigital/posti-components/build/brand'
import { BrandTheme } from '@postidigital/posti-theme'
import { observer } from 'mobx-react-lite'
import React, { MouseEventHandler, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonContainer, CancelButton, RemoveButton } from '../../components'
import { StoreContext } from '../../store'
import { ResponsiveBody } from '../typography/ResponsiveBody'

interface IProps {
  visible: boolean
  onClose: MouseEventHandler<HTMLButtonElement>
}

const OrgDialogContentComponent: React.FC<IProps> = ({ onClose, visible }) => {
  const { t } = useTranslation()
  const { orgStore, userStore } = useContext(StoreContext)
  const { removeOrg } = userStore
  const { setShowModal, setLeavingOrg } = orgStore

  const handleRemove = useCallback(() => {
    setShowModal(false)
    removeOrg(orgStore.leavingOrg)
    setLeavingOrg('')
  }, [orgStore.leavingOrg, removeOrg, setLeavingOrg, setShowModal])

  const handleModalCancel = useCallback(() => setShowModal(false), [setShowModal])

  return (
    <ModalStateWrapper open={visible}>
      <Modal>
        <Modal.CloseButton type="button" aria-label="Close" onClick={onClose} icon={CloseIcon} />
        <Modal.Content style={{ paddingRight: BrandTheme.spacing.space6 + 'rem' }}>
          <Modal.Header>
            <Modal.Title size="Five">{t('myOrgsView.modalTitle')}</Modal.Title>
          </Modal.Header>
          <ResponsiveBody>{t(`myOrgsView.modalText`)}</ResponsiveBody>
          <Modal.ButtonWrapper style={{ width: '100%', padding: 0, margin: 0 }}>
            <ButtonContainer position="end" style={{ padding: 0, marginTop: '2rem' }}>
              <CancelButton type="button" id="cancel_leave_button" onClick={handleModalCancel} />
              <RemoveButton type="button" id="leave_organization_button" onClick={handleRemove} />
            </ButtonContainer>
          </Modal.ButtonWrapper>
        </Modal.Content>
      </Modal>
    </ModalStateWrapper>
  )
}

export const OrgDialogContent = observer(OrgDialogContentComponent)
