// export const validateEmail = (email: string): boolean => {
//   const re = /\S+@\S+\.\S+/
//   return re.test(email)
// }

export const validateName = (name: string): boolean => {
  return /^[0-9\'\. \u002D\u0041-\u005A\u0061-\u007A\u00C0-\u00d6\u00D8-\u00F6\u00F8-\u017E\u01FA-\u01FF\u0218-\u021B\u0400-\u045F]+$/.test(
    name
  )
}

/**
 * Validate email address
 * @param newEmail
 * @param currentEmail
 */
export const validateResetEmail = (newEmail: string, currentEmail?: string): boolean => {
  if (!newEmail || newEmail.length > 254 || (currentEmail && newEmail === currentEmail)) {
    return false
  }

  const re = /^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/g
  const regex = RegExp(re)

  return regex.test(newEmail)
}

/**
 * Validate password complexity
 * @param value
 */
export const validatePasswordComplexity = (value: string) => {
  const regExp = /^(?=.*[^a-zA-Z0-9])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,254}$/
  return regExp.test(value)
}

/**
 * Validates a code for complexity.
 * @param code The code to validate.
 * @returns true if the code meets the complexity criteria, false otherwise.
 * @example
 * ```typescript
 * const code1 = "ABC123";
 * console.log(validateCodeComplexity(code1)); // Output: true
 * ```
 * @example
 * ```typescript
 * const code2 = "aBc123";
 * console.log(validateCodeComplexity(code2)); // Output: false, lowercase letters not allowed
 * ```
 * @example
 * ```typescript
 * const code3 = "ABC12";
 * console.log(validateCodeComplexity(code3)); // Output: false, length not exactly 6 characters
 * ```
 * @example
 * ```typescript
 * const code4 = "ABC1234";
 * console.log(validateCodeComplexity(code4)); // Output: false, length not exactly 6 characters
 * ```
 */
export const validateCodeComplexity = (code: string) => {
  // Check if code length is exactly 6 characters
  if (code.length !== 6) return false

  // Check first three characters for letters
  const firstThreeLetters = code.substring(0, 3)
  if (!/^[A-Z]+$/.test(firstThreeLetters)) return false

  // Check last three characters for numbers
  const lastThreeNumbers = code.substring(3)
  if (!/^\d+$/.test(lastThreeNumbers)) return false

  // If all conditions are satisfied, return true
  return true
}
