import { ArrowRightIcon, Checkbox, ExternalLinkIcon } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { ChangeEventHandler, MouseEventHandler, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled, { css } from 'styled-components'

import { FeatureFlags } from '../../featureFlags'
import Localization from '../../locales'
import { StoreContext } from '../../store'
import { StyledLinkWithExternalIcon } from '../../views/registration/registration.styles'
import { ButtonContainer, CancelButton, ContentSection, SaveButton, ServiceContractList } from '../'
import { ResponsiveBody } from '../typography/ResponsiveBody'
import { ResponsiveHeadline } from '../typography/ResponsiveHeadline'
import { ResponsiveLink } from '../typography/ResponsiveLink'

const MarketingDetailsExpanderContentComponent: React.FC = () => {
  const history = useHistory()
  const { userStore, terminateAccountViewStore, infoStore } = useContext(StoreContext)
  const { t } = useTranslation()
  const currentLang = Localization.CurrentLocale

  let feedbackUrl = window['postienv']?.feedbackLinks?.[Localization.CurrentLocale]

  if (!feedbackUrl) {
    feedbackUrl = 'https://yhteystietoni.posti.fi/ythp/datarequest?lang=' + Localization.CurrentLocale
  }

  const handleCancelClick = useCallback(() => {
    userStore.setExpanderOpen(false)
    userStore.restoreOriginalMarketingPermissions()
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [userStore])

  const handleSave = useCallback(async () => {
    if (userStore.savingChanges) {
      return
    }

    await userStore.saveMarketingChanges()
    userStore.setExpanderOpen(false)
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [userStore])

  const handleCheckboxChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { name } = event.target
      userStore.toggleMarketingPermission(name)
    },
    [userStore]
  )

  const renderCheckbox = useCallback(
    ({ id, name, label }: { id: string; name: string; label: string }) => (
      <Checkbox
        large
        id={id}
        name={name}
        // following prop can be removed if not used anywhere
        value={name}
        label={label}
        labelPosition="right"
        onChange={handleCheckboxChange}
        checked={userStore.marketingPermissions.includes(name)}
        style={{ padding: '4px 0' }}
      />
    ),
    [handleCheckboxChange, userStore.marketingPermissions]
  )

  const handleDeleteAccountClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (event) => {
      const to = event.currentTarget.getAttribute('href')

      event.preventDefault()
      event.nativeEvent.stopImmediatePropagation()

      if (!to) {
        return null
      }

      terminateAccountViewStore.initialize(infoStore, userStore)
      history.push(to)
    },
    [history, infoStore, terminateAccountViewStore, userStore]
  )

  const cookieInfoLink: Record<typeof currentLang, string> = useMemo(
    () => ({
      fi: 'https://www.posti.fi/fi/evasteet',
      sv: 'https://www.posti.fi/sv/kakor',
      en: 'https://www.posti.fi/en/cookies',
    }),
    []
  )

  const privacyPolicyLink: Record<typeof currentLang, string> = useMemo(
    () => ({
      fi:
        'https://www.posti.fi/fi/asiakastuki/ehdot-ja-tietosuoja/tietosuoja/tietosuojaselosteet/postin-sahkoisten-kuluttajapalveluiden-asiakasrekisteri',
      sv:
        'https://www.posti.fi/sv/kundsupport/villkor-och-dataskydd/dataskydd/dataskyddsbeskrivningar/postis-kundregister-for-elektroniska-konsumenttjanster',
      en:
        'https://www.posti.fi/en/customer-support/terms-and-data-protection/data-protection/privacy-statements/posti-customer-register-for-electronic-consumer-services',
    }),
    []
  )

  return (
    <ContentSection gap="lg">
      <ResponsiveHeadline>{t('detailsView.markConsent')}</ResponsiveHeadline>
      <ResponsiveBody>{t(`detailsView.contentText`)}</ResponsiveBody>
      <div>
        <ResponsiveBody>
          <b>{t(`detailsView.contentIWantBenefits`)}</b>
        </ResponsiveBody>
        <ContentSection top={false} gap="lg">
          {renderCheckbox({ id: 'email_marketing_box', name: 'EMAIL_MARKETING', label: t(`detailsView.byEmail`) })}
          {renderCheckbox({ id: 'mobile_marketing_box', name: 'MOBILE_MARKETING', label: t(`detailsView.byPhone`) })}
        </ContentSection>
      </div>
      <div>
        <ResponsiveBody>
          <strong>{t('detailsView.infoPart0')}</strong>
          {`${t('detailsView.infoPart1')} ${t('detailsView.infoPart2')}`}
        </ResponsiveBody>
        <ContentSection top={false} gap="lg">
          {renderCheckbox({
            id: 'partner_email_marketing_box',
            name: 'PARTNER_EMAIL',
            label: t(`detailsView.byEmail`),
          })}
          {renderCheckbox({
            id: 'partner_mail_marketing_box',
            name: 'PARTNER_MAIL',
            label: t(`detailsView.byMail`),
          })}
          {renderCheckbox({
            id: 'partner_phone_marketing_box',
            name: 'PARTNER_PHONE',
            label: t(`detailsView.byPhone`),
          })}
        </ContentSection>
      </div>
      <Divider />
      <ResponsiveHeadline>{t('detailsView.dataSharing')}</ResponsiveHeadline>
      <div>
        <ResponsiveBody>
          {t(`detailsView.infoPart3`)}
          {t(`detailsView.infoPart4`)}
        </ResponsiveBody>
        {renderCheckbox({
          id: 'partner_targeted_marketing_box',
          name: 'PARTNER_TARGETED_MARKETING',
          label: t(`detailsView.browserData`),
        })}
        <ResponsiveBody>
          {t(`detailsView.infoPart5`)}
          <ResponsiveLink target="_blank" href={cookieInfoLink[currentLang]}>
            {t(`detailsView.infoHere`)}
          </ResponsiveLink>
          {t(`detailsView.infoPart55`)}
          <ResponsiveLink target="_blank" href={privacyPolicyLink[currentLang]}>
            {t(`detailsView.infoPart6`)}
          </ResponsiveLink>
        </ResponsiveBody>
      </div>
      <div>
        <ResponsiveBody>{t(`detailsView.infoPart7`)}</ResponsiveBody>
        {renderCheckbox({
          id: 'partner_updatecontact_box',
          name: 'PARTNER_UPDATECONTACTINFO',
          label: t('detailsView.infoPart8') + '*',
        })}
        <ResponsiveBody>
          * {t(`registration.create.acceptRegistryUpdate.additionalInfo`)}
          <StyledLinkWithExternalIcon
            href={t('registration.create.links.disclosureBan')}
            icon={ExternalLinkIcon}
            target="_blank"
          >
            {t('registration.create.acceptRegistryUpdate.additionalInfoLinkText')}
          </StyledLinkWithExternalIcon>
        </ResponsiveBody>
      </div>
      <Divider />
      <ServiceContractList items={userStore.identities.consumerIdentity.contracts} />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ResponsiveBody>{t(`detailsView.accountTerminationDescription`)}</ResponsiveBody>
        <ResponsiveLink
          href="/terminateAccount"
          onClick={handleDeleteAccountClick}
          icon={ArrowRightIcon}
          iconPosition="right"
        >
          {t(`detailsView.accountTerminationLink`)}
        </ResponsiveLink>

        {FeatureFlags.FeedbackLinksEnabled && infoStore.userInfo.consumerAccountExists && (
          <>
            <ResponsiveLink href={feedbackUrl} icon={ArrowRightIcon} iconPosition="right" target="_blank">
              {t('sideMenu.feedback.link')}
            </ResponsiveLink>
          </>
        )}
      </div>
      <ButtonContainer position="start">
        <SaveButton id="save_consumer_details_button" disabled={!userStore.changesMade} onClick={handleSave} />
        <CancelButton
          id="cancel_consumer_details_button"
          disabled={!userStore.changesMade}
          onClick={handleCancelClick}
        />
      </ButtonContainer>
    </ContentSection>
  )
}

export const MarketingDetailsExpanderContent = observer(MarketingDetailsExpanderContentComponent)

const Divider = styled('div')(
  ({
    theme: {
      brand: { spacing },
    },
  }) => css`
    max-height: 1px;
    height: 1px;
    background: #b5b8ba;
    width: 100%;
    margin: ${spacing.space4}rem 0;
  `
)
