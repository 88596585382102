function getFlag(name: string, defaultValue: boolean): boolean {
  return !!(window['postienv']?.featureFlags?.[name] ?? defaultValue)
}

export const FeatureFlags = {
  FeedbackLinksEnabled: getFlag('FeedbackLinksEnabled', false),
  UseOldMyAccountForConsumers: getFlag('UseOldMyAccountForConsumers', true),
  EnablePreferredNameEditing: getFlag('EnablePreferredNameEditing', true),
  EnableAccountTermination: getFlag('EnableAccountTermination', false),
  EnableAccountTerminationFeedback: getFlag('EnableAccountTerminationFeedback', false),
  EnableAccountTerminationForConsumers: getFlag('EnableAccountTerminationForConsumers', true),
  EnableAccountTerminationForBusinessUsers: getFlag('EnableAccountTerminationForBusinessUsers', true),
  AllowConsumerAccountTerminationForBusinessUsers: getFlag('AllowConsumerAccountTerminationForBusinessUsers', false),
  EnableBusinessAccountNameChange: getFlag('EnableBusinessAccountNameChange', true),
  EnableConsumerRegistration: getFlag('EnableConsumerRegistration', false),
}
