import { ArrowLeftIcon } from '@postidigital/posti-components/build/brand'
import { XyzTheme } from '@postidigital/posti-theme'
import React, { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { MaxWidthLimiter } from './MaxWidthLimiter'

const PostiPageHeaderComponent: React.FC<PropsWithChildren<{ className?: string }>> = (props) => {
  return (
    <header className={props.className}>
      <MaxWidthLimiter max={640}>{props.children}</MaxWidthLimiter>
    </header>
  )
}
export const PostiPageHeader = styled(PostiPageHeaderComponent)`
  background-color: #ffffff;
  color: #262c30;
  margin: 0;
  padding: 0 24px 24px 24px;

  @media (min-width: ${XyzTheme.breakpoint.md}) {
    padding-bottom: 24px;
  }
`

export const HeaderTitle = styled.h1`
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
  margin: 0;
`

export const HeaderSubTitle = styled.div`
  font-size: 18px;
  min-height: 27px;
  font-weight: 600;
`

const HeaderBackLinkComponent: React.FC<PropsWithChildren<{ className?: string; to: string }>> = (props) => {
  return (
    <div className={props.className}>
      <Link to={props.to}>
        <ArrowLeftIcon aria-hidden={true} color={XyzTheme.color.neutralBlack} width="1.2rem" height="1.2rem" />
        {props.children}
      </Link>
    </div>
  )
}

export const HeaderBackLink = styled(HeaderBackLinkComponent)`
  height: 32px;
  font-size: 16px;
  font-weight: 600;

  & > a {
    color: ${XyzTheme.color.neutralBlack};
  }

  & svg {
    padding-bottom: 4px;
    margin-right: 8px;
  }

  @media (min-width: ${XyzTheme.breakpoint.md}) {
    font-size: 18px;
    height: 48px;
  }
`
