import { Input, PhoneIcon } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { ChangeEventHandler, FormEventHandler, useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IOrganizationPhoneNumberEditingState, organizationsUiStateStore, StoreContext, userStore } from '../../store'
import { ButtonContainer, CancelButton, ContentSection, SaveButton, SideImageContainerContent } from '..'
import { StaticInputLayout } from '../StaticInputLayout'
import { ResponsiveHeadline } from '../typography/ResponsiveHeadline'
import { OrganizationPhoneNumberChangeFailed } from './OrganizationPhoneNumberChangeFailed'
import { OrganizationPhoneNumberSuccess } from './OrganizationPhoneNumberSuccess'

const EditOrganizationPhoneNumberComponent: React.FC = () => {
  const { t } = useTranslation()
  const { editOrganizationPhoneNumberStore } = useContext(StoreContext)
  const {
    newPhoneNumber,
    isSavingPossible,
    isNewPhoneNumberInputInvalid,
    generalError,
    editingState,
  } = editOrganizationPhoneNumberStore

  const save: FormEventHandler<HTMLFormElement> = useCallback(
    (event): void => {
      event.preventDefault()
      event.nativeEvent.stopImmediatePropagation()

      if (isSavingPossible) {
        editOrganizationPhoneNumberStore.setEditingState(IOrganizationPhoneNumberEditingState.Saving)
      }
    },
    [editOrganizationPhoneNumberStore, isSavingPossible]
  )

  const updateNumber: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      editOrganizationPhoneNumberStore.setNewPhoneNumber(e.currentTarget.value)
    },
    [editOrganizationPhoneNumberStore]
  )

  const resetAndExit = useCallback((): void => {
    organizationsUiStateStore.reset()
    editOrganizationPhoneNumberStore.reset()
  }, [editOrganizationPhoneNumberStore])

  useEffect(() => {
    if (editingState == IOrganizationPhoneNumberEditingState.Success) {
      userStore.setOrganizationPhoneToUserAccountsMyOrganizations(newPhoneNumber)
      editOrganizationPhoneNumberStore.setNewPhoneNumber('')
      setTimeout(() => {
        resetAndExit()
      }, 5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingState])

  return (
    <SideImageContainerContent>
      <StyledVerticalContainer>
        <ResponsiveHeadline as="h1">{t(`mainView.editPhoneNumber.title`)}</ResponsiveHeadline>
        <form id="reset_organization_phone_form" onSubmit={save}>
          <ContentSection>
            <StaticInputLayout
              label={t(`mainView.editPhoneNumber.currentPhoneNumber`)}
              content={userStore.identities.businessIdentities.organizationPhoneNumber || '-'}
              Icon={PhoneIcon}
            />
            <Input
              autoFocus
              lightBackground
              id="phonenumber-input"
              label={t(`mainView.editPhoneNumber.newPhoneNumber`)}
              value={newPhoneNumber}
              onChange={updateNumber}
              isInvalid={isNewPhoneNumberInputInvalid}
              message={isNewPhoneNumberInputInvalid ? t(`mainView.editPhoneNumber.invalidPhoneNumber`) : ''}
            />
          </ContentSection>
          <ButtonContainer position="end">
            <CancelButton type="button" id="cancel_organization_phone_button" onClick={resetAndExit} />
            <SaveButton type="submit" id="save_organization_phone_button" disabled={!isSavingPossible} />
          </ButtonContainer>
        </form>
      </StyledVerticalContainer>
      {editingState == IOrganizationPhoneNumberEditingState.Success && <OrganizationPhoneNumberSuccess />}
      {generalError && <OrganizationPhoneNumberChangeFailed />}
    </SideImageContainerContent>
  )
}

export const EditOrganizationPhoneNumber = observer(EditOrganizationPhoneNumberComponent)

const StyledVerticalContainer = styled('div')`
  display: flex;
  flex-direction: column;
`
