import { XyzTheme } from '@postidigital/posti-theme'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled, { css } from 'styled-components'

interface IProps {
  title: string
  subtitle: string
}

const TitleWithSubTitleComponent: React.FC<IProps> = ({ title, subtitle }) => {
  return (
    <StyledTitleWithSubTitle>
      <StyledSubtitleContainer>{subtitle}</StyledSubtitleContainer>
      <StyledTitleContainer boldTitle={true} titleLength={title ? title.length : 0}>
        {title}
      </StyledTitleContainer>
    </StyledTitleWithSubTitle>
  )
}

export const TitleWithSubTitle = observer(TitleWithSubTitleComponent)

const StyledTitleWithSubTitle = styled('div')`
  width: 100%;
`

const StyledSubtitleContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: ${theme.xyz.color.neutralNetworkGray};
  `
)

const StyledTitleContainer = styled.div<{ boldTitle: boolean; titleLength: number }>(
  ({ theme, boldTitle, titleLength }) => css`
    display: inline-block;
    font-size: 1.125rem;
    line-height: 1.75rem;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: ${theme.xyz.color.neutralBlack};
    ${
      boldTitle &&
      `
      font-weight: ${theme.xyz.fontWeight.semiBold};
    `
    }
    ${
      titleLength > 30 &&
      `
      @media (max-width: ${XyzTheme.breakpoint.lg}) {
        white-space: normal;
        word-break: break-word;
        word-wrap: break-word;
        min-width: 200px;
      }
    `
    }
    @media (max-width: ${XyzTheme.breakpoint.sm}) {
      white-space: normal;
      word-break: break-word;
      word-wrap: break-word;
    }

  `
)
