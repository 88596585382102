import { Headline, HeadlineProps } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { ComponentPropsWithRef, FC } from 'react'

type ResponsiveHeadlineComponentProps = Omit<HeadlineProps, 'size'> & {
  size?: HeadlineProps['size']
} & ComponentPropsWithRef<typeof Headline>

const ResponsiveHeadlineComponent: FC<ResponsiveHeadlineComponentProps> = ({ children, ...headlineProps }) => {
  const size = headlineProps.size || 'Seven'

  return (
    <Headline {...headlineProps} size={size} style={{ margin: 0, ...headlineProps.style }}>
      {children}
    </Headline>
  )
}

export const ResponsiveHeadline = observer(ResponsiveHeadlineComponent)
