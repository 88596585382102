import { Button, EnvelopeIcon } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { FormEventHandler, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { StoreContext } from '../../../store'
import { CancelButton, SaveButton } from '../../buttons/buttons'
import { ButtonContainer, ContentSection } from '../../layout'
import { StaticInputLayout } from '../../StaticInputLayout'
import { StyledWrapper } from '../MainInfo'
import { EditEmailInput, EditEmailNotifications } from './'
import { EditEmailVerification } from './EditEmailVerification'

const EditEmailComponent: React.FC = () => {
  const { t } = useTranslation()
  const { mainViewStore, editEmailStore, infoStore } = useContext(StoreContext)
  const { newEmail, isNewEmailValid, changeSuccess } = editEmailStore

  editEmailStore.currentEmail = infoStore.userInfo.email

  const handleSave: FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()

      if (!isNewEmailValid) {
        return null
      }

      if (editEmailStore.savingChanges) {
        return null
      }

      return editEmailStore.saveNewEmail()
    },
    [editEmailStore, isNewEmailValid]
  )

  const handleDone = useCallback(() => {
    editEmailStore.reset()
    mainViewStore.reset()
  }, [editEmailStore, mainViewStore])

  return (
    <StyledWrapper>
      <form id="edit_account_email_form" onSubmit={handleSave}>
        <ContentSection>
          <EditEmailVerification />
          <StaticInputLayout
            label={!changeSuccess ? t(`mainView.editEmail.currentEmail`) : t(`mainView.editEmail.newEmail`)}
            content={!changeSuccess ? infoStore.userInfo.email : newEmail}
            Icon={EnvelopeIcon}
          />
          {!changeSuccess && <EditEmailInput />}
          <EditEmailNotifications />
          {!changeSuccess ? (
            <ButtonContainer>
              <CancelButton type="button" id="cancel_email_change_button" onClick={handleDone} />
              <SaveButton type="submit" id="change_email_button" disabled={!isNewEmailValid} />
            </ButtonContainer>
          ) : (
            <ButtonContainer>
              <Button
                contentMode="secondary"
                type="button"
                id="try_again_email_change_button"
                onClick={editEmailStore.reset}
              >
                {t(`mainView.editEmail.tryAgainButton`)}
              </Button>
              <Button
                contentMode="primary"
                hasBackground
                type="button"
                id="exit_email_change_button"
                onClick={handleDone}
              >
                {t(`mainView.editEmail.exitButton`)}
              </Button>
            </ButtonContainer>
          )}
        </ContentSection>
      </form>
    </StyledWrapper>
  )
}

export default observer(EditEmailComponent)
