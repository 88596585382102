import { configure } from 'mobx'
import React from 'react'

import { changeNameStore } from './changeNameStore'
import { changePasswordStore } from './changePasswordStore'
import { corporateAccountRecoveryStore } from './corporateRecoveryStore'
import { detailsUiStateStore } from './detailsUiStateStore'
import { editEmailStore } from './editEmailStore'
import { editOrganizationPhoneNumberStore } from './editOrganizationPhoneNumberStore'
import { editPhoneNumberStore } from './editPhoneNumberStore'
import { editPreferredNameStore } from './editPreferredNameStore'
import { infoStore } from './infoStore'
import { layoutStore } from './layoutStore'
import { mainViewStore } from './mainViewStore'
import { organizationsUiStateStore } from './organizationsUiStateStore'
import { orgStore } from './orgStore'
import { userStore } from './userStore'
import { registrationStore } from './views/registrationStore'
import { terminateAccountViewStore } from './views/terminateAccountViewStore'

configure({
  enforceActions: 'never',
})

export const StoreContextValue = {
  layoutStore,
  userStore,
  infoStore,
  orgStore,
  mainViewStore,
  editPhoneNumberStore,
  editOrganizationPhoneNumberStore,
  editEmailStore,
  changePasswordStore,
  editPreferredNameStore,
  detailsUiStateStore,
  organizationsUiStateStore,
  terminateAccountViewStore,
  changeNameStore,
  registrationStore,
  corporateAccountRecoveryStore,
}
export const StoreContext: React.Context<typeof StoreContextValue> = React.createContext(StoreContextValue)

export * from './changePasswordStore'
export * from './corporateRecoveryStore'
export * from './detailsUiStateStore'
export * from './editEmailStore'
export * from './editOrganizationPhoneNumberStore'
export * from './editPhoneNumberStore'
export * from './editPreferredNameStore'
export * from './infoStore'
export * from './layoutStore'
export * from './mainViewStore'
export * from './organizationsUiStateStore'
export * from './orgStore'
export * from './userStore'
export * from './views/acceptStore'
export * from './views/registrationStore'
export * from './views/terminateAccountViewStore'
