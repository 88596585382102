import React, { MutableRefObject, useState } from 'react'
import { TFunction } from 'react-i18next'

const isOptionalAndEmpty = (formItem: FormItem): boolean => {
  return Boolean(formItem.optional && !formItem.value)
}

const updateIsEmpty = (formItem: FormItem): void => {
  // isEmpty must not be updated unless it's initially defined
  if (formItem.isEmpty !== undefined) {
    formItem.isEmpty = Boolean(!formItem.value)
  }
}

type AllOrNone<T> = Required<T> | Partial<Record<keyof T, undefined>>

interface IsEmptyProps {
  isEmpty?: boolean
  emptyMessageTranslationKey?: string
}

// Conditional requirement: either both or none of the props are required
type FormItemIsEmptyProps = AllOrNone<IsEmptyProps>

type FormItemBase = {
  value: any
  validator: (value: any) => boolean
  isInvalid: boolean
  isEmpty?: boolean
  type?: 'checkbox'
  touched: boolean
  ref?: MutableRefObject<any>
  labelTranslationKey?: string
  invalidMessageTranslationKey?: string
  emptyMessageTranslationKey?: string
  customTypeRestriction?: (value: any) => boolean
  optional?: boolean
}

export type FormItem = FormItemBase & FormItemIsEmptyProps

export function useFormFields<T extends string>(
  initialState: Record<T, FormItem>
): [Record<T, FormItem>, (event: any) => void] {
  const [fields, setValues] = useState(initialState)

  return [
    fields,
    function (event: React.ChangeEvent<HTMLInputElement> | 'validateAll') {
      const updatedFields = { ...fields }
      if (event === 'validateAll') {
        Object.keys(fields).forEach((fieldName) => {
          const typedFieldName = fieldName as T
          updatedFields[typedFieldName].touched = true
          const value = fields[typedFieldName].value
          const isValid = isOptionalAndEmpty(fields[typedFieldName]) || fields[typedFieldName].validator(value)
          updatedFields[typedFieldName].value = value
          updatedFields[typedFieldName].isInvalid = !isValid
          updatedFields[typedFieldName].touched = true
          updateIsEmpty(updatedFields[typedFieldName])
          if (typedFieldName === 'password' && updatedFields['email' as T].value === value) {
            updatedFields[typedFieldName].isInvalid = true
          }
        })
      } else if (event.type === 'updateField') {
        // NOTE: CustomEvent typing is missing, because it's quite complicated to add so ignoring type warning for now.
        // @ts-ignore
        const fieldName = event.detail.fieldName
        // @ts-ignore
        updatedFields[fieldName].value = event.detail.value
      } else {
        // input-field may fire multiple events depending on where you click. We only want to handle the ones that have currentTarget.
        const fieldName = event?.currentTarget?.name as T
        if (fieldName) {
          const value = fields[fieldName]?.type === 'checkbox' ? event.currentTarget.checked : event.target.value

          // Do not update field value when customTypeRestriction -function returns false
          // customTypeRestriction is used to only allow specified type of characters to be typed in given field
          if (fields[fieldName].customTypeRestriction) {
            const valueIsAllowed = fields[fieldName].customTypeRestriction!(value)
            if (!valueIsAllowed) {
              return
            }
          }

          const isValid = isOptionalAndEmpty(fields[fieldName]) || fields[fieldName].validator(value)
          updatedFields[fieldName].value = value
          updatedFields[fieldName].isInvalid = !isValid
          updateIsEmpty(updatedFields[fieldName])
          if (fieldName === 'password' && updatedFields['email' as T].value === value) {
            updatedFields[fieldName].isInvalid = true
          }
          if (event.type === 'blur') {
            // Do not set touched when blur was triggered via clicking a button.
            // This prevents validation error from briefly showing when clicking modal cancel button. This also prevents other duplicate validation code from triggering.
            // If you need to trigger validation on button click: you may either call "validateAll" or add new "FormItem" property for this hook that allows it.
            const relatedTargetEvent = ((event as unknown) as React.FocusEvent).relatedTarget
            if (relatedTargetEvent?.nodeName === 'BUTTON') {
              return
            }

            updatedFields[fieldName].touched = true
          }
        }
      }

      setValues(updatedFields)
    },
  ]
}

/**
 * Helper function that returns correct message for FormItem based on it's state
 * @param formItem
 * @param t
 */
export const getFormItemMessage = (formItem: FormItem, t: TFunction<'translation'>): string => {
  if (formItem.touched && formItem.isInvalid) {
    return formItem.isEmpty
      ? t(formItem.emptyMessageTranslationKey as string) || ''
      : t(formItem.invalidMessageTranslationKey as string) || ''
  } else {
    return ''
  }
}
