import {
  ArrowRightIcon,
  Button,
  LockIcon,
  Notification,
  Password,
  ShieldIcon,
} from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  FormEventHandler,
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ButtonContainer, ContentSection } from '../../components'
import { ResponsiveBody } from '../../components/typography/ResponsiveBody'
import { ResponsiveLinkButton } from '../../components/typography/ResponsiveLinkButton'
import { CORPORATE_RECOVERY_STEP, corporateAccountRecoveryStore, RESPONSE_MESSAGE, StoreContext } from '../../store'
import { validateCodeComplexity, validatePasswordComplexity } from '../../utils/validation'

type ResetFieldName = 'code' | 'password' | 'passwordVerification'

const validators: Record<ResetFieldName, (value: string, repeatPassword?: string) => boolean> = {
  code: (code) => validateCodeComplexity(code),
  password: (password) => validatePasswordComplexity(password),
  passwordVerification: (password, repeatPassword) => !!(password && repeatPassword && password === repeatPassword),
}

const PasswordStep: FC = () => {
  const { t } = useTranslation()
  const {
    corporateAccountRecoveryStore: { email, isSaving, error, message },
  } = useContext(StoreContext)

  const [touched, setTouched] = useState<Record<ResetFieldName, boolean>>({
    code: false,
    password: false,
    passwordVerification: false,
  })
  const [valid, setValid] = useState<Record<ResetFieldName, boolean>>({
    code: false,
    password: false,
    passwordVerification: false,
  })
  const [{ code, password, passwordVerification }, setValue] = useState<Record<ResetFieldName, string>>({
    code: '',
    password: '',
    passwordVerification: '',
  })

  const [linkDisabled, setLinkDisabled] = useState<boolean>(false)
  const okToSubmit = useMemo(() => [...Object.values(valid), !linkDisabled, !isSaving].every((val) => val), [
    isSaving,
    linkDisabled,
    valid,
  ])
  const timeout = useRef<NodeJS.Timeout | null>(null)

  const resetDisabledState = useCallback(() => {
    setLinkDisabled(!okToSubmit)

    if (timeout?.current) {
      clearTimeout(timeout.current)
    }
  }, [okToSubmit])

  const handleInputBlur: FocusEventHandler<HTMLInputElement> = useCallback((event) => {
    setTouched((prevState) => ({ ...prevState, [event.target.name]: true }))
  }, [])

  const validateInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { name, value } = event.target

      setValid((prevState) => {
        // Validate both password fields when changing the first one
        if (name === 'password') {
          return {
            ...prevState,
            [name]: validators[name as ResetFieldName]?.(value),
            passwordVerification: validators.passwordVerification(passwordVerification, value),
          }
        }

        // compare both entered passwords
        if (name === 'passwordVerification') {
          return {
            ...prevState,
            [name]: validators[name as ResetFieldName]?.(value, password),
          }
        }

        // validate code
        return {
          ...prevState,
          [name]: validators[name as ResetFieldName]?.(value),
        }
      })
    },
    [password, passwordVerification]
  )

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { name, value } = event.target

      corporateAccountRecoveryStore.resetError()
      validateInput(event)
      setValue((prevState) => ({ ...prevState, [name]: name === 'code' ? value.toUpperCase() : value }))
    },
    [validateInput]
  )

  const handleBackClick: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    corporateAccountRecoveryStore.setStep(CORPORATE_RECOVERY_STEP.EMAIL)
  }, [])

  const handleSubmitCodeRequest: FormEventHandler<HTMLFormElement> = useCallback(
    async (event) => {
      event.preventDefault()
      event.stopPropagation()

      if (!okToSubmit) {
        return null
      }

      setLinkDisabled(true)
      await corporateAccountRecoveryStore.resetPassword({ code, password })
      timeout.current = setTimeout(resetDisabledState, 2000)
    },
    [code, okToSubmit, password, resetDisabledState]
  )

  useEffect(
    () => () => {
      if (timeout?.current) {
        clearTimeout(timeout.current)
      }
    },
    []
  )

  return (
    <form id="corporate-recovery-init" onSubmit={handleSubmitCodeRequest}>
      <ContentSection>
        <ResponsiveBody>
          <Trans i18nKey="accountRecovery.corporate.passwordStep.content0" values={{ email }} components={[<b />]} />
        </ResponsiveBody>
        <ResponsiveBody as="p">
          {t('accountRecovery.corporate.passwordStep.content1')}
          <ResponsiveLinkButton style={{ display: 'inline' }} onClick={handleBackClick}>
            {t('accountRecovery.corporate.passwordStep.contentLink')}
          </ResponsiveLinkButton>
          {' ' + t('accountRecovery.corporate.passwordStep.content2')}
        </ResponsiveBody>
        <Notification dismissible={false} contentType={'success'}>
          {t(RESPONSE_MESSAGE.SUCCESS)}
        </Notification>
        {error && message && (
          <Notification dismissible={false} contentType={message === RESPONSE_MESSAGE.SUCCESS ? 'success' : 'alert'}>
            {t(message)}
          </Notification>
        )}
      </ContentSection>
      <ContentSection gap="lg">
        <Password
          autoFocus
          maxLength={6}
          minLength={6}
          lightBackground
          id="code-input"
          name="code"
          label={t(`accountRecovery.corporate.passwordStep.codeInputLabel`)}
          value={code}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          isInvalid={touched['code'] && !valid['code']}
          message={touched['code'] && !valid['code'] ? t('accountRecovery.corporate.error.codeValidation') : ''}
          leftTemplate={<ShieldIcon width="24px" />}
        />
        <Password
          lightBackground
          id="password-input"
          name="password"
          label={t(`mainView.changePassword.newPassword`)}
          value={password}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          message={
            touched['password'] && password && !valid['password']
              ? t('mainView.changePassword.error.NEW_PASSWORD_NOT_VALID')
              : ''
          }
          isInvalid={touched['password'] && !valid['password']}
          leftTemplate={<LockIcon width="24px" />}
        />
        <Password
          lightBackground
          id="passwordVerification-input"
          name="passwordVerification"
          label={t(`mainView.changePassword.newPasswordAgain`)}
          value={passwordVerification}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          isInvalid={touched['passwordVerification'] && !valid['passwordVerification']}
          message={
            touched['passwordVerification'] && passwordVerification && !valid['passwordVerification']
              ? t(`mainView.changePassword.noMatch`)
              : ''
          }
          leftTemplate={<LockIcon width="24px" />}
        />
      </ContentSection>
      <ButtonContainer>
        <Button
          size="sm"
          type="submit"
          id="corporate_recovery_code"
          contentMode="primary"
          hasBackground
          icon={ArrowRightIcon}
          iconPosition="right"
          disabled={!okToSubmit}
        >
          {t('accountRecovery.corporate.passwordStep.ctaLabel')}
        </Button>
      </ButtonContainer>
    </form>
  )
}

export default observer(PasswordStep)
