import { action, computed, makeObservable, observable } from 'mobx'

import { serviceAdapter } from '../service/serviceAdapter'
import { userStore } from '.'
import { IChangeNameResult } from './dataModels/interfaces'

export class ChangeNameStore {
  oldFirstName: string = ''

  oldLastName: string = ''

  firstName: string = ''

  lastName: string = ''

  savingChanges: boolean = false

  result: IChangeNameResult | undefined = undefined

  changeSuccess: boolean = false

  hideErrorTimeout: ReturnType<typeof setTimeout> | undefined = undefined

  constructor() {
    makeObservable(this, {
      oldFirstName: observable,
      oldLastName: observable,
      firstName: observable,
      lastName: observable,
      savingChanges: observable,
      result: observable,
      changeSuccess: observable,
      hasError: computed,
      setFirstName: action,
      setLastName: action,
      setOldFirstName: action,
      setOldLastName: action,
      resetResult: action,
      saveNewName: action,
      hideErrorAfterTimeout: action,
    })
  }

  get hasError(): boolean | undefined {
    return this.result && this.result?.result !== 'OK'
  }

  setFirstName = (value: string) => {
    this.firstName = value
  }

  setLastName = (value: string) => {
    this.lastName = value
  }

  setOldFirstName = (value: string) => {
    this.oldFirstName = value
  }

  setOldLastName = (value: string) => {
    this.oldLastName = value
  }

  resetResult = () => {
    this.result = undefined
  }

  saveNewName = async () => {
    this.savingChanges = true
    try {
      const response = await serviceAdapter.sendPatchRequest('/api/myaccount/changename', {
        firstName: this.firstName.trim(),
        lastName: this.lastName.trim(),
      })

      if (response.status >= 500) {
        this.result = { result: 'ERROR' }
        this.savingChanges = false
        this.hideErrorAfterTimeout()
        throw new Error('Bad response from server')
      }

      const data = await response.json()
      this.result = data
      this.hideErrorAfterTimeout()

      // Save changes to userStore
      userStore.setFirstName(this.firstName)
      userStore.setLastName(this.lastName)

      this.savingChanges = false
      return this.result
    } catch (err) {
      console.error(err)
    }
  }

  async hideErrorAfterTimeout() {
    if (this.hideErrorTimeout) {
      clearTimeout(this.hideErrorTimeout)
    }

    this.hideErrorTimeout = setTimeout(() => {
      this.result = undefined
    }, 5000)
  }
}

export const changeNameStore = new ChangeNameStore()
