import { Loading } from '@postidigital/posti-components'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  CloseIcon,
  EditAltIcon,
  ExternalLinkIcon,
} from '@postidigital/posti-components/build/brand'
import { XyzTheme } from '@postidigital/posti-theme'
import React from 'react'
import { TransProps, useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

interface IProps {
  id: string
  onClick?: () => any
  disabled?: boolean
  loading?: boolean
  textColor?: string
  backgroundColor?: string
  translationId?: string
  type?: 'button' | 'submit'
}

type Props = Partial<TransProps<any>> & IProps

export const SaveButton: React.FC<Props> = ({
  onClick,
  disabled,
  loading,
  id,
  translationId = 'general.saveChanges',
  type = 'button',
}) => {
  const [t] = useTranslation()

  return (
    <StyledButton
      type={type}
      contentMode="primary"
      hasBackground
      id={id}
      css
      disabled={disabled}
      size="sm"
      onClick={onClick}
    >
      <StyledButtonTitle>{t(translationId)}</StyledButtonTitle>
      {loading && <StyledLoading color="currentcolor" size="sm" />}
    </StyledButton>
  )
}

const StyledButtonTitle = styled.span<{ color?: string }>(
  ({ color }) => css`
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: ${color};
  `
)

const StyledButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  width: max-content;

  &[mode='nude']:focus:not(:disabled):before {
    background-color: #f6f7f9;
  }
`

const StyledButtonWithMarginRight = styled(Button)<{ color?: string }>(
  ({ color }) => css`
    display: flex;
    justify-content: space-between;
    width: max-content;
    margin-right: 8px;
    background-color: ${color};

    &[mode='nude']:focus:not(:disabled):before {
      background-color: #f6f7f9;
    }
  `
)

const StyledLoading = styled(Loading)`
  margin-left: 16px;
`

export const CancelButton: React.FC<Props> = ({
  onClick,
  disabled,
  textColor,
  backgroundColor,
  translationId = 'general.cancel',
  id,
  type = 'button',
}) => {
  const [t] = useTranslation()

  return (
    <StyledButtonWithMarginRight
      id={id}
      css
      type={type}
      disabled={disabled}
      size="sm"
      contentMode="secondary"
      hasBackground={false}
      onClick={onClick}
      color={backgroundColor}
    >
      <StyledButtonTitle color={textColor}>{t(translationId)}</StyledButtonTitle>
    </StyledButtonWithMarginRight>
  )
}

export const EditButton: React.FC<Props> = ({ onClick, disabled, id, translationId = 'general.edit' }) => {
  const [t] = useTranslation()

  return (
    <StyledButton
      id={id}
      css
      disabled={disabled}
      size="sm"
      icon={EditAltIcon}
      iconPosition="left"
      contentMode="tertiary"
      hasBackground={false}
      aria-label={t(translationId)}
      onClick={() => onClick && onClick()}
    />
  )
}

export const RemoveButton: React.FC<Props> = ({ onClick, disabled, id, translationId = 'general.remove' }) => {
  const [t] = useTranslation()

  return (
    <>
      <StyledButton
        id={id}
        css
        disabled={disabled}
        size="sm"
        hasBackground
        contentMode="primary"
        onClick={() => onClick && onClick()}
      >
        <StyledButtonTitle>{t(translationId)}</StyledButtonTitle>
      </StyledButton>
    </>
  )
}

export const LeaveOrgButton: React.FC<Props> = ({ onClick, disabled, translationId = 'myOrgsView.leaveOrg' }) => {
  const [t] = useTranslation()
  return (
    <Button
      id="leave_organization_button"
      css
      disabled={disabled}
      size="sm"
      contentMode="secondary"
      hasBackground={false}
      icon={CloseIcon}
      onClick={() => onClick?.()}
    >
      {t(translationId)}
    </Button>
  )
}

export const EditOrgButton: React.FC<Props> = ({ onClick, disabled, id, translationId = 'myOrgsView.editOrg' }) => {
  const [t] = useTranslation()
  return (
    <Button
      css
      id={id}
      icon={ExternalLinkIcon}
      iconPosition="right"
      size="sm"
      hasBackground
      disabled={disabled}
      contentMode="primary"
      onClick={() => onClick && onClick()}
    >
      {t(translationId)}
    </Button>
  )
}

export const BackButton = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <Button contentMode="secondary" icon={ArrowLeftIcon} hasBackground={true} {...props}>
      {props.children}
    </Button>
  )
}

export const NextButton = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <Button contentMode="primary" icon={ArrowRightIcon} iconPosition="right" hasBackground {...props}>
      {props.children}
    </Button>
  )
}

export const SaveNameButton: React.FC<Props> = ({
  onClick,
  disabled,
  loading,
  id,
  translationId = 'general.saveChanges',
}) => {
  const [t] = useTranslation()

  return (
    <Button
      id={id}
      css
      disabled={disabled}
      size="sm"
      contentMode="primary"
      hasBackground
      onClick={() => onClick && onClick()}
    >
      <StyledButtonTitle>{t(translationId)}</StyledButtonTitle>
      {loading && <StyledLoading color={XyzTheme.color.neutralIconGray} size="sm" />}
    </Button>
  )
}
