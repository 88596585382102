class PhoneNumberValidator {
  ALLOWED_CHARS_RE = /^\s*\+?[0-9\s\(\)\-]+$/
  HAS_AT_LEAST_ONE_NUMBER_RE = /.*[0-9].*/
  FINNISH_NUMBER_START_RE = /^\s*(?:\+358|[^+])/
  MIN_FINNISH_NUMBER_NORMALISED_LENGTH = 7

  isValid(phoneNumber: string, allowEmptyOrNull: boolean): boolean {
    if (phoneNumber && phoneNumber.length > 0 && phoneNumber.length < 20) {
      return (
        phoneNumber.length < 31 &&
        this.ALLOWED_CHARS_RE.test(phoneNumber) &&
        this.HAS_AT_LEAST_ONE_NUMBER_RE.test(phoneNumber)
      )
    }
    return allowEmptyOrNull
  }

  isValidFinnishMobileNumber(phoneNumber: string): boolean {
    const numbers = phoneNumber.replace(/[^0-9+]/g, '')
    return (
      phoneNumber.length >= this.MIN_FINNISH_NUMBER_NORMALISED_LENGTH &&
      this.isValid(phoneNumber, false) &&
      this.FINNISH_NUMBER_START_RE.test(phoneNumber) &&
      this.VALID_PREFIXES.filter((prefix) => numbers.startsWith(prefix)).length > 0
    )
  }

  isFinnishAnyNumber(phoneNumber: string): boolean {
    return this.FINNISH_NUMBER_START_RE.test(phoneNumber)
  }

  normalize(phoneNumber: string, allowNull: boolean): string {
    if (!this.isValid(phoneNumber, allowNull)) {
      return phoneNumber
    }

    if (phoneNumber != null) {
      phoneNumber = phoneNumber.trim()
    }

    if (phoneNumber && phoneNumber.length > 0) {
      let normalized = phoneNumber.replace(/[^0-9+]/g, '')

      // Assume finnish country code if number starts with zero
      if (normalized.startsWith('0')) {
        normalized = '+' + '358' + normalized.substring(1)
      }

      // Add plus sign
      if (!normalized.startsWith('+')) {
        normalized = '+' + normalized
      }

      // Remove possible zero after finnish country code
      // Note: For some countries (for example Italy) the zero after country code should not be removed
      if (normalized.startsWith('+3580')) {
        normalized = '+358' + normalized.substring(5)
      }

      return normalized
    }
    return ''
  }

  VALID_PREFIXES = [
    // Phone numbers starting with 04 or 050 are considered valid
    '+3584',
    '+35850',

    // We also allow national subscriber numbers
    // https://www.viestintavirasto.fi/en/internettelephone/numberingoftelecommunicationsnetworks/businessnumbers.html
    '+358101',
    '+358102',
    '+358103',
    '+358104',
    '+358105',
    '+358106',
    '+358107',
    '+358108',
    '+358109',

    '+358201',
    '+3582021',
    '+3582024',
    '+3582025',
    '+3582026',
    '+3582027',
    '+3582028',
    '+3582029',
    '+358203',
    '+358204',
    '+358205',
    '+358206',
    '+358207',
    '+358208',

    '+35829',

    '+358301',
    '+358302',
    '+358303',
    '+358304',
    '+358305',
    '+358306',
    '+358307',
    '+358308',
    '+358309',

    '+35839',

    '+35871',
    '+35873',

    '+35875003',
    '+35875004',
    '+35875005',
    '+35875006',
    '+35875007',
    '+35875008',
    '+35875009',

    '+35875303',
    '+35875304',
    '+35875305',
    '+35875306',
    '+35875307',
    '+35875308',
    '+35875309',

    '+35875323',
    '+35875324',
    '+35875325',
    '+35875326',
    '+35875327',
    '+35875328',
    '+35875329',

    '+35875753',
    '+35875754',
    '+35875755',
    '+35875756',
    '+35875757',
    '+35875758',
    '+35875759',

    '+35875759',

    '+35875983',
    '+35875984',
    '+35875985',
    '+35875986',
    '+35875987',
    '+35875988',
    '+35875989',
  ]
}

export const phoneNumberValidator = new PhoneNumberValidator()
