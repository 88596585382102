import { ArrowRightIcon, Button } from '@postidigital/posti-components/build/brand'
import React, { FC, MouseEventHandler, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'

import {
  ButtonContainer,
  ContentSection,
  MinYSpacingWrapper,
  PostiPage,
  PostiPageFooter,
  TopNav,
  ViewLoading,
} from '../../components'
import { ResponsiveBody } from '../../components/typography/ResponsiveBody'
import { ResponsiveHeadline } from '../../components/typography/ResponsiveHeadline'
import Localization, { Locale } from '../../locales'
import { StoreContext } from '../../store'
import { TextContainer } from '../registration/registration.styles'

const AccountRecoveryInfo: FC<RouteComponentProps> = ({ history }) => {
  const { t } = useTranslation()
  const lang: Locale = Localization.CurrentLocale as Locale
  const { infoStore, userStore } = useContext(StoreContext)
  const [storesFetched, setStoresFetched] = useState(false)
  const [authenticated, setAuthenticated] = useState(true)

  const continueClickHandler: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    location.href = `${location.origin}/forgot?lang=${lang}`
  }, [lang])

  useEffect(() => {
    const fetchData = () => Promise.all([infoStore.fetchUserInfo(true), userStore.fetchIdentities(true)])

    fetchData()
      .catch(() => {
        setAuthenticated(false)
      })
      .finally(() => {
        setStoresFetched(true)
      })
  }, [infoStore, userStore])

  useEffect(() => {
    if (storesFetched && authenticated) {
      history.push('/')
    }
  }, [authenticated, history, storesFetched, userStore])

  if (!storesFetched || authenticated) {
    return <ViewLoading />
  }

  return (
    <>
      <TopNav hideNavigation hideLogout />
      <PostiPage>
        <MinYSpacingWrapper>
          <TextContainer>
            <ResponsiveHeadline as="h1">{t('accountRecovery.initial.title')}</ResponsiveHeadline>
            <ContentSection>
              <ResponsiveBody>{t('accountRecovery.initial.content')}</ResponsiveBody>
            </ContentSection>
            <ButtonContainer>
              <Button
                size="sm"
                id="account_recovery_init"
                contentMode="primary"
                hasBackground
                icon={ArrowRightIcon}
                onClick={continueClickHandler}
                iconPosition="right"
              >
                {t('accountRecovery.initial.buttonText')}
              </Button>
            </ButtonContainer>
            <PostiPageFooter showCookiePreferences />
          </TextContainer>
        </MinYSpacingWrapper>
      </PostiPage>
    </>
  )
}

export default withRouter(AccountRecoveryInfo)
