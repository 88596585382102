import { BusinessIcon, CogIcon, HeadphonesIcon, HomeIcon, PinIcon } from '@postidigital/posti-components/build/brand'
import { DefaultIconProps } from '@postidigital/posti-components/build/design-tokens/icons/icons.types'
import { XyzTheme } from '@postidigital/posti-theme'
import { FC, HTMLAttributes } from 'react'

import { FeatureFlags } from '../../featureFlags'
import localization from '../../locales'

enum SIDE_MENU_ITEM_ICON_POSITION {
  right = 'right',
  left = 'left',
}

export interface SideMenuPropsMainItem extends Omit<HTMLAttributes<HTMLElement>, 'onClick'> {
  label: string
  route: string
  icon: FC<DefaultIconProps>
  iconPosition?: keyof typeof SIDE_MENU_ITEM_ICON_POSITION
  isOpenByDefault?: boolean
  activeBackgroundColor?: string
  activeTextColor?: string
  isActive: (route: string) => boolean
  onClick: (route: string) => void
  subItems?: SideMenuPropsSubItem[]
  resetMainViewStore: () => void
  mobileOnly: boolean
}

interface SideMenuPropsSubItem extends Omit<HTMLAttributes<HTMLElement>, 'onClick'> {
  label: string
  subtitle?: string
  icon?: JSX.Element
  iconPosition?: keyof typeof SIDE_MENU_ITEM_ICON_POSITION
  route: string
  isActive: (route: string) => boolean
  onClick: (route: string) => void
}

// routes that will be visible in the navigation
enum Routes {
  HOME = '/',
  DETAILS = '/details',
  ADDRESS = '/address',
  SETTINGS = '/settings',
  ORGS = '/organisations',
  FEEDBACK = '/feedback',
}

export const getExampleSideMenuStructure = (
  history: any,
  location: any,
  t: any,
  showDetails: any,
  showOrgs: any,
  isStrongAuth: any,
  resetMainViewStore: any
): SideMenuPropsMainItem[] => {
  const defaultPathMatcher = (route: any) => location.pathname === route
  const startsWithPathMatcher = (route: any) => location.pathname.toLowerCase().startsWith(route)
  const neverPathMatcher = () => false
  const defaultAction = (route: any) => history.push(route)
  const gotoFeedbackAction = () => {
    let feedbackUrl = window['postienv']?.feedbackLinks?.[localization.CurrentLocale]
    if (!feedbackUrl) {
      feedbackUrl = 'https://yhteystietoni.posti.fi/ythp/datarequest?lang=' + localization.CurrentLocale
    }
    window.location.href = feedbackUrl
  }

  const handleMyAccountClick = (route: any) => {
    resetMainViewStore()
    defaultAction(route)
  }

  const structure = [
    {
      label: t(`sideMenu.myAccount`),
      route: Routes.HOME,
      activeBackgroundColor: XyzTheme.color.brandMutedYellow,
      activeTextColor: XyzTheme.color.brandPetrol,
      icon: HomeIcon,
      isActive: () => defaultPathMatcher(Routes.HOME) || startsWithPathMatcher('/terminateaccount'),
      onClick: handleMyAccountClick,
      resetMainViewStore: resetMainViewStore,
      mobileOnly: false,
    },
  ]

  if (showDetails) {
    structure.push({
      label: t(`sideMenu.myAddress`),
      route: Routes.ADDRESS,
      activeBackgroundColor: XyzTheme.color.brandMutedYellow,
      activeTextColor: XyzTheme.color.brandPetrol,
      icon: PinIcon,
      isActive: () => defaultPathMatcher(Routes.ADDRESS),
      onClick: defaultAction,
      resetMainViewStore: resetMainViewStore,
      mobileOnly: false,
    })

    structure.push({
      label: t(`sideMenu.mySettings`),
      route: Routes.SETTINGS,
      activeBackgroundColor: XyzTheme.color.brandMutedYellow,
      activeTextColor: XyzTheme.color.brandPetrol,
      icon: CogIcon,
      isActive: () => defaultPathMatcher(Routes.SETTINGS),
      onClick: defaultAction,
      resetMainViewStore: resetMainViewStore,
      mobileOnly: false,
    })
  }

  if (showOrgs) {
    structure.push({
      label: t(`sideMenu.myOrgs`),
      route: Routes.ORGS,
      activeBackgroundColor: XyzTheme.color.brandMutedYellow,
      activeTextColor: XyzTheme.color.brandPetrol,
      icon: BusinessIcon,
      isActive: () => defaultPathMatcher(Routes.ORGS),
      onClick: defaultAction,
      resetMainViewStore: resetMainViewStore,
      mobileOnly: false,
    })
  }

  if (FeatureFlags.FeedbackLinksEnabled && showDetails) {
    structure.push({
      label: t(`sideMenu.feedback.mobileLink`),
      route: Routes.FEEDBACK,
      activeBackgroundColor: XyzTheme.color.brandMutedYellow,
      activeTextColor: XyzTheme.color.brandPetrol,
      icon: HeadphonesIcon,
      isActive: () => neverPathMatcher(),
      onClick: gotoFeedbackAction,
      resetMainViewStore: resetMainViewStore,
      mobileOnly: true,
    })
  }

  return structure
}
