export const formatDate = (dateString: string): string => {
  let date = new Date(Date.parse(dateString))
  let day = date.getUTCDate()
  let monthIndex = date.getUTCMonth() + 1
  let year = date.getUTCFullYear()

  return day + '.' + monthIndex + '.' + year
}

export const formatDateDaysLeft = (dateString: string): string => {
  let date = new Date(Date.parse(dateString))
  let today = new Date()
  let oneDay = 1000 * 60 * 60 * 24
  let daysleft = Math.ceil((date.getTime() - today.getTime()) / oneDay)
  return daysleft.toString()
}
