import { BrandLinkButtonProps } from '@postidigital/posti-components/build/basic-components/LinkButton/LinkButton'
import { LinkButton } from '@postidigital/posti-components/build/brand'
import { BrandTheme } from '@postidigital/posti-theme'
import { observer } from 'mobx-react-lite'
import React, { ComponentPropsWithRef, FC } from 'react'
import styled, { css, useTheme } from 'styled-components'

import { layoutStore } from '../../store'

type ResponsiveLinkButtonComponentProps = Omit<BrandLinkButtonProps, 'size'> & {
  size?: BrandLinkButtonProps['size']
} & ComponentPropsWithRef<typeof LinkButton>

const ResponsiveLinkButtonComponent: FC<ResponsiveLinkButtonComponentProps> = ({ children, ...linkButtonProps }) => {
  const {
    brand: { color },
  } = useTheme()

  // Switch back to this when small sizes are fixed
  // const size = linkButtonProps.size || (!layoutStore.isDesktop ? 'xxs' : 'md')
  const fontSize = `${!layoutStore.isDesktop ? BrandTheme.fontSize.body.four : BrandTheme.fontSize.body.three}rem`
  const linkColor = linkButtonProps.style?.color || color.postiOrange70

  return (
    <StyledLinkButton
      type="button"
      {...linkButtonProps}
      iconColor={linkColor}
      style={{
        margin: 0,
        padding: 0,
        fontSize: fontSize,
        lineHeight: 'normal',
        fontWeight: BrandTheme.fontWeight.bold,
        borderWidth: BrandTheme.spacing.space05 + 'rem',
        ...(linkButtonProps.style || {}),
        color: linkButtonProps.disabled ? 'inherit' : linkColor,
      }}
    >
      {children}
    </StyledLinkButton>
  )
}

const StyledLinkButton = styled(LinkButton)<{ iconColor: string }>(
  ({ iconColor }) => css`
    & > svg > path {
      ${iconColor &&
      css`
        // Lack of handles for specificity
        stroke: ${iconColor} !important;
      `}
    }
  `
)

export const ResponsiveLinkButton = observer(ResponsiveLinkButtonComponent)
