import React from 'react'
import Popup from 'reactjs-popup'

const contentStyle: React.CSSProperties = {
  borderRadius: '5px',
  width: '440px',
  paddingTop: '23px',
  paddingLeft: '43px',
  paddingRight: '19px',
  alignSelf: 'center',
}

interface ModalDialogProps {
  visible: boolean
  children: any
  onClose?: () => any
  closeOnDocumentClick?: boolean
}

export const ModalDialog: React.FC<ModalDialogProps> = ({
  visible,
  children,
  onClose,
  closeOnDocumentClick = false,
}) => {
  return (
    <Popup
      contentStyle={contentStyle}
      modal={true}
      closeOnDocumentClick={closeOnDocumentClick}
      closeOnEscape={true}
      lockScroll={true}
      open={visible}
      onClose={() => onClose && onClose()}
    >
      {children}
    </Popup>
  )
}
