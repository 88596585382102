import { action, computed, makeObservable, observable, runInAction } from 'mobx'

import { phoneNumberValidator } from '../service/phoneNumberValidator'
import { serviceAdapter } from '../service/serviceAdapter'
import { VERIFY_PHONE_ERROR } from '../views/registration/verifyPhone/AlertNotification'
import { IPhoneNumberVerificationResponse } from './dataModels/interfaces'
import { infoStore } from './infoStore'
import { userStore } from './userStore'

export enum IPhoneNumberEditingState {
  NotEditing = 1,
  SendingVerifyCode,
  WaitingVerifyCodeInput,
  VerifyingCode,
  VerificationCodeIncorrect,
  VerificationCodeFailed,
  WaitAMoment,
  CodeWasVerified,
}

export class EditPhoneNumberStore {
  constructor() {
    makeObservable(this, {
      newPhoneNumber: observable,
      editingState: observable,
      code: observable,
      generalError: observable,
      isNewPhoneNumberValid: computed,
      isNewPhoneNumberInputInvalid: computed,
      isSavingPossible: computed,
      isCodeValidFormat: computed,
      isVerificationPopupVisible: computed,
      areVerficationDigitsVisible: computed,
      setNewPhoneNumber: action,
      setLang: action,
      setEditingState: action,
      sanitizePhoneNumber: action,
      reset: action,
      setCode: action,
      setGeneralError: action,
      sendVerifyCode: action,
      verifyCode: action,
    })

    this.reset()
  }

  newPhoneNumber: string

  editingState: IPhoneNumberEditingState

  code: string

  generalError: boolean

  lang: string

  get isNewPhoneNumberValid(): boolean {
    const normalized = phoneNumberValidator.normalize(this.newPhoneNumber, true)
    return phoneNumberValidator.isValidFinnishMobileNumber(normalized)
  }

  get isNewPhoneNumberInputInvalid(): boolean {
    return this.newPhoneNumber?.length > 3 && !this.isNewPhoneNumberValid
  }

  get isSavingPossible(): boolean {
    if (!this.newPhoneNumber) return false
    return this.newPhoneNumber.length > 3 && this.isNewPhoneNumberValid
  }

  get isCodeValidFormat(): boolean {
    return /^[0-9]{6}$/.test(this.code)
  }

  get isVerificationPopupVisible(): boolean {
    return this.editingState && this.editingState != IPhoneNumberEditingState.NotEditing
  }

  get areVerficationDigitsVisible(): boolean {
    return (
      this.editingState == IPhoneNumberEditingState.SendingVerifyCode ||
      this.editingState == IPhoneNumberEditingState.WaitingVerifyCodeInput ||
      this.editingState == IPhoneNumberEditingState.VerifyingCode ||
      this.editingState == IPhoneNumberEditingState.VerificationCodeIncorrect ||
      this.editingState == IPhoneNumberEditingState.VerificationCodeFailed ||
      this.editingState == IPhoneNumberEditingState.WaitAMoment
    )
  }

  setNewPhoneNumber(value: string) {
    this.newPhoneNumber = value
  }

  setLang(value: string) {
    this.lang = value
  }

  setEditingState(editingState: IPhoneNumberEditingState) {
    this.editingState = editingState
  }

  sanitizePhoneNumber() {
    this.newPhoneNumber = phoneNumberValidator.normalize(this.newPhoneNumber, true)
  }

  reset() {
    this.code = ''
    this.lang = 'fi'
    this.newPhoneNumber = ''
    this.generalError = false
    this.editingState = IPhoneNumberEditingState.NotEditing
  }

  async sendVerifyCode() {
    runInAction(() => {
      this.setEditingState(IPhoneNumberEditingState.SendingVerifyCode)
    })

    try {
      const response = await serviceAdapter.sendPostRequest('/api/myaccount/changeconsumerphone', {
        phone: this.newPhoneNumber || infoStore.userInfo?.phoneVerification?.phoneNumber || '',
        language: this.lang,
      })

      if (response.status >= 400) {
        runInAction(() => {
          this.generalError = true
        })

        throw response
      }

      const data: IPhoneNumberVerificationResponse = await response.json()

      runInAction(() => {
        this.setEditingState(IPhoneNumberEditingState.WaitingVerifyCodeInput)
        this.generalError = false

        infoStore.updateExpirationData(data)
      })

      return data
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  errorMessageToEditingState(message: string): IPhoneNumberEditingState {
    if (!message) return IPhoneNumberEditingState.VerificationCodeIncorrect
    switch (message) {
      case 'too_many_failed_attempts':
        return IPhoneNumberEditingState.VerificationCodeFailed
      case 'too_soon_after_failed_attempt':
        return IPhoneNumberEditingState.WaitAMoment
      case 'incorrect_code':
      default:
        return IPhoneNumberEditingState.VerificationCodeIncorrect
    }
  }

  async verifyCode() {
    runInAction(() => {
      this.setEditingState(IPhoneNumberEditingState.VerifyingCode)
    })

    try {
      const response = await serviceAdapter.sendPostRequest('/api/myaccount/verifyconsumerphone', {
        phone: this.newPhoneNumber,
        code: this.code,
      })

      if (response.ok) {
        runInAction(() => {
          userStore.setPhoneNumber(this.newPhoneNumber)
          this.setEditingState(IPhoneNumberEditingState.CodeWasVerified)
        })

        return response
      }

      if (response.status >= 400 && response.status < 500) {
        const message = await response.json()

        runInAction(() => {
          this.setEditingState(this.errorMessageToEditingState(message))
        })

        throw message
      }

      if (response.status >= 500) {
        this.generalError = true
        throw new Error('Bad response from server')
      }

      throw VERIFY_PHONE_ERROR.INTERNAL_ERROR
    } catch (err) {
      throw err
    }
  }

  setCode(code: string) {
    this.code = code
  }

  setGeneralError(error: boolean) {
    this.generalError = error
  }
}

export const editPhoneNumberStore = new EditPhoneNumberStore()
