import { action, makeObservable, observable } from 'mobx'

import { serviceAdapter } from '../service/serviceAdapter'
import { encodeToBase64 } from '../utils/password'
import { IChangePasswordResult } from './dataModels/interfaces'

export class ChangePasswordStore {
  oldPassword: string = ''

  newPassword: string = ''

  newPasswordRepeat: string = ''

  savingChanges: boolean = false

  result: IChangePasswordResult | undefined = undefined

  hideErrorTimeout: ReturnType<typeof setTimeout> | undefined = undefined

  setOldPassword = (value: string) => {
    this.oldPassword = value
  }

  setNewPassword = (value: string) => {
    this.newPassword = value
  }

  setNewPasswordRepeat = (value: string) => {
    this.newPasswordRepeat = value
  }

  resetResult = () => {
    this.result = undefined
  }

  savePassword = async () => {
    this.savingChanges = true
    try {
      let response = await serviceAdapter.sendPostRequest('/api/myaccount/changepassword', {
        oldPassword: encodeToBase64(this.oldPassword),
        newPassword: encodeToBase64(this.newPassword),
        newPasswordVerification: encodeToBase64(this.newPasswordRepeat),
        passwordEncoded: true,
      })

      if (response.status >= 500 || response.headers.get('Content-Type') !== 'application/json') {
        this.result = { result: 'ERROR' }
        this.savingChanges = false
        this.hideErrorAfterTimeout()
        throw new Error('Bad response from server')
      }

      const data = await response.json()
      this.result = data
      this.hideErrorAfterTimeout()

      this.savingChanges = false
      this.oldPassword = ''
      this.newPassword = ''
      this.newPasswordRepeat = ''

      return data
    } catch (err) {
      console.error(err)
      return err
    }
  }

  constructor() {
    makeObservable(this, {
      oldPassword: observable,
      newPassword: observable,
      newPasswordRepeat: observable,
      savingChanges: observable,
      result: observable,
      setOldPassword: action,
      setNewPassword: action,
      setNewPasswordRepeat: action,
      resetResult: action,
      savePassword: action,
      hideErrorAfterTimeout: action,
    })
  }

  async hideErrorAfterTimeout() {
    if (this.hideErrorTimeout) {
      clearTimeout(this.hideErrorTimeout)
    }

    this.hideErrorTimeout = setTimeout(() => {
      this.result = undefined
    }, 5000)
  }
}

export const changePasswordStore = new ChangePasswordStore()
