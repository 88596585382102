import React from 'react'
import ReactDOM from 'react-dom/client'

import { Index } from './views'

declare global {
  interface Window {
    // TODO add list of envs
    postienv: any
    customVerificationTTl?: number
  }
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(<Index />)
