import { Button, CloseIcon, MenuIcon } from '@postidigital/posti-components/build/brand'
import { MobileMenu } from '@postidigital/posti-components/build/sections'
import { observer } from 'mobx-react-lite'
import React, { Dispatch, MouseEventHandler, SetStateAction, useCallback, useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router'
import styled, { css } from 'styled-components'
import useOnClickOutside from 'use-onclickoutside'

import { StoreContext } from '../../store'
import { breakpoint } from '../../utils'
import CookieLink from '../general/CookieLink'
import { LangSelectButton } from '../lang/LangSelectButton'
import { ServiceList } from '../ServiceList'
import { getExampleSideMenuStructure, SideMenuPropsMainItem } from '../SideNav/Structure'

const MobileNavComponent: React.FC<RouteComponentProps & { setMobileMenuOpen?: Dispatch<SetStateAction<boolean>> }> = ({
  history,
  location,
  setMobileMenuOpen,
}) => {
  const { t } = useTranslation()

  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const closeButtonRef = useRef<HTMLButtonElement | null>(null)
  const rootRef = useRef<HTMLDivElement | null>(null)
  const {
    infoStore,
    mainViewStore,
    userStore,
    layoutStore: { isAppWebView },
  } = useContext(StoreContext)
  const isConsumer = infoStore.isConsumer
  const hasOrgs = userStore.hasOrganizations
  const isStrongAuth = infoStore.isStrongAuth
  const structure = getExampleSideMenuStructure(
    history,
    location,
    t,
    isConsumer,
    hasOrgs,
    isStrongAuth,
    mainViewStore.reset
  ).filter((item) => !item.mobileOnly)

  const MenuWrapper = ({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: Dispatch<SetStateAction<boolean>> }) => {
    useEffect(() => {
      if (!isOpen) {
        buttonRef.current?.focus()
      } else {
        closeButtonRef.current?.focus()
      }
    }, [isOpen])

    const handleOutsideClick = () => {
      setIsOpen(false)
      setMobileMenuOpen?.(false)
    }

    const toggleOpen: MouseEventHandler<HTMLButtonElement> = useCallback(
      (e) => {
        setIsOpen((open) => {
          setMobileMenuOpen?.(!open)
          return !open
        })
        e.stopPropagation()
      },
      [setIsOpen]
    )

    useOnClickOutside(rootRef, handleOutsideClick)

    return (
      <div ref={rootRef}>
        <Button
          icon={!isOpen ? MenuIcon : CloseIcon}
          ref={buttonRef}
          size="sm"
          contentMode="tertiary"
          onClick={toggleOpen}
        />

        <StyledMobileMenu isAppWebView={isAppWebView} isBrand>
          {!isAppWebView && (
            <MobileMenu.HeaderButtons>
              <MobileMenu.CloseButton ref={closeButtonRef} onClick={toggleOpen} aria-label={t('general.close')} />
            </MobileMenu.HeaderButtons>
          )}
          <ModalContent isBrand>
            <MobileMenu.ContentGroup>
              {structure.map((item: SideMenuPropsMainItem) => (
                <StyledMobileMenuItem
                  aria-label={item.label}
                  icon={item.icon}
                  id={item.route}
                  key={item.route}
                  value={item.route}
                  onSelect={() => {
                    item.onClick(item.route)
                    handleOutsideClick()
                  }}
                  onButtonClick={() => null}
                  selected={item.isActive(item.route)}
                  redirectsToSubMenu={false}
                  chevronAriaLabel={item.label}
                  label={item.label}
                />
              ))}
              <ServiceList />
            </MobileMenu.ContentGroup>
            <MobileMenu.ContentGroup>
              {isAppWebView && (
                <div style={{ padding: '20px 0 0 20px' }}>
                  <CookieLink idString="side_nav_cookie_link" color="black" />
                </div>
              )}
              <MobileMenu.LanguageSelection
                langGroupLabel="Language"
                aria-label={t(`common.languageSelection`)}
                isBrand
              >
                <LangSelectButton isLast isInline />
              </MobileMenu.LanguageSelection>
            </MobileMenu.ContentGroup>
          </ModalContent>
        </StyledMobileMenu>
      </div>
    )
  }

  return (
    <MobileMenu.StateWrapper>
      {({ isOpen, setIsOpen }) => <MenuWrapper isOpen={isOpen} setIsOpen={setIsOpen} />}
    </MobileMenu.StateWrapper>
  )
}

export const MobileNav = withRouter(observer(MobileNavComponent))

export const StyledMobileMenu = styled(MobileMenu)<{ isAppWebView?: boolean }>(
  ({
    theme: {
      brand: { spacing },
    },
    isAppWebView = false,
  }) => {
    return css`
      @media ${breakpoint.mobile} {
        height: 100%;
        max-height: 100dvh;
      }

      ${isAppWebView &&
      css`
        @media ${breakpoint.mobile} {
          position: fixed;
          top: ${spacing.space26}rem;
          left: 0;
          max-height: calc(100dvh - ${spacing.space26}rem);
          box-shadow: 0 8px 22px rgba(27, 31, 35, 0.07);
        }
      `}
    `
  }
)

const StyledMobileMenuItem = styled(MobileMenu.Item)(
  ({
    selected,
    theme: {
      brand: { color },
    },
  }) => css`
    ${!selected &&
    css`
      & > div {
        background: transparent;
      }
    `}
    ${selected &&
    css`
      & > div,
      & > div:hover {
        background: ${color.gray20};
      }
    `}
    & > div:focus {
      box-shadow: ${color.postiOrange60} 0 0 7px;
    }
  `
)

const ModalContent = styled(MobileMenu.Content)(
  () => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  `
)
