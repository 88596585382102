import { Link } from '@postidigital/posti-components'
import styled, { css } from 'styled-components'

export const StyledList = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const StyledLink = styled(Link)(
  ({
    theme: {
      brand: { fontSize, fontWeight, color },
    },
  }) => css`
    font-size: ${fontSize.body.five}rem;
    font-weight: ${fontWeight.regular};
    color: ${color.blue50} !important;
  `
)
