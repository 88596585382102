import { sendPageInfo } from '@postidigital/posti-google-analytics'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Localization from '../../locales'
import { ConfusedErrorSvg } from '../../views/registration/registration.styles'
import { ContentSection } from '../layout'
import { ResponsiveBody } from '../typography/ResponsiveBody'
import { ResponsiveHeadline } from '../typography/ResponsiveHeadline'

interface IProps {
  invalidInvite: boolean
}

export const InviteErrorComponent: React.FC<IProps> = ({ invalidInvite }) => {
  const { t } = useTranslation()

  useEffect(() => {
    sendPageInfo({
      language: Localization.CurrentLocale,
      solution: 'oneaccount-onboarding',
      domain: window.location.hostname,
      pagePath: '/acceptinvite',
      pageName: invalidInvite ? 'oneaccount-onboarding-invalid' : 'oneaccount-onboarding-error',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ConfusedErrorSvg />
      <ResponsiveHeadline as="h1">
        {invalidInvite ? t('invite.invalidTitle') : t('invite.errorTitle')}
      </ResponsiveHeadline>

      <ContentSection>
        {invalidInvite ? (
          <>
            <ResponsiveBody>{t('invite.invalidContent')}</ResponsiveBody>
            <ResponsiveBody as="ul">
              <ResponsiveBody as="li">{t('invite.invalidReason1')}</ResponsiveBody>
              <ResponsiveBody as="li">{t('invite.invalidReason2')}</ResponsiveBody>
              <ResponsiveBody as="li">{t('invite.invalidReason3')}</ResponsiveBody>
            </ResponsiveBody>
            <ResponsiveBody>{t('invite.accept.supportMessage')}</ResponsiveBody>
          </>
        ) : (
          <ResponsiveBody>{t('invite.errorContent')}</ResponsiveBody>
        )}
      </ContentSection>
    </>
  )
}

export const InviteError = observer(InviteErrorComponent)
