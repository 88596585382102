import i18next, { Resource } from 'i18next'
import { action, computed, makeObservable, observable } from 'mobx'
import moment from 'moment'
import queryString from 'query-string'

import english from './en'
import finnish from './fi'
import swedish from './sv'

export enum Locale {
  FI = 'fi',
  EN = 'en',
  SV = 'sv',
}

export const defaultLocale = Locale.FI

class Localization {
  private locale: Locale | string = ''

  private initialized: boolean = false

  public i18nInstance = i18next.createInstance()

  public initialize = () => {
    if (this.initialized) {
      return
    }

    let lang = this.extractLocaleFromQueryString()
    if (!lang) {
      lang = this.getLocaleFromLocalStorage() as string
    }
    if (!lang) {
      lang = this.getLocaleFromHtmlElement()
    }
    if (!lang) {
      lang = defaultLocale
    }
    if (!this.isValidLocale(lang)) {
      lang = defaultLocale
    }

    // Initialize i18n
    const ns = 'MyAccount'
    const i18nResources = {
      [Locale.FI]: { [ns]: finnish },
      [Locale.EN]: { [ns]: english },
      [Locale.SV]: { [ns]: swedish },
    }
    this.i18nInstance.init({
      resources: i18nResources,
      lng: this.locale,
      whitelist: Object.values(Locale),
      fallbackLng: Locale.FI,
      ns: [ns],
      defaultNS: ns,
      debug: false,
      interpolation: { escapeValue: false },
      react: { useSuspense: true },
    })

    // Set locale
    this.initialized = true
    this.setLocale(lang)
  }

  public isValidLocale = (value: string) => {
    value = value?.toLowerCase()
    return Object.values(Locale).includes(value as any)
  }

  constructor() {
    makeObservable<Localization, 'locale' | 'initialized'>(this, {
      locale: observable,
      initialized: observable,
      i18nInstance: observable,
      initialize: action,
      CurrentLocale: computed,
      setLocale: action,
      addResourceBundle: action,
    })
  }

  get CurrentLocale() {
    return this.locale
  }

  public setLocale(locale: Locale | string) {
    if (!locale) {
      return
    }
    if (this.locale == locale) {
      return
    }
    if (!this.isValidLocale(locale)) {
      return
    }
    locale = locale.toLowerCase()

    // Store the selected language
    this.locale = locale
    window.localStorage?.setItem('USER_LOCALE', locale)

    // Update DOM to let screen readers know about the language
    document.documentElement.lang = locale

    // Update i18n
    this.i18nInstance.changeLanguage(this.locale)

    // Update momentjs
    moment.locale(locale)
    moment.updateLocale(locale, this.createMomentLocaleSpecification())
  }

  private createMomentLocaleSpecification = () => {
    const MOMENT_START_OF_YEAR = moment().startOf('year').startOf('day')
    const TODAY_IS_NEAR_YEAR_START = moment().startOf('day').isBefore(MOMENT_START_OF_YEAR.add(1, 'month'))
    const MOMENT_WEEK_AGO = moment().subtract(1, 'week').startOf('day')

    const defaultResolver = function (this: any) {
      // If the date is from this week, return day of week (Mon, Tue, ...)
      if (this.isSameOrAfter(MOMENT_WEEK_AGO)) {
        return 'ddd'
      }

      // If the date is from last year or before, return long date, only if the date is not near the start of a year
      if (this.isSameOrBefore(MOMENT_START_OF_YEAR) && !TODAY_IS_NEAR_YEAR_START) {
        return 'D.M.YYYY'
      }

      // Else, return the short date format
      return 'D.M.'
    }

    return {
      calendar: {
        sameDay: `[${this.i18nInstance.t('formatters.today')}]`,
        lastDay: `[${this.i18nInstance.t('formatters.yesterday')}]`,
        nextDay: defaultResolver,
        lastWeek: defaultResolver,
        nextWeek: defaultResolver,
        sameElse: defaultResolver,
      },
    }
  }

  public addResourceBundle = (locale: Locale, ns: string, resource: Resource) => {
    if (locale && ns && resource) {
      this.i18nInstance.addResourceBundle(locale, ns, resource)
    }
  }

  private extractLocaleFromQueryString = () => {
    let queryLangValue: string | null = ''

    // Get first lang parameter of query
    const query = queryString.parse(location.search)
    if (query && query.lang) {
      if (query.lang instanceof Array) {
        queryLangValue = query.lang.length > 0 ? query.lang[0] : ''
      } else {
        queryLangValue = query.lang
      }
    }

    // Exit early if lang is not in query
    if (!queryLangValue) {
      return ''
    }

    // Move lang parameter from address bar to local storage
    console.log(`Initializing locale from querystring to '${queryLangValue}'.`)
    queryLangValue = queryLangValue.toLowerCase()
    if (this.isValidLocale(queryLangValue)) {
      window.localStorage?.setItem('USER_LOCALE', queryLangValue)
    }

    // Remove lang parameter from querystring
    let newUrl = window.location.href.replace(/lang=.*?(&|$)/gi, '')
    if (/.+\?$/.test(newUrl)) {
      newUrl = newUrl.slice(0, -1)
    }
    if (/.+\&$/.test(newUrl)) {
      newUrl = newUrl.slice(0, -1)
    }
    if (window.location.href !== newUrl) {
      window.history.replaceState({}, document.title, newUrl)
    }

    return queryLangValue
  }

  private getLocaleFromHtmlElement = () => {
    return typeof document !== 'undefined' ? document.documentElement?.lang?.toLowerCase() : ''
  }

  private getLocaleFromLocalStorage = () => {
    return window.localStorage?.getItem('USER_LOCALE')
  }
}

// Initialize the localization before exporting it
const localization = new Localization()
localization.initialize()
export default localization

// Export other useful functions
export const i18nInstance = localization.i18nInstance
export const t = localization.i18nInstance.t.bind(localization.i18nInstance)
