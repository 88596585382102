import { Body, CheckmarkIcon, Headline } from '@postidigital/posti-components/build/brand'
import { XyzTheme } from '@postidigital/posti-theme'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'

import Localization from '../../locales'
import { ILocalizedNameModel, IOrganizations, IRoleGroupModel } from '../../store/dataModels/interfaces'
import { ContentSection } from '../layout'

interface IProps {
  organisation: IOrganizations
}

const RolesListComponent: React.FC<IProps> = ({ organisation }) => {
  return (
    <>
      {organisation.selectedRoleGroups
        // Filter out main user role, as it's taken care of in another component
        .filter((roleGroup) => {
          return roleGroup.value !== 'OrganizationAdministration'
        })
        // draw UI for the rest of the role groups
        .map((roleGroup) => {
          return <RoleGroup key={roleGroup.value} roleGroup={roleGroup} />
        })}
    </>
  )
}

export const RolesList = observer(RolesListComponent)

interface IRoleGroupProps {
  roleGroup: IRoleGroupModel
  disabled?: boolean
}

const RoleGroupComponent: React.FC<IRoleGroupProps> = ({ roleGroup }) => {
  const { name, roles } = roleGroup

  const currentLang = Localization.CurrentLocale

  return (
    <ContentSection>
      <Headline as="div" size="Eight">
        {name[currentLang as ILocalizedNameModel]}
      </Headline>
      {roles.map((role) => {
        return (
          <StyledRoleRow key={role.value}>
            <Body as="span" size="Four">
              {role.name[currentLang as ILocalizedNameModel]}
            </Body>
            <CheckmarkIcon color={XyzTheme.color.neutralPassiveGray} width="1.5em" height="1.5em" />
          </StyledRoleRow>
        )
      })}
    </ContentSection>
  )
}

const RoleGroup = observer(RoleGroupComponent)

const StyledRoleRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.xyz.color.neutralGray5};
`
