import { ExternalLinkIcon, HomeIcon, Notification } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { ContentSection, SideImageContainerContent } from '../../components'
import { StaticInputLayout } from '../../components/StaticInputLayout'
import { ResponsiveBody } from '../../components/typography/ResponsiveBody'
import { ResponsiveHeadline } from '../../components/typography/ResponsiveHeadline'
import { ResponsiveLink } from '../../components/typography/ResponsiveLink'
import Localization from '../../locales'
import { StoreContext } from '../../store'

type AddressObject = {
  city?: string
  postalcode?: string
  streetaddress?: string
  [key: string]: any
}

const AddressViewComponent: React.FC = () => {
  const { t } = useTranslation()
  const { userStore } = useContext(StoreContext)
  const { addressLines } = userStore?.identities?.consumerIdentity?.postalAddress

  const addressObject: AddressObject = useMemo(() => {
    return addressLines?.reduce((acc, item) => ({ ...acc, [item.type]: item.value }), {})
  }, [addressLines])

  return (
    <SideImageContainerContent>
      <ResponsiveHeadline as="h1">{t(`sideMenu.myAddress`)}</ResponsiveHeadline>
      <ContentSection gap="lg">
        {Object.values(addressObject).some((val) => !!val) ? (
          <AddressBlock>
            <StaticInputLayout
              label={t(`detailsView.streetAddress`)}
              content={addressObject.streetaddress || ''}
              Icon={HomeIcon}
            />
            <StaticInputLayout label={t(`detailsView.postalCode`)} content={addressObject.postalcode || ''} />
            <StaticInputLayout label={t(`detailsView.city`)} content={addressObject.city || ''} />
          </AddressBlock>
        ) : (
          <Notification dismissible={false} contentType="warning">
            {t('detailsView.addressMissingContent')}
          </Notification>
        )}
        <ResponsiveBody>{t(`detailsView.addressInfo`)}</ResponsiveBody>
        <ResponsiveLink
          onClick={() =>
            window.open(`https://yhteystietoni.posti.fi/ythp/?lang=${Localization.CurrentLocale}`, '_blank')
          }
          id="changeaddress_link"
          icon={ExternalLinkIcon}
          tabIndex={0}
        >
          {t(`detailsView.linkInfo`)}
        </ResponsiveLink>
      </ContentSection>
    </SideImageContainerContent>
  )
}

export const AddressView = observer(AddressViewComponent)

const AddressBlock = styled('div')(
  ({
    theme: {
      brand: { color },
    },
  }) => css`
    border: 1px solid ${color.gray10};
    border-left-color: transparent;
    border-right-color: transparent;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;

    & :first-child {
      width: 100%;
    }
  `
)
