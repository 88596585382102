import { EnvelopeIcon } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { ChangeEventHandler, FC, FocusEventHandler, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { StoreContext } from '../../../store'
import { AbsoluteMessageInput } from '../../general/input'

const EditEmailInput: FC = () => {
  const { t } = useTranslation()
  const { editEmailStore } = useContext(StoreContext)
  const { newEmail, changeSuccess, isNewEmailValid } = editEmailStore
  const [touched, setTouched] = useState<boolean>(false)

  const handleInputBlur: FocusEventHandler<HTMLInputElement> = useCallback(() => {
    setTouched(true)
  }, [])

  const handleEmail: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      editEmailStore.setNewEmail(event.target.value)
    },
    [editEmailStore]
  )

  if (changeSuccess) {
    return null
  }

  return (
    <AbsoluteMessageInput
      autoFocus
      lightBackground
      id="email-input"
      value={newEmail}
      onChange={handleEmail}
      onBlur={handleInputBlur}
      isInvalid={touched && !isNewEmailValid}
      label={t(`mainView.editEmail.newEmail`)}
      leftTemplate={<EnvelopeIcon width="24px" />}
      message={touched && !isNewEmailValid ? t(`mainView.editEmail.validationError`) : undefined}
    />
  )
}

export default observer(EditEmailInput)
