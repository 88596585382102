import { BrandTheme } from '@postidigital/posti-theme'
import React, { PropsWithChildren, useEffect } from 'react'
import { createGlobalStyle } from 'styled-components'

import { breakpoint2 } from '../../utils'

const PostiGlobalStyleDefinition = createGlobalStyle`
  body {
    background-color: ${BrandTheme.color.white};
    width: 100%;
    color: ${BrandTheme.color.gray70};

    @media ${breakpoint2.tabletAndUp} {
      background-color: ${BrandTheme.color.gray1};
    }
  }

  #root {
    position: relative;
    display: flex;
    min-height: 100vh;
    min-width: 100%;
    flex-direction: column;
    padding-bottom: 0;
  }

  #PostiMainContent {
    flex: 1;
    display: flex;
    min-width: 320px;
    position: relative;
    flex-direction: column;
  }
`

export const PostiPage: React.FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    const titleElem = document.querySelector('#PostiMainContent h1')
    if (titleElem) {
      document.title = (titleElem as HTMLElement).innerText
    }
  })

  return (
    <>
      <PostiGlobalStyleDefinition />
      <main role="main" id="PostiMainContent">
        {children}
      </main>
    </>
  )
}
