import { Headline } from '@postidigital/posti-components/build/brand'
import { XyzTheme } from '@postidigital/posti-theme'
import React, { PropsWithChildren, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { StoreContext } from '../store'
import { IMainViewState } from '../store/dataModels/interfaces'
import { ResponsiveHeadline } from './typography/ResponsiveHeadline'

export const ViewTitle: React.FC<PropsWithChildren> = ({ children }) => {
  const { layoutStore } = useContext(StoreContext)
  const { isDesktop } = layoutStore

  // TODO bug found here: isDesktop @computed requires page reload to update.
  return (
    <Headline as="h1" size={isDesktop ? 'Four' : 'Five'} color={XyzTheme.color.neutralBlack}>
      {children}
    </Headline>
  )
}

export const SubTitle: React.FC<PropsWithChildren> = ({ children }) => {
  const { layoutStore } = useContext(StoreContext)
  const { isDesktop } = layoutStore

  return (
    <Headline
      as="h2"
      size={isDesktop ? 'Five' : 'Five'}
      color={XyzTheme.color.neutralBlack}
      style={{ marginTop: '2rem' }}
    >
      {children}
    </Headline>
  )
}

export const MainViewTitles: React.FC = () => {
  const { mainViewStore } = useContext(StoreContext)
  const { t } = useTranslation()

  const getTranslatedTitle = (state: IMainViewState): string => {
    switch (+state) {
      case IMainViewState.EditEmail:
        return t(`mainView.editEmail.title`)

      case IMainViewState.ChangePassword:
        return t(`mainView.changePassword.title`)

      case IMainViewState.EditPreferredName:
        return t(`mainView.editPreferredName.title`)

      case IMainViewState.EditPhone:
        return t(`mainView.editPhoneNumber.title`)

      case IMainViewState.VerifyPhone:
        return t('registration.verifyPhone.title')

      default:
        return t(`mainView.title`)
    }
  }

  return <ResponsiveHeadline as="h1">{getTranslatedTitle(mainViewStore.state)}</ResponsiveHeadline>
}
