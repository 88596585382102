import { XyzTheme } from '@postidigital/posti-theme'
import styled, { css } from 'styled-components'

import { breakpoint2 } from '../../utils'

export const PageSection = styled.section`
  margin-top: 24px;
  margin-left: 24px;
  margin-right: 24px;

  @media (min-width: ${XyzTheme.breakpoint.md}) {
    margin-top: 48px;
    margin-left: 0;
    margin-right: 0;
  }
`

export const RoundedPageSection = styled('section')(
  ({
    theme: {
      brand: { color, spacing },
    },
  }) => css`
    background-color: ${color.white};
    border-radius: ${spacing.space4}rem;
    display: grid;
    grid-template-columns: 1fr;
    box-shadow: 0 15px 22px rgba(0, 0, 0, 0.06), 0 3px 8px rgba(0, 0, 0, 0.08), 0 1px 1px rgba(0, 0, 0, 0.08),
      0 3px 1px rgba(0, 0, 0, 0.04);

    & > * {
      padding: ${spacing.space5}rem ${spacing.space6}rem;

      &:not(:last-child) {
        border-bottom: 1px solid currentcolor;
      }

      & label {
        margin: 0;
      }
    }
  `
)

export const ContentSection = styled('div')<{ gap?: 'sm' | 'md' | 'lg' | 'xl'; top?: boolean }>(
  ({
    theme: {
      brand: { spacing },
    },
    gap = 'md',
    top = true,
  }) => {
    const space: Record<typeof gap, number> = {
      sm: spacing.space2,
      md: spacing.space4,
      lg: spacing.space6,
      xl: spacing.space10,
    }

    return css`
      ${() => top && `padding-top: ${spacing.space8}rem;`}
      display: flex;
      flex-direction: column;
      justify-content: start;
      gap: ${space[gap]}rem;

      @media ${breakpoint2.tabletAndUp} {
        ${() => top && `padding-top: ${spacing.space10}rem;`}
      }
    `
  }
)

export const ButtonContainer = styled.div<{ position?: 'start' | 'center' | 'end' | 'space-between' }>(
  ({
    theme: {
      brand: { spacing, fontSize },
    },
    position = 'start',
  }) => css`
    width: 100%;
    display: flex;
    gap: ${spacing.space10}rem;
    flex-direction: column-reverse;
    margin-top: ${spacing.space14}rem;

    button {
      width: 100%;
      padding: ${spacing.space4}rem;
      justify-content: center;
      font-size: ${fontSize.label.three}rem;
    }

    @media ${breakpoint2.mobile} {
      &.swap-button-order-in-mobile {
        flex-direction: column;
      }
    }

    @media ${breakpoint2.tabletAndUp} {
      flex-direction: row;
      justify-content: ${position};
      margin-top: ${spacing.space16}rem;
      gap: ${spacing.space8}rem;

      button {
        padding: ${spacing.space5}rem ${spacing.space8}rem;
        margin: 0;
        width: auto;
      }
    }
  `
)

export const ContentContainer = styled('div')(
  ({
    theme: {
      brand: { spacing },
    },
  }) => css`
    padding: ${spacing.space9}rem ${spacing.space4}rem ${spacing.space26}rem;
    margin-top: 0;
    width: 100%;

    @media ${breakpoint2.tabletAndUp} {
      padding: ${spacing.space10}rem ${spacing.space14}rem ${spacing.space32}rem;
      margin: 0 auto;
      max-width: 47.2rem;
    }
  `
)
