import { EnvelopeIcon } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { FC, FormEventHandler, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import {
  CancelButton,
  EditButton,
  EditEmailInput,
  EditEmailNotifications,
  SaveButton,
  StyledButtonWrapper,
} from '../../components'
import { StaticInputLayout } from '../../components/StaticInputLayout'
import { StoreContext } from '../../store'
import { breakpoint2 } from '../../utils'
import { IEditComponentProps } from './accountRecovery'

export const RecoverEmail: FC<IEditComponentProps> = observer(({ editState, type, toggleEditState }) => {
  const { t } = useTranslation()
  const { editEmailStore, userStore, infoStore } = useContext(StoreContext)
  const { savingChanges, isNewEmailValid, changeSuccess } = editEmailStore

  const handleSave: FormEventHandler<HTMLFormElement> = useCallback(
    async (event) => {
      event.preventDefault()
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()

      if (!isNewEmailValid) {
        return null
      }

      if (savingChanges) {
        return null
      }

      try {
        await editEmailStore.saveNewEmail()
        toggleEditState(type)
      } catch (err) {}
    },
    [editEmailStore, isNewEmailValid, savingChanges, toggleEditState, type]
  )

  const toggleEdit = useCallback(() => {
    toggleEditState(type)
    editEmailStore.reset()
    editEmailStore.currentEmail = infoStore.userInfo.email
  }, [editEmailStore, infoStore.userInfo.email, toggleEditState, type])

  if (!editState) {
    return (
      <>
        <StyledButtonWrapper>
          <StaticInputLayout label={t(`general.email`)} content={userStore.identities.email} Icon={EnvelopeIcon} />
          <EditButton id="edit_email_button" onClick={toggleEdit} />
        </StyledButtonWrapper>
        <EditEmailNotifications />
      </>
    )
  }

  return (
    <EmailForm id="edit_account_email_form" onSubmit={handleSave}>
      <EditEmailInput />
      {!changeSuccess && (
        <>
          <CancelButton type="button" id="cancel_email_change_button" onClick={toggleEdit} />
          <SaveButton type="submit" id="change_email_button" disabled={!isNewEmailValid} />
        </>
      )}
      <EditEmailNotifications />
    </EmailForm>
  )
})

export const EmailForm = styled('form')(
  () => css`
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: end;
    width: 100%;
    flex-wrap: wrap;

    button {
      width: 100%;
    }

    @media ${breakpoint2.tabletAndUp} {
      button {
        width: auto;
      }
    }
  `
)
