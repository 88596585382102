import { BrandTheme, XyzTheme } from '@postidigital/posti-theme'
import React, { ReactNode } from 'react'
import { ThemeProvider } from 'styled-components'

const combinedTheme = { ...XyzTheme, xyz: XyzTheme, brand: BrandTheme }

function ThemeWrapper({ children }: { children: ReactNode }) {
  return (
    <ThemeProvider theme={combinedTheme}>
      <>{children}</>
    </ThemeProvider>
  )
}

export default ThemeWrapper
