import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import { StoreContext } from '../../store'

const TerminateAccountGuardComponent: React.FC<RouteComponentProps & { children?: any }> = ({ location, children }) => {
  const { terminateAccountViewStore } = useContext(StoreContext)
  terminateAccountViewStore.handleLocationChange(location.pathname)
  return children
}
export const TerminateAccountGuard = withRouter(observer(TerminateAccountGuardComponent))
