import { AdminIcon } from '@postidigital/posti-components/build/brand'
import { XyzTheme } from '@postidigital/posti-theme'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled, { css } from 'styled-components'

interface IProps {
  name: string
  email: string
}

const MainUserSectionComponent: React.FC<IProps> = ({ name, email }) => {
  return (
    <StyledTitleWithSubTitle>
      <div>
        <AdminIcon style={{ height: 18, width: 20 }} color={XyzTheme.color.brandPurple} />
      </div>

      <StyledTextWrapper>
        <StyledName>{name}</StyledName>
        <StyledEmail>{email}</StyledEmail>
      </StyledTextWrapper>
    </StyledTitleWithSubTitle>
  )
}

export const MainUserSection = observer(MainUserSectionComponent)

const StyledTitleWithSubTitle = styled('div')`
  width: 340px;
  display: flex;
  flex-direction: row;
  border: 1px solid #edbdbd;
  box-sizing: border-box;
  border-radius: 8px;

  padding: 16px;
  margin-right: 8px;
  margin-bottom: 8px;
`

const StyledName = styled.div(
  () => css`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 163%;
    color: #000000;

    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;
  `
)

const StyledEmail = styled.div<{ boldTitle?: boolean }>(
  () => css`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 163%;
    color: #000000;

    overflow: hidden;
    white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis;
  `
)

const StyledTextWrapper = styled.div(
  () => css`
    margin-left: 8px;
    width: 250px;
  `
)
