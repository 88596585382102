import { Input, MultipleUsersIcon, Notification, ProfileIcon } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { FeatureFlags } from '../../featureFlags'
import { StoreContext } from '../../store'
import { validateName } from '../../utils/validation'
import { EditButton, SaveNameButton } from '../buttons/buttons'
import { ContentSection } from '../layout'
import { StaticInputLayout } from '../StaticInputLayout'

enum NameField {
  firstName,
  lastName,
}

const NameFieldsComponent: React.FC = () => {
  const { t } = useTranslation()
  const { changeNameStore, userStore, infoStore } = useContext(StoreContext)
  const [isNameEditing, setIsNameEditing] = useState(false)

  const {
    firstName,
    lastName,
    saveNewName,
    savingChanges,
    setFirstName,
    setLastName,
    result,
    hasError,
  } = changeNameStore

  useEffect(() => {
    if (userStore.identities?.firstName) {
      setFirstName(userStore.identities?.firstName)
    }
    if (userStore.identities?.lastName) {
      setLastName(userStore.identities?.lastName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const notEmpty = (value: any) => value.replace(/\s/g, '').length > 0 && typeof value === 'string' && value.length > 0

  const validateField = (value: any) => notEmpty(value) && validateName(value)

  const getMessage = (nameField: NameField): string => {
    switch (nameField) {
      case NameField.firstName: {
        if (!notEmpty(firstName)) {
          return t('mainView.changeName.invalidEmptyFirstName')
        }
        if (!validateName(firstName)) {
          return t('mainView.changeName.invalidCharacterFirstName')
        }
        return ''
      }
      case NameField.lastName: {
        if (!notEmpty(lastName)) {
          return t('mainView.changeName.invalidEmptyLastName')
        }
        if (!validateName(lastName)) {
          return t('mainView.changeName.invalidCharacterLastName')
        }
        return ''
      }
      default:
        return ''
    }
  }

  const fieldMessages = {
    firstName: getMessage(NameField.firstName),
    lastName: getMessage(NameField.lastName),
  }

  const canSend = (): boolean => {
    return savingChanges || (validateField(firstName) && validateField(lastName))
  }

  const saveName = async () => {
    if (!canSend()) {
      return
    }
    const response = await saveNewName()
    if (response?.result === 'OK') {
      setIsNameEditing(false)
    }
  }

  const renderNotification = () => {
    if (!hasError) {
      return null
    }

    return (
      <StyledNotification contentType="alert" dismissible={false}>
        <span>{t(`mainView.changeName.error.${result?.result}`)}</span>
      </StyledNotification>
    )
  }

  const renderNameText = () => (
    <>
      <StaticInputLayout
        label={t(`mainView.firstNames`)}
        content={userStore.identities.firstName}
        Icon={MultipleUsersIcon}
      />
      <StaticInputLayout label={t(`general.lastName`)} content={userStore.identities.lastName} Icon={ProfileIcon} />
    </>
  )

  const renderEditableNameFields = () => (
    <>
      <div id="firstName">
        <Input
          autoFocus
          lightBackground
          disabled={savingChanges}
          id="firstName-input"
          label={t('mainView.changeName.firstName')}
          value={firstName}
          message={fieldMessages.firstName}
          isInvalid={!validateField(firstName)}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div id="lastName">
        <Input
          lightBackground
          disabled={savingChanges}
          id="lastName-input"
          label={t('mainView.changeName.lastName')}
          value={lastName}
          message={fieldMessages.lastName}
          isInvalid={!validateField(lastName)}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <StyledButtonContainer>
        <SaveNameButton onClick={saveName} disabled={!canSend()} loading={savingChanges} id={'SaveNameButtonID'} />
      </StyledButtonContainer>
    </>
  )

  if (!infoStore.isConsumer) {
    if (isNameEditing) {
      return (
        <ContentSection top={false}>
          {renderNotification()}
          {renderEditableNameFields()}
        </ContentSection>
      )
    }

    return (
      <>
        {renderNotification()}
        {renderNameText()}
        {FeatureFlags.EnableBusinessAccountNameChange && (
          <StyledButtonContainer>
            <EditButton onClick={() => setIsNameEditing(true)} id={'EditButtonID'} />
          </StyledButtonContainer>
        )}
      </>
    )
  }

  return renderNameText()
}

export const NameFields = observer(NameFieldsComponent)

const StyledButtonContainer = styled('div')`
  margin-top: 16px;
`

const StyledNotification = styled(Notification)`
  max-width: 420px;
`
