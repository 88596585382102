import { Modal, ModalStateWrapper } from '@postidigital/posti-components'
import { ArrowLeftIcon, BinIcon, Button, Checkbox, CloseIcon } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useRef, useState } from 'react'
import { renderToString } from 'react-dom/server'
import { Trans, useTranslation } from 'react-i18next'

import {
  BackButton,
  ButtonContainer,
  ContentContainer,
  ContentSection,
  PostiPage,
  PostiPageFooter,
  RoundedPageSection,
  TopNav,
} from '../../components'
import { ResponsiveBody } from '../../components/typography/ResponsiveBody'
import { ResponsiveHeadline } from '../../components/typography/ResponsiveHeadline'
import { ResponsiveLink } from '../../components/typography/ResponsiveLink'
import { FeatureFlags } from '../../featureFlags'
import { AccountTerminationTypeEnum, StoreContext } from '../../store'

interface TerminateAccountConfirmationViewProps {
  onBackClick: () => void
  onCancelTermination: () => void
  onConfirmTermination: () => void
}

const TerminateAccountConfirmationViewComponent: React.FC<TerminateAccountConfirmationViewProps> = ({
  onBackClick,
  onConfirmTermination,
  onCancelTermination,
}) => {
  const { t } = useTranslation()
  const { terminateAccountViewStore: store } = useContext(StoreContext)

  const [isCheckboxRequiredVisible, setCheckboxRequiredVisible] = useState(false)
  const [inputRef, setInputFocus] = useFocus()

  const isDeleteButtonEnabled = useCallback(() => {
    if (store.terminationType === AccountTerminationTypeEnum.NotDefined) {
      return false
    }

    return store.confirmationUnderstoodChecked
  }, [store.confirmationUnderstoodChecked, store.terminationType])

  const onNextClick = useCallback(() => {
    if (!isDeleteButtonEnabled()) {
      setCheckboxRequiredVisible(true)
      setInputFocus()
    } else {
      store.areYouSureModalVisible = true
    }
  }, [isDeleteButtonEnabled, setInputFocus, store])

  const onUnderstoodClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCheckboxRequiredVisible(false)
      store.confirmationUnderstoodChecked = event.target.checked
    },
    [store]
  )

  //
  // Are you sure modal
  //
  const AreYouSureModal = () => {
    let modalTitle = ''
    let modalContent = ''
    switch (store.terminationType) {
      case AccountTerminationTypeEnum.OrphanedAccountTermination:
        modalTitle = t('termination.confirmation.OrphanedAccountTermination.dialogTitle')
        modalContent = t('termination.confirmation.OrphanedAccountTermination.dialogContent')
        break
      case AccountTerminationTypeEnum.BusinessAccountTermination:
        modalTitle = t('termination.confirmation.BusinessAccountTermination.dialogTitle')
        modalContent = t('termination.confirmation.BusinessAccountTermination.dialogContent')
        break
      case AccountTerminationTypeEnum.ConsumerAccountTermination:
        modalTitle = t('termination.confirmation.ConsumerAccountTermination.dialogTitle')
        modalContent = t('termination.confirmation.ConsumerAccountTermination.dialogContent')
        break
      default:
        const errMsg = `Account type '${store.terminationType}' not handled in confirmation modal dialog!`
        console.log(errMsg)
        throw new Error(errMsg)
    }

    return (
      <ModalStateWrapper
        // open can be set to always true in this case, because areYouSureModalVisible observable handles the visibility
        open={true}
      >
        <Modal>
          <Modal.CloseButton
            aria-label={t('termination.confirmation.dialog.x_tooltip')}
            onClick={() => (store.areYouSureModalVisible = false)}
            icon={CloseIcon}
            type="button"
          />
          <Modal.Content>
            <Modal.Header>
              <Modal.Title size="Five">{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.MainContent>
              <p>{modalContent}</p>
              <Modal.ButtonWrapper>
                <ButtonContainer position="end">
                  <Button
                    type="button"
                    size="sm"
                    contentMode="secondary"
                    hasBackground={false}
                    onClick={onCancelTermination}
                  >
                    {t('termination.confirmation.dialog.cancel')}
                  </Button>
                  <Button type="button" hasBackground size="sm" onClick={onConfirmTermination}>
                    {t('termination.confirmation.dialog.confirm')}
                  </Button>
                </ButtonContainer>
              </Modal.ButtonWrapper>
            </Modal.MainContent>
          </Modal.Content>
        </Modal>
      </ModalStateWrapper>
    )
  }

  //
  // MAIN
  //

  if (store.terminationType == AccountTerminationTypeEnum.NotDefined) {
    throw new Error('Account termination type not defined!')
  }

  let warningKey = ''
  switch (store.terminationType) {
    case AccountTerminationTypeEnum.OrphanedAccountTermination:
      warningKey = 'termination.confirmation.OrphanedAccountTermination.warning'
      break
    case AccountTerminationTypeEnum.BusinessAccountTermination:
      warningKey = store.isConsumerUser
        ? 'termination.confirmation.BusinessAccountTermination.warning_consumer'
        : 'termination.confirmation.BusinessAccountTermination.warning_businessOnly'
      break
    case AccountTerminationTypeEnum.ConsumerAccountTermination:
      warningKey = store.isBusinessUser
        ? 'termination.confirmation.ConsumerAccountTermination.warning_business'
        : 'termination.confirmation.ConsumerAccountTermination.warning_consumerOnly'
      break
    default:
      const errMsg = `Account type '${store.terminationType}' not supported!`
      console.log(errMsg)
      throw new Error(errMsg)
  }

  const allowedTransComponents: any = { p: <p />, italic: <i />, strong: <strong /> }
  return (
    <>
      <TopNav hideNavigation={true} hasShadow={false} fullWidth={true} showCookieIcon />
      <PostiPage>
        <ContentContainer>
          <ResponsiveLink size="lg" icon={ArrowLeftIcon} iconPosition="left" href="/">
            {t('termination.confirmation.backlink')}
          </ResponsiveLink>
          <ContentSection>
            <ResponsiveHeadline as="h1">
              {t(`termination.confirmation.${store.terminationType}.title`)}
            </ResponsiveHeadline>
            <ResponsiveBody>
              <b>{t(`termination.confirmation.${store.terminationType}.subtitle`)}</b>
            </ResponsiveBody>
          </ContentSection>
          <ContentSection gap="lg">
            <RoundedPageSection>
              <div
                dangerouslySetInnerHTML={{
                  __html: t(`termination.confirmation.${store.terminationType}.content`, {
                    name: store.firstname ? ` ${store.firstname}` : '',
                    feedbackThanks: FeatureFlags.EnableAccountTerminationFeedback
                      ? t('termination.confirmation.thankYouForFeedback')
                      : '',
                    warning: t(warningKey),
                    organizationList: store.userOrganizations
                      ? store.userOrganizations
                          .map((o) =>
                            renderToString(
                              <li>
                                {o.orgName} ({o.orgId})
                              </li>
                            )
                          )
                          .join('')
                      : '',
                  }),
                }}
              />
            </RoundedPageSection>
            <Checkbox
              id="checkUnderstood"
              value="understood"
              isInvalid={isCheckboxRequiredVisible}
              errorMessage={t('termination.confirmation.input_required')}
              ref={inputRef}
              checked={store.confirmationUnderstoodChecked}
              onChange={onUnderstoodClick}
              required={true}
              labelPosition="right"
              label={
                <Trans
                  i18nKey={`termination.confirmation.${store.terminationType}.understand`}
                  components={allowedTransComponents}
                />
              }
            />
          </ContentSection>
          <ButtonContainer position="space-between">
            <BackButton id="btnBack" onClick={onBackClick}>
              {t('termination.confirmation.back')}
            </BackButton>
            <Button hasBackground id="btnDeleteAccount" icon={BinIcon} onClick={onNextClick}>
              {t('termination.confirmation.next')}
            </Button>
          </ButtonContainer>
          {store.areYouSureModalVisible && AreYouSureModal()}
          <PostiPageFooter />
        </ContentContainer>
      </PostiPage>
    </>
  )
}

const useFocus = (): [React.MutableRefObject<any>, () => void] => {
  const htmlElRef = useRef<HTMLInputElement>(null)
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus()
  }
  return [htmlElRef, setFocus]
}

export const TerminateAccountConfirmationView = observer(TerminateAccountConfirmationViewComponent)
