import { action, computed, makeObservable, observable } from 'mobx'

export const DESKTOP_BREAKPOINT_WIDTH_PX = 768

/**
 * Mobile app web view identification strings in userAgent;
 */
const webViewUserAgentStrings = ['OmaPostiAndroid', 'OmaPosti iOS']

/**
 * Store to handle layout related changes
 */
export class LayoutStore {
  windowHeight: number = this.screenDimensions.height

  windowWidth: number = this.screenDimensions.width

  get isAppWebView(): boolean {
    const userAgent = navigator.userAgent

    return webViewUserAgentStrings.some((str) => userAgent.includes(str))
  }

  get isMobile() {
    return this.windowWidth < DESKTOP_BREAKPOINT_WIDTH_PX
  }

  get isDesktop() {
    return this.windowWidth >= DESKTOP_BREAKPOINT_WIDTH_PX
  }

  get screenDimensions() {
    const {
      outerWidth,
      innerWidth,
      outerHeight,
      innerHeight,
      screen: { availHeight, availWidth },
    } = window

    // AvailWidth & availHeight will have mixed values in multi-screen setups.
    // They work properly for most mobile clients however.
    return {
      width: (availWidth > innerWidth ? innerWidth : availWidth) || outerWidth,
      height: (availHeight > innerHeight ? innerHeight : availHeight) || outerHeight,
    }
  }

  constructor() {
    makeObservable(this, {
      windowHeight: observable,
      windowWidth: observable,
      isMobile: computed,
      isDesktop: computed,
      setWindowHeight: action,
      setWindowWidth: action,
    })

    const setDimensions = () => {
      const { width, height } = this.screenDimensions
      this.setWindowHeight(height)
      this.setWindowWidth(width)
    }

    window.onresize = debounce(setDimensions, 250, false)
  }

  setWindowHeight = (height: number) => {
    this.windowHeight = height
  }

  setWindowWidth = (width: number) => {
    this.windowWidth = width
  }
}

export const layoutStore = new LayoutStore()

// source: https://davidwalsh.name/javascript-debounce-function
function debounce(func: any, wait: any, immediate: any) {
  let timeout: ReturnType<typeof setTimeout> | undefined
  return function () {
    // @ts-ignore
    let context = this,
      args = arguments
    let later = function () {
      timeout = undefined
      if (!immediate) func.apply(context, args)
    }
    let callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}
