import moment, { Moment } from 'moment'
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

interface ICountdownArg {
  time: number
  onFinish: Function
  interval?: number
  timeUnit?: 'minutes' | 'seconds'
}

export interface ICountdownResponse {
  reset: () => void
  expired: boolean
  data: {
    days: number
    hours: number
    minutes: number
    seconds: number
  }
}

/**
 * @description A hook for implementing countdowns, returning current countdown state and executing a callback when countdown ends
 * @param time - Countdown time in seconds
 * @param onFinish
 * @param interval - countdown evaluation interval in milliseconds, default one second
 * @param timeUnit - unit of the time argument
 */
export const useCountdown = ({
  time,
  onFinish,
  interval = 1000,
  timeUnit = 'minutes',
}: ICountdownArg): ICountdownResponse => {
  const [targetDateTime, setTargetDateTime] = useState<Moment>(moment().add(time, timeUnit))
  const intervalRef: MutableRefObject<ReturnType<typeof setInterval> | null> = useRef(null)

  const calculateTimeLeft = useCallback(() => {
    const now = moment()
    const diff = targetDateTime.diff(now)
    const duration = moment.duration(diff)
    const days = Math.floor(Math.abs(duration.asDays()))
    const hours = Math.abs(duration.hours())
    const minutes = Math.abs(duration.minutes())
    const seconds = Math.abs(duration.seconds())

    return { days, hours, minutes, seconds }
  }, [targetDateTime])

  const [{ days, hours, minutes, seconds }, setTimeLeft] = useState<{
    days: number
    hours: number
    minutes: number
    seconds: number
  }>(calculateTimeLeft())

  const expired = useMemo(() => [days, hours, minutes, seconds].every((time) => time === 0), [
    days,
    hours,
    minutes,
    seconds,
  ])

  const handleReset = useCallback(() => {
    setTargetDateTime(moment().add(time, timeUnit))
  }, [time, timeUnit])

  const timeoutCB = useCallback(() => {
    const now = moment()

    const isExpired = now.isAfter(targetDateTime)

    if (isExpired) {
      clearInterval(intervalRef.current!)
      return onFinish?.()
    }

    return setTimeLeft(calculateTimeLeft())
  }, [calculateTimeLeft, onFinish, targetDateTime])

  useEffect(() => {
    intervalRef.current = setInterval(timeoutCB, interval)

    return () => clearInterval(intervalRef.current!)
  }, [targetDateTime, calculateTimeLeft, expired, interval, timeoutCB])

  return {
    expired,
    reset: handleReset,
    data: { days, hours, minutes, seconds },
  }
}
