import { ButtonGroup, LanguageBox, NavDropdown } from '@postidigital/posti-components'
import { LanguageIcon } from '@postidigital/posti-components/build/brand'
import { ButtonGroupProps } from '@postidigital/posti-components/build/composite-components/ButtonGroup/ButtonGroup.types'
import { observer } from 'mobx-react-lite'
import React, { ComponentPropsWithRef, useCallback, useState } from 'react'
import { TransProps, useTranslation } from 'react-i18next'
import Select from 'react-select/dist/declarations/src/Select'
import styled, { css } from 'styled-components'

import Localization from '../../locales'

export interface ISelectOption {
  value: string | number
  label: string

  [key: string | number]: any
}

export type SelectChangeHandler = Select['onChange']

const langOptions: ISelectOption[] = [
  { id: 'fi', label: 'Suomi', value: 'fi' },
  { id: 'en', label: 'English', value: 'en' },
  { id: 'sv', label: 'Svenska', value: 'sv' },
]

interface IProps {
  isLast?: boolean
  isInline?: boolean
}

type Props = TransProps<any> & IProps

export const LangSelectButtonComponent: React.FC<Props> = ({ isInline }) => {
  const { t } = useTranslation()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const currLang = Localization.CurrentLocale

  const getActiveValue = useCallback((value: string | number): ISelectOption => {
    return langOptions.find((o) => o.value === value)!
  }, [])

  const changeLanguage = useCallback(
    (value: string) => {
      setDropdownOpen(!dropdownOpen)
      Localization.setLocale(value)
      // Set language on cookie modal
      // @ts-ignore
      window.OneTrust?.changeLanguage(value)
    },
    [dropdownOpen]
  )

  const languageChangeHandler: SelectChangeHandler = useCallback(
    (newValue) => {
      if (!newValue || Array.isArray(newValue)) {
        return null
      }

      changeLanguage((newValue as ISelectOption).value as string)
    },
    [changeLanguage]
  )

  const languageSelectClickHandler: NonNullable<ButtonGroupProps['onChange']> = useCallback(
    (_, id) => {
      changeLanguage((getActiveValue(id).value as string) || 'fi')
    },
    [changeLanguage, getActiveValue]
  )

  if (!isInline) {
    return (
      <BrandLanguageSelector
        aria-label={t(`common.languageSelection`)}
        label={getActiveValue(currLang)?.label}
        onChange={languageChangeHandler}
        value={getActiveValue(currLang)}
        id="topbar-locale-dropdown"
        inputId="languageDropdown"
        options={langOptions}
        icon={LanguageIcon}
        hasChevron={false}
        isBrand
      />
    )
  }

  return (
    <ButtonGroup
      aria-label={t(`common.languageSelection`)}
      selectedValue={langOptions.filter((o) => o.id === currLang)[0].id}
      onChange={languageSelectClickHandler}
    >
      {langOptions.map((option: ISelectOption) => (
        <LanguageBox key={option.id} text={option.id.toUpperCase()} value={option.id} isBrand />
      ))}
    </ButtonGroup>
  )
}

export const LangSelectButton = observer(LangSelectButtonComponent)

// Styles are an unmaintainable mess due to lack of useful selectors
const BrandLanguageSelector = styled(NavDropdown)<ComponentPropsWithRef<typeof NavDropdown>>(
  ({
    theme: {
      brand: { fontWeight },
    },
    id,
  }) => css`
    .select {
      &__control {
        background: transparent;
        min-height: 0;

        &:hover {
          &:not(:focus) {
            border-color: transparent;
          }

          background: transparent;
        }

        &,
        &--menu-is-open {
          #${id}-label {
            min-width: fit-content;
            font-weight: ${fontWeight.bold};
            overflow-x: visible;
            text-overflow: unset;
          }
        }

        & > div {
          padding: 0;

          & > div > div {
            width: auto;
            max-width: none;
          }
        }

        &--is-focused {
          @media (hover: hover) {
            &:hover {
              background: transparent;
            }
          }
        }

        & svg path,
        & svg rect {
          stroke: currentcolor;
        }
      }

      &__option {
        &,
        &--is-selected {
          & > span {
            max-width: none;
            overflow: visible;
            text-overflow: unset;
          }

          svg {
            margin-left: 10px;
          }
        }
      }
    }
  `
)
