import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { MaxWidthLimiter } from './MaxWidthLimiter'

interface IPostiPageContentsProps {
  className?: string
  style?: 'narrow' | 'wide'
}
const PostiPageContentsComponent: React.FC<PropsWithChildren<IPostiPageContentsProps>> = (props) => {
  const max = props.style == 'wide' ? 1024 : 640
  return (
    <MaxWidthLimiter max={max} className={props.className}>
      {props.children}
    </MaxWidthLimiter>
  )
}
PostiPageContentsComponent.defaultProps = {
  style: 'narrow',
}

export const PostiPageContents = styled(PostiPageContentsComponent)``
