import Localization from '../locales'

const fetch = require('isomorphic-fetch')

class ServiceAdapter {
  createDefaultHeaders = async () => {
    const JSESSIONID = ('; ' + document.cookie).split('; JSESSIONID=').pop()?.split(';').shift()

    return {
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': this.getXsrfToken(),
      clientid: JSESSIONID,
    }
  }

  addEndpointToUrl = (url: string): string => {
    return `${process.env.API_ENDPOINT}${url}`
  }

  loginHasBeenstarted: boolean = false

  isLoggedIn = (response: any): boolean => {
    if (response.status == 401) {
      if (this.loginHasBeenstarted) {
        return false
      }
      this.loginHasBeenstarted = true
      window.location.href = `/relogin?lang=${Localization.CurrentLocale}`
      return false
    }
    return true
  }

  handleLogin = (resp: Promise<Response>): Promise<Response> => {
    if (!this.isLoggedIn(resp)) {
      return Promise.reject(resp)
    }
    return resp
  }

  handleLogOut = async () => {
    location.href = `/logout?lang=${Localization.CurrentLocale}`
  }

  async sendGetRequest(url: string): Promise<Response> {
    let defaultHeaders = await this.createDefaultHeaders()
    return fetch(this.addEndpointToUrl(url), {
      method: 'GET',
      credentials: 'same-origin',
      headers: defaultHeaders,
    }).then(this.handleLogin)
  }

  async sendGetRequestWithoutLogin(url: string): Promise<Response> {
    let defaultHeaders = await this.createDefaultHeaders()
    return fetch(this.addEndpointToUrl(url), {
      method: 'GET',
      credentials: 'same-origin',
      headers: defaultHeaders,
    })
  }

  async sendPostRequestWithoutLogin(url: string, body: any): Promise<Response> {
    let defaultHeaders = await this.createDefaultHeaders()
    return fetch(this.addEndpointToUrl(url), {
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(body),
      headers: defaultHeaders,
    })
  }

  async sendDeleteRequest(url: string): Promise<Response> {
    let defaultHeaders = await this.createDefaultHeaders()
    return fetch(this.addEndpointToUrl(url), {
      method: 'DELETE',
      credentials: 'same-origin',
      headers: defaultHeaders,
    }).then(this.handleLogin)
  }

  async sendPostRequest(url: string, body: any): Promise<Response> {
    let defaultHeaders = await this.createDefaultHeaders()
    return fetch(this.addEndpointToUrl(url), {
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(body),
      headers: defaultHeaders,
    }).then(this.handleLogin)
  }

  async sendPatchRequest(url: string, body: any): Promise<Response> {
    let defaultHeaders = await this.createDefaultHeaders()
    return fetch(this.addEndpointToUrl(url), {
      method: 'PATCH',
      credentials: 'same-origin',
      body: JSON.stringify(body),
      headers: defaultHeaders,
    }).then(this.handleLogin)
  }

  getXsrfToken = () => ('; ' + document.cookie).split('; XSRF-TOKEN=').pop()?.split(';').shift()
}

export const serviceAdapter = new ServiceAdapter()
