import styled, { css } from 'styled-components'

import { breakpoint2 } from '../../utils'

export const Centered = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`

export const MinYSpacingWrapper = styled('div')(
  ({
    theme: {
      brand: { spacing },
    },
  }) => css`
    display: flex;
    flex: 1;

    @media ${breakpoint2.tabletAndUp} {
      margin: ${spacing.space18}rem 0;
    }
  `
)
