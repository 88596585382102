import styled, { css } from 'styled-components'

export const MailBox = styled('div')(
  () => css`
    display: flex;
    align-items: center;
    padding: 16px 24px;
    gap: 16px;
    flex-wrap: nowrap;

    p {
      white-space: wrap;
    }
  `
)
