import { Notification } from '@postidigital/posti-components/build/brand'
import { BrandTheme } from '@postidigital/posti-theme'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ResponsiveLinkButton } from '../../../components/typography/ResponsiveLinkButton'

interface Props {
  additionalMsg?: string
}

export const GeneralErrorNotificationWithRetryButton: React.FC<Props> = ({ additionalMsg }) => {
  const { t } = useTranslation()

  return (
    <Notification id="notification_container" contentType="alert" dismissible={false}>
      <>
        {`${t('errors.generalErrorWithRetry.somethingWentWrong')} `}
        <ResponsiveLinkButton
          style={{ fontSize: 'inherit', color: BrandTheme.color.blue50 }}
          size="xxs"
          onClick={() => {
            window.location.reload()
          }}
        >
          {t('errors.generalErrorWithRetry.tryAgain')}
        </ResponsiveLinkButton>
        .<br />
        {additionalMsg}
      </>
    </Notification>
  )
}
