import { Notification } from '@postidigital/posti-components/build/brand'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { REGISTRATION_FORM_ERROR } from './RegistrationForm'

interface Props {
  error: REGISTRATION_FORM_ERROR
  ref: React.Ref<HTMLParagraphElement>
}

export const AlertNotification: React.FC<Props> = React.forwardRef<HTMLParagraphElement, Props>(({ error }, ref) => {
  const { t } = useTranslation()
  let localisedMessage

  switch (error) {
    case REGISTRATION_FORM_ERROR.EMAIL_ALREADY_IN_USE_409:
      localisedMessage = t('registration.create.errorMessages.emailTaken')
      break
    case REGISTRATION_FORM_ERROR.VALIDATION_ERROR:
      localisedMessage = t('registration.create.errorMessages.validationError')
      break
    default:
      localisedMessage = t('general.error')
  }

  return (
    <Notification id="notification_container" contentType="alert" dismissible={false}>
      <span ref={ref} tabIndex={-1}>
        {localisedMessage}
      </span>
    </Notification>
  )
})
