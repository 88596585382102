import { Notification } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { FC, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { StoreContext } from '../../../store'

const ChangePasswordErrorNotification: FC = () => {
  const { t, i18n } = useTranslation()
  const { changePasswordStore } = useContext(StoreContext)
  const { result } = changePasswordStore

  const parsePasswordError = useCallback(() => {
    return i18n.exists(`mainView.changePassword.error.${result?.result}`)
      ? t(`mainView.changePassword.error.${result?.result}`)
      : t(`mainView.changePassword.error.ERROR`)
  }, [i18n, result?.result, t])

  if (!result || result.result === 'OK') {
    return null
  }

  return (
    <Notification contentType="alert" dismissible={false}>
      <span>{parsePasswordError()}</span>
    </Notification>
  )
}

export default observer(ChangePasswordErrorNotification)
