import styled, { css } from 'styled-components'

import { breakpoint2 } from '../../../utils'

export const ButtonContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex: 1;
    flex-direction: column-reverse;

    button {
      width: 100%;
      margin: ${theme.xyz.spacing.space2}rem 0;
      justify-content: center;
    }

    @media ${breakpoint2.tabletAndUp} {
      flex-direction: row;
      justify-content: flex-end;

      button {
        width: inherit;
        margin: 0;
      }

      button:first-of-type {
        margin: 0 ${theme.xyz.spacing.space2}rem 0 0;
      }
    }
  `
)
