import { EnvelopeIcon, Notification } from '@postidigital/posti-components/build/brand'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { FC, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { StoreContext } from '../../../store'
import { ContentSection } from '../../layout'
import { MailBox } from '../../StaticInputLayout/StaticInputLayout.style'
import { ResponsiveBody } from '../../typography/ResponsiveBody'

const EditEmailVerificationComponent: FC = () => {
  const { t } = useTranslation()
  const {
    editEmailStore: { emailPendingVerification, expirePendingVerification, isEmailVerified },
  } = useContext(StoreContext)
  const emailPendingVerificationJs = toJS(emailPendingVerification)

  const handleRemoveVerification = useCallback(() => {
    expirePendingVerification()
  }, [expirePendingVerification])

  if (!emailPendingVerificationJs || isEmailVerified) {
    return null
  }

  return (
    <ContentSection top={false}>
      <Notification
        contentType="warning"
        id={emailPendingVerificationJs.email}
        key={emailPendingVerificationJs.email}
        onClose={handleRemoveVerification}
      >
        <MailBox style={{ padding: 0 }}>
          <EnvelopeIcon />
          <div>
            <ResponsiveBody size={'Five'}>{t(`mainView.editEmail.pendingVerification`)}</ResponsiveBody>
            <ResponsiveBody size={'Five'} style={{ wordBreak: 'break-all' }}>
              <b>{emailPendingVerificationJs.email}</b>
            </ResponsiveBody>
          </div>
        </MailBox>
      </Notification>
    </ContentSection>
  )
}

export const EditEmailVerification = observer(EditEmailVerificationComponent)
