import { Notification } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { StoreContext } from '../../../store'
import { ResponsiveBody } from '../../typography/ResponsiveBody'

const EditEmailNotifications: FC = () => {
  const { t } = useTranslation()
  const { editEmailStore } = useContext(StoreContext)
  const { changeSuccess, error, emailAlreadyInUse } = editEmailStore

  return (
    <>
      {error && !emailAlreadyInUse && (
        <Notification contentType="alert" dismissible={false}>
          <ResponsiveBody>{t(`mainView.editEmail.error`)}</ResponsiveBody>
        </Notification>
      )}

      {error && emailAlreadyInUse && (
        // error message here if email in use.
        <Notification id="notification_container" contentType="alert" dismissible={false}>
          <ResponsiveBody>{t(`registration.verifyEmail.errorMessages.reserved`)}</ResponsiveBody>
        </Notification>
      )}

      {changeSuccess && (
        <Notification title={t(`mainView.editEmail.verifyTitle`)} dismissible={false}>
          {t(`mainView.editEmail.verifyText`)}
        </Notification>
      )}
    </>
  )
}

export default observer(EditEmailNotifications)
