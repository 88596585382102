import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'

import { EditEmail, EditPreferredName, MainInfo, MainViewTitles, SideImageContainerContent } from '../components'
import { ChangePassword } from '../components/main/ChangePassword'
import { EditConsumerPhoneNumber } from '../components/main/EditConsumerPhoneNumber'
import { StoreContext } from '../store'
import { IMainViewState } from '../store/dataModels/interfaces'
import { VerifyConsumerPhone } from './consumerDetailsView/VerifyConsumerPhone'

const getContent = (state: IMainViewState) => {
  switch (+state) {
    case IMainViewState.EditEmail:
      return <EditEmail />

    case IMainViewState.ChangePassword:
      return <ChangePassword />

    case IMainViewState.EditPreferredName:
      return <EditPreferredName />

    case IMainViewState.EditPhone:
      return <EditConsumerPhoneNumber />

    case IMainViewState.VerifyPhone:
      return <VerifyConsumerPhone />

    default:
      return <MainInfo />
  }
}

const MainViewComponent: React.FC = () => {
  const { mainViewStore } = useContext(StoreContext)

  useEffect(() => {
    return () => {
      // when the component unmounts, reset state
      mainViewStore.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <MainViewTitles />
      <SideImageContainerContent>{getContent(mainViewStore.state)}</SideImageContainerContent>
    </>
  )
}

export const MainView = observer(MainViewComponent)
