import { Modal, ModalStateWrapper } from '@postidigital/posti-components'
import { ProgressAnimation, SuccessAnimation } from '@postidigital/posti-components/build/animations'
import { CloseIcon, Notification } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IPhoneNumberEditingState, StoreContext } from '../../store'
import { ButtonContainer, CancelButton, ContentSection } from '../'
import { PinCodeInput } from '../inputs'
import { ResponsiveBody } from '../typography/ResponsiveBody'
import { ResponsiveLinkButton } from '../typography/ResponsiveLinkButton'

const PhoneNumberVerificationComponent: React.FC = () => {
  const closeRef = useRef<HTMLButtonElement | null>(null)
  const { t } = useTranslation()
  const { userStore, editPhoneNumberStore, detailsUiStateStore } = useContext(StoreContext)
  const { newPhoneNumber, editingState, areVerficationDigitsVisible } = editPhoneNumberStore
  const [codeWasResent, setCodeWasResent] = useState<boolean>(false)

  // ToDo refactor timers
  let successMessageTimer: any = null
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let resendTimer = null

  const resetAndExit = () => {
    detailsUiStateStore.reset()
    editPhoneNumberStore.reset()
  }

  const onCloseModal = () => {
    if (successMessageTimer) {
      clearTimeout(successMessageTimer)
    }
    if (editingState == IPhoneNumberEditingState.CodeWasVerified) {
      resetAndExit()
    } else {
      editPhoneNumberStore.setEditingState(IPhoneNumberEditingState.NotEditing)
    }
  }

  const editingStateToKey = (state: IPhoneNumberEditingState) => {
    if (state == IPhoneNumberEditingState.VerificationCodeIncorrect) return 'invalidCode'
    if (state == IPhoneNumberEditingState.VerificationCodeFailed) return 'failedCode'
    if (state == IPhoneNumberEditingState.WaitAMoment) return 'tooSoonAfterFailed'
    return 'invalidCode'
  }

  useEffect(() => {
    if (editingState == IPhoneNumberEditingState.CodeWasVerified) {
      userStore.setPhoneNumber(newPhoneNumber)
      editPhoneNumberStore.setNewPhoneNumber('')
      // eslint-disable-next-line react-hooks/exhaustive-deps
      successMessageTimer = setTimeout(() => {
        resetAndExit()
      }, 5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingState])

  return (
    <ModalStateWrapper open>
      <Modal>
        <Modal.CloseButton type="button" ref={closeRef} aria-label="Close" onClick={onCloseModal} icon={CloseIcon} />
        <Modal.Content style={{ padding: '2rem' }}>
          <Modal.Header>
            <Modal.Title size="Five">{t(`mainView.editPhoneNumber.verifyTitle`)}</Modal.Title>
          </Modal.Header>
          <ContentSection>
            {areVerficationDigitsVisible && (
              <>
                <ResponsiveBody>{t(`mainView.editPhoneNumber.verifyText`, { newPhoneNumber })}</ResponsiveBody>
                <div style={{ alignSelf: 'center' }}>
                  <PinCodeInput
                    id="pincode"
                    size={6}
                    groupSize={3}
                    lightBackground={true}
                    onChange={(code) => editPhoneNumberStore.setCode(code)}
                    onAutoSubmit={(code) => {
                      editPhoneNumberStore.setCode(code)
                      if (editPhoneNumberStore.isCodeValidFormat) {
                        editPhoneNumberStore.setEditingState(IPhoneNumberEditingState.VerifyingCode)
                      }
                    }}
                  />
                </div>
                {editPhoneNumberStore.generalError && (
                  <Notification contentType="alert" dismissible={false}>
                    {t(`general.error`)}
                  </Notification>
                )}
                {[
                  IPhoneNumberEditingState.VerificationCodeIncorrect,
                  IPhoneNumberEditingState.VerificationCodeFailed,
                  IPhoneNumberEditingState.WaitAMoment,
                ].includes(editingState) && (
                  <Notification contentType="alert" dismissible={false}>
                    {t(`mainView.editPhoneNumber.${editingStateToKey(editingState)}`)}
                  </Notification>
                )}
                <ResponsiveLinkButton
                  disabled={codeWasResent}
                  onClick={() => {
                    resendTimer = setTimeout(() => {
                      setCodeWasResent(false)
                    }, 4000)
                    setCodeWasResent(true)
                    editPhoneNumberStore.setEditingState(IPhoneNumberEditingState.SendingVerifyCode)
                  }}
                >
                  {codeWasResent
                    ? t(`mainView.editPhoneNumber.codeWasResent`)
                    : t(`mainView.editPhoneNumber.sendNewCode`)}
                  &nbsp;
                  {codeWasResent && <SuccessAnimation width={'32px'} height={'32px'} />}
                </ResponsiveLinkButton>
              </>
            )}
            {editingState === IPhoneNumberEditingState.CodeWasVerified && (
              <StyledVerticalContainer>
                <SuccessAnimation width={'130px'} height={'130px'} />
                <ResponsiveBody>{t(`mainView.editPhoneNumber.verifySuccess`)}</ResponsiveBody>
              </StyledVerticalContainer>
            )}
          </ContentSection>
          <Modal.ButtonWrapper style={{ width: '100%' }}>
            <ButtonContainer position="end">
              {editingState !== IPhoneNumberEditingState.VerifyingCode ? (
                <CancelButton
                  id="cancel_phone_verification_button"
                  onClick={() => editPhoneNumberStore.setEditingState(IPhoneNumberEditingState.NotEditing)}
                />
              ) : (
                <StyledVerifyingAnimation>
                  <ResponsiveBody>{t(`mainView.editPhoneNumber.verifying`)}</ResponsiveBody>
                  <ProgressAnimation width={'50px'} height={'50px'} />
                </StyledVerifyingAnimation>
              )}
            </ButtonContainer>
          </Modal.ButtonWrapper>
        </Modal.Content>
      </Modal>
    </ModalStateWrapper>
  )
}

export const PhoneNumberVerification = observer(PhoneNumberVerificationComponent)

const StyledVerifyingAnimation = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-radius: 5px;
  padding-left: 12px;
  width: max-content;
  align-items: center;
`

const StyledVerticalContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
