import { IUserInfo } from '../../store/dataModels/interfaces'
import { serviceAdapter } from '../serviceAdapter'
import { RegisterConsumerUserData, User } from './ServiceModels'

class RegistrationService {
  async registerUser(user: User & { passwordEncoded?: boolean }): Promise<boolean> {
    return serviceAdapter
      .sendPostRequest('/api/acceptinvite/user', user)
      .then((response) => {
        if (response.ok) {
          return true
        } else {
          console.log('Unable to register user: ', response.statusText)
          return false
        }
      })
      .catch((err) => {
        console.log('Unable to register user: ', err)
        return false
      })
  }

  async registerConsumerUser(data: RegisterConsumerUserData, usePatch: boolean): Promise<any> {
    return usePatch
      ? serviceAdapter.sendPatchRequest('api/registerconsumer', data)
      : serviceAdapter.sendPostRequest('api/registerconsumer', data)
  }

  /**
   * gets invite for code
   * @param code
   * @returns
   * @throws true if try again later, false if invalid invite
   */
  async getInvite(code: string): Promise<any> {
    return serviceAdapter
      .sendGetRequest(`/api/acceptinvite/code?code=${code}`)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          if (response.status >= 500) {
            throw true
          } else {
            throw false
          }
        }
      })
      .catch((err) => {
        console.log('unable to get invite: ', err)
        if (typeof err !== 'boolean') err = true
        throw err
      })
  }

  async acceptInvite(code: string): Promise<boolean> {
    return serviceAdapter
      .sendGetRequest(`/api/acceptinvite/accept?code=${code}`)
      .then((response) => {
        if (response.ok) {
          return true
        } else {
          console.log('unable to accept invite: ', response.statusText)
          return false
        }
      })
      .catch((err) => {
        console.log('unable to accept invite: ', err)
        return false
      })
  }

  /**
   * gets consumer registration info
   * @returns
   * @throws true if there is an error and must try again later
   */
  async getInfo(): Promise<IUserInfo> {
    return serviceAdapter
      .sendGetRequestWithoutLogin('/api/user/info')
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else if (response.status == 401) {
          throw false
        } else {
          throw true
        }
      })
      .catch((err) => {
        if (typeof err !== 'boolean') err = true
        throw err
      })
  }

  async backgroundLogout(): Promise<boolean> {
    return serviceAdapter
      .sendGetRequest('/api/logout')
      .then((response) => {
        if (response.ok) {
          return true
        } else {
          console.log('Unable to do background logout: ', response.statusText)
          return false
        }
      })
      .catch((err) => {
        console.log('Unable to do background logout: ', err)
        return false
      })
  }
}

export default new RegistrationService()
