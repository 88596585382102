import { observer } from 'mobx-react-lite'
import React, { FormEventHandler, useCallback, useContext, useEffect, useRef } from 'react'

import RegistrationService from '../../../service/registration_service/RegistrationService'
import { serviceAdapter } from '../../../service/serviceAdapter'
import { StoreContext } from '../../../store'
import { validatePasswordComplexity } from '../../../utils/validation'
import { ButtonContainer, CancelButton, ContentSection, SaveButton } from '../../'
import { StyledWrapper } from '../MainInfo'
import { ChangePasswordConfirmModal, ChangePasswordErrorNotification, ChangePasswordInputList } from './'

const ChangePasswordComponent: React.FC = () => {
  const logOutTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const {
    mainViewStore,
    changePasswordStore,
    infoStore: { isStrongAuth },
  } = useContext(StoreContext)
  const { oldPassword, newPassword, newPasswordRepeat, setOldPassword } = changePasswordStore
  const passwordsMatch = newPassword === newPasswordRepeat

  if (isStrongAuth) setOldPassword('na')

  useEffect(
    () => () => {
      if (logOutTimeoutRef?.current) {
        clearTimeout(logOutTimeoutRef.current)
      }
    },
    []
  )

  const canSave = useCallback((): boolean => {
    if ((!oldPassword && !isStrongAuth) || !newPassword || !newPasswordRepeat) {
      return false
    }

    return validatePasswordComplexity(newPassword) && passwordsMatch
  }, [newPassword, newPasswordRepeat, oldPassword, passwordsMatch, isStrongAuth])

  const handleSave: FormEventHandler<HTMLFormElement> = useCallback(
    async (event) => {
      event.preventDefault()
      event.stopPropagation()
      event.nativeEvent.stopImmediatePropagation()

      if (!canSave()) {
        return null
      }

      const data = await changePasswordStore.savePassword()

      if (data.result === 'OK') {
        logOutTimeoutRef.current = setTimeout(() => {
          if (isStrongAuth) {
            return serviceAdapter.handleLogOut()
          }

          mainViewStore.reset()
        }, 5000)

        if (isStrongAuth) {
          return RegistrationService.backgroundLogout()
        }
      }
    },
    [canSave, changePasswordStore, isStrongAuth, mainViewStore]
  )

  return (
    <StyledWrapper>
      <form id="edit_account_email_form" onSubmit={handleSave}>
        <ContentSection>
          <ChangePasswordInputList />
          <ChangePasswordErrorNotification />
        </ContentSection>
        <ButtonContainer>
          <CancelButton type="button" id="cancel_password_change_button" onClick={mainViewStore.reset} />
          <SaveButton type="submit" id="change_password_button" disabled={!canSave()} />
        </ButtonContainer>
      </form>
      <ChangePasswordConfirmModal logout={serviceAdapter.handleLogOut} />
    </StyledWrapper>
  )
}

export default observer(ChangePasswordComponent)
