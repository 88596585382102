import { Notification } from '@postidigital/posti-components/build/brand'
import { BrandTheme } from '@postidigital/posti-theme'
import styled, { css } from 'styled-components'

import { ResponsiveLink } from '../../components/typography/ResponsiveLink'
import AllSetImage from '../../static/img/all_is_done.svg'
import ConfusedErrorImage from '../../static/img/confused_error.svg'
import InspectingDocImage from '../../static/img/inspecting_doc.svg'
import PhoneImage from '../../static/img/phone.svg'
import SubmitImage from '../../static/img/submit.svg'
import { breakpoint2 } from '../../utils'

export const TextContainer = styled.div(
  ({
    theme: {
      brand: { spacing, color },
    },
  }) => css`
    box-shadow: 0 15px 22px rgba(0, 0, 0, 0.06), 0 3px 8px rgba(0, 0, 0, 0.08), 0 1px 1px rgba(0, 0, 0, 0.08),
      0 3px 1px rgba(0, 0, 0, 0.04);
    padding: ${spacing.space4}rem ${spacing.space8}rem ${spacing.space4}rem ${spacing.space6}rem;
    background-color: ${color.white};
    margin: 0;
    flex-grow: 1;

    @media ${breakpoint2.tabletAndUp} {
      margin: auto;
      padding: ${spacing.space24}rem ${spacing.space28}rem ${spacing.space24}rem ${spacing.space24}rem;
      max-width: 47.2rem;
      border-radius: 1.25rem;
      flex-grow: 0;
    }
  `
)

export const IconBulletList = styled('ul')(
  ({
    theme: {
      brand: { spacing },
    },
  }) => css`
    padding: 0;
    margin-bottom: 0;
    margin-top: ${spacing.space20}rem;

    & p {
      margin-bottom: 0;
    }

    & li:first-child {
      margin-top: 0;
    }
  `
)

export const IconAndText = styled.li(
  ({ theme }) => css`
    display: flex;
    margin-top: ${theme.xyz.spacing.space3}rem;

    svg {
      margin-right: ${theme.xyz.spacing.space2}rem;
      flex-shrink: 0;
      margin-top: 0.1875rem;
    }
  `
)

export const AllSetSvg = styled(AllSetImage)(
  ({
    theme: {
      brand: { spacing },
    },
  }) => css`
    margin: ${spacing.space10}rem auto ${spacing.space12}rem;
    display: block;

    @media ${breakpoint2.tabletAndUp} {
      margin-top: ${spacing.space12}rem;
    }
  `
)

export const PhoneSvg = styled(PhoneImage)(
  ({
    theme: {
      brand: { spacing },
    },
  }) => css`
    margin: ${spacing.space10}rem auto ${spacing.space12}rem;
    display: block;

    @media ${breakpoint2.tabletAndUp} {
      margin-top: ${spacing.space12}rem;
    }
  `
)

export const SubmitSvg = styled(SubmitImage)(
  ({
    theme: {
      brand: { spacing },
    },
  }) => css`
    margin: ${spacing.space10}rem auto ${spacing.space12}rem;
    display: block;

    @media ${breakpoint2.tabletAndUp} {
      margin: ${spacing.space12}rem auto ${spacing.space16}rem;
    }
  `
)

export const ConfusedErrorSvg = styled(ConfusedErrorImage)(
  ({
    theme: {
      brand: { spacing },
    },
  }) => css`
    margin: ${spacing.space10}rem auto ${spacing.space12}rem;
    display: block;

    @media ${breakpoint2.tabletAndUp} {
      margin-top: ${spacing.space12}rem;
    }
  `
)

export const InspectingDocSvg = styled(InspectingDocImage)(
  ({
    theme: {
      brand: { spacing },
    },
  }) => css`
    margin: ${spacing.space10}rem auto ${spacing.space12}rem;
    display: block;

    @media ${breakpoint2.tabletAndUp} {
      margin: ${spacing.space12}rem auto ${spacing.space16}rem;
    }
  `
)

export const CheckboxContainer = styled.div<{ isInvalid?: boolean }>(
  ({
    theme: {
      brand: { fontWeight, spacing },
    },
  }) => css`
    & > div > label {
      font-weight: ${fontWeight.regular};

      & > div {
        flex-grow: 1;
        flex-shrink: 0;
        width: ${spacing.space8}rem;
        height: ${spacing.space8}rem;
      }
    }
  `
)

export const CheckboxErrorContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin-left: ${theme.xyz.spacing.space7}rem;

    svg {
      margin-right: ${theme.xyz.spacing.space2}rem;
    }

    p {
      margin: 0;
    }
  `
)

export const StyledLinkWithExternalIcon = styled(ResponsiveLink)(
  ({
    theme: {
      brand: { spacing },
    },
  }) => css`
    font-size: inherit;
    line-height: inherit;
    margin-left: ${spacing.space05}rem;

    svg {
      height: 1em;
      width: 1em;
      margin: 0 0.125rem 0.4375rem 0.5rem;
    }
  `
)

export const defaultInputIconProps = {
  width: `${BrandTheme.spacing.space6}rem`,
  height: `${BrandTheme.spacing.space6}rem`,
  'aria-hidden': true,
}

export const ListNotification = styled(Notification)(
  ({
    theme: {
      brand: { spacing },
    },
  }) => css`
    margin: 2rem 0;

    @media ${breakpoint2.tabletAndUp} {
      margin: 3rem 0;
    }

    ul {
      padding: 0 0 0 1.375rem;
      margin-top: ${spacing.space2}rem;
    }
  `
)

export const ListNotificationBody = styled('div')(
  () => css`
    word-break: break-word;
    margin: 0;
  `
)

/**
 * Add around posti-components input to reserve space for input message so that total height does not change.
 * This prevents resizing of Modal when Input is used inside Modal.
 */
export const ModalInputMinHeightContainer = styled.div(
  () => css`
    min-height: 6.125rem;
  `
)

export const HiddenNotificationContainer = styled('div')<{ visible: boolean }>(
  ({ visible = true }) => css`
    & > div {
      ${!visible && 'display: none'};
    }
  `
)
